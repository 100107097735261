import React, { FC, FormEvent, useCallback, useState } from 'react';

import {
  ButtonItem,
  ButtonOptions,
  Form,
  GroupItem,
  SimpleItem,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { useDifferProcedure } from '../../api/home';
import { errorMsgs, successMsgs } from '../../configs';

interface DifferFormProps {
  onHiding: () => void;
  refetch?: () => void;
  id: number;
  occurDate: string;
}
interface CommentForm {
  id: string;
  comment: string;
  occurDate: string;
}

export const DifferForm: FC<DifferFormProps> = ({
  onHiding,
  id,
  refetch,
  occurDate,
}) => {
  const initialFormData = {
    id: id.toString(),
    comment: '',
    occurDate: occurDate,
  };
  const [formData, setFormData] = useState<CommentForm>(initialFormData);

  const { mutate: mutateDiffer, isLoading } = useDifferProcedure({
    onSuccess: () => {
      notify(successMsgs.DIFFER_SUCCESS, 'success');
      setFormData(initialFormData);
      onHiding();
      refetch && refetch();
    },
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
  });

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      mutateDiffer(formData);
    },
    [formData, mutateDiffer],
  );

  return (
    <form onSubmit={handleSubmit}>
      <Form labelMode={'floating'} formData={formData} height={120}>
        <SimpleItem
          dataField={'comment'}
          label={{ text: 'Comment' }}
          isRequired
        />
        <GroupItem colCount={3}>
          <GroupItem />
          <GroupItem>
            <ButtonItem>
              <ButtonOptions
                text="Cancel"
                width={'100%'}
                onClick={() => {
                  setFormData(initialFormData);
                  onHiding();
                }}
              />
            </ButtonItem>
          </GroupItem>
          <GroupItem>
            <ButtonItem>
              <ButtonOptions
                text="Yes"
                width={'100%'}
                type={'default'}
                useSubmitBehavior={true}
                disabled={isLoading}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </form>
  );
};
export default DifferForm;
