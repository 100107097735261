import { FC, useState } from 'react';
import React from 'react';

import cx from 'classnames';
import { Tooltip } from 'devextreme-react';
import { Popup } from 'devextreme-react/popup';

import ViewProcedureForm from '../../../pages/procedures/view-procedure/ViewProcedureForm';
import {
  ProcedureProps,
  ProcedureStatus,
} from '../../../types/procedure.types';
import { displayDate } from '../../../utils/dateTime';
import { getStaticFileUrl } from '../../../utils/getStaticFileUrl';
import Avatar from '../../avatar/Avatar';
import ActionButtons from './ActionButtons';
import styles from './procedureCard.module.scss';

const ProcedureCard: FC<ProcedureProps & { refetch?: () => void }> = (
  props,
) => {
  const { data, type, refetch, minSize } = props;
  const { id, subjectName, subjectTitle, users, occurDate, subjectValueId } =
    data;
  const [viewProcedureModal, setViewProcedureModal] = useState(false);
  const displayOccurDate = displayDate(occurDate);

  const roles = ['R', 'R2', 'R3', 'A', 'C', 'I'];
  const usersList = users?.map(({ firstName, lastName, image }, index) => (
    <React.Fragment key={index}>
      <Avatar
        keyValue={index}
        id={`profile-${index}-${data.key}`}
        alt={`${firstName} ${lastName}-${data.key}`}
        imageUrl={getStaticFileUrl(image)}
      />
      <Tooltip
        target={`#profile-${index}-${data.key}`}
        showEvent="dxhoverstart"
        hideEvent="dxhoverend"
        contentRender={() => (
          <>
            {roles[index]} - {firstName} {lastName}
          </>
        )}
      />
    </React.Fragment>
  ));

  return (
    <div
      className={cx(styles.wrapper, { [styles.minSizeWrapper]: minSize })}
      id="procedureCard"
    >
      <div className={styles.clientWrapper}>
        {subjectValueId && subjectName && (
          <p>{subjectValueId + ' - ' + subjectName}</p>
        )}
        <div>
          <i className="dx-icon-event" />
          {displayOccurDate}
        </div>
      </div>
      <h6 onClick={() => setViewProcedureModal(true)}>{subjectTitle}</h6>
      <div className={styles.imagesBlock}>{usersList} </div>
      {type === ProcedureStatus.PendingApproval ? (
        <div className={styles.pendingApproval}>
          <div className={styles.pendingApprovalText}>
            <p>Pending Approval</p>
          </div>
        </div>
      ) : (
        <ActionButtons
          data={props}
          id={id}
          occurDate={occurDate}
          refetch={refetch}
        />
      )}
      {viewProcedureModal && (
        <Popup
          visible={viewProcedureModal}
          hideOnOutsideClick={false}
          onHiding={() => setViewProcedureModal(false)}
          title={'View Procedure'}
          resizeEnabled={false}
          showCloseButton
          dragEnabled={false}
        >
          <ViewProcedureForm
            selectedProcedure={id.toString()}
            isOnGoing={Boolean(type === ProcedureStatus.OnGoing)}
          />
        </Popup>
      )}
    </div>
  );
};

export default ProcedureCard;
