import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import { Button, ScrollView } from 'devextreme-react';
import FileUploader from 'devextreme-react/file-uploader';
import {
  ButtonItem,
  ButtonOptions,
  EmailRule,
  Form,
  GroupItem,
  Item,
  Label,
  RequiredRule,
  SimpleItem,
} from 'devextreme-react/form';

import {
  ALLOWED_IMAGE_TYPES,
  MAX_IMAGE_UPLOAD_SIZE,
  USER_ROLES,
  formValidationMsg,
} from '../../configs';
import { useAuth } from '../../contexts/auth';
import eye from '../../images/hide.png';
import eyeSlash from '../../images/show.png';
import { Nullable } from '../../types';
import { GetUserResponse, UserRoleEnum } from '../../types/user.types';
import styles from './UserForm.module.scss';

interface UserFormProps {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  selectedUser?: GetUserResponse;
  onHiding: () => void;
}

// Try to get this from used types. DOn't define this
interface AddUserProps {
  id: string;
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  role: Nullable<UserRoleEnum>;
  image: string | FormData | null;
}

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
};

const roleEditorOptions = {
  dataSource: USER_ROLES,
  valueExpr: 'value',
  displayExpr: 'name',
  disabled: false,
};

const initialFormData = {
  id: '',
  email: '',
  password: '',
  firstName: '',
  lastName: '',
  role: null,
  image: '',
};

export const UserForm: FC<UserFormProps> = ({
  onSubmit,
  isLoading,
  selectedUser,
  onHiding,
}) => {
  const [showPassword, setShowPassword] = useState(false);
  const [profileImage, setProfileImage] = useState<any>(null);
  const [formData] = useState<AddUserProps>({
    ...initialFormData,
    ...selectedUser,
  });

  const { currentUser } = useAuth();

  roleEditorOptions.disabled = selectedUser?.email === currentUser?.email;

  useEffect(() => {
    setProfileImage(null);
  }, []);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      const { firstName, lastName, role, email, password } = formData;

      const reqForm = new FormData();
      reqForm.append('firstName', firstName);
      reqForm.append('lastName', lastName);
      reqForm.append('email', email);
      reqForm.append('password', password);
      reqForm.append('role', role as string);
      reqForm.append('image', profileImage?.[0]);

      if (selectedUser) {
        const {
          firstName: selectedFirstName,
          lastName: selectedLastName,
          role: selectedRole,
        } = selectedUser;

        if (
          firstName.trim() != selectedFirstName.trim() ||
          lastName.trim() != selectedLastName.trim() ||
          role?.trim() != selectedRole?.trim()
        ) {
          onSubmit(reqForm);
        } else {
          onHiding();
        }
      } else {
        onSubmit(reqForm);
      }
    },
    [profileImage],
  );

  const handlePasswordToggle = () => setShowPassword(!showPassword);

  const passwordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Password',
    mode: showPassword ? 'text' : 'password',
  };

  return (
    <ScrollView height="100%" width="100%">
      <form onSubmit={handleSubmit}>
        <Form labelMode={'floating'} formData={formData} disabled={isLoading}>
          <SimpleItem
            dataField={'email'}
            label={{ text: 'User Email' }}
            isRequired
            editorOptions={{
              ...emailEditorOptions,
              disabled: Boolean(selectedUser),
            }}
          >
            <RequiredRule message={formValidationMsg.EMAIL_REQUIRED} />
            <EmailRule message={formValidationMsg.EMAIL_INVALID} />
          </SimpleItem>

          {!selectedUser && (
            <GroupItem>
              <GroupItem cssClass={styles.passwordEditor}>
                <Item
                  dataField={'password'}
                  editorType={'dxTextBox'}
                  editorOptions={passwordEditorOptions}
                  cssClass={styles.passwordItem}
                >
                  <RequiredRule message={formValidationMsg.PASSWORD_REQUIRED} />
                  <Label visible={false} />
                </Item>
              </GroupItem>

              <GroupItem cssClass={styles.showHiddenIcon}>
                <Item>
                  <Button
                    id="button1"
                    type="normal"
                    icon={showPassword ? eye : eyeSlash}
                    onClick={() => {
                      handlePasswordToggle();
                    }}
                  />
                </Item>
              </GroupItem>
            </GroupItem>
          )}

          <SimpleItem
            dataField="firstName"
            label={{ text: 'First Name' }}
            isRequired
          />
          <SimpleItem
            dataField="lastName"
            label={{ text: 'Last Name' }}
            isRequired
          />
          <SimpleItem
            dataField="role"
            editorType="dxSelectBox"
            label={{ text: 'User Role' }}
            editorOptions={roleEditorOptions}
            isRequired
          />
          <Item isRequired dataField={'profileImage'}>
            <FileUploader
              allowedFileExtensions={ALLOWED_IMAGE_TYPES}
              selectButtonText="Select photo"
              labelText="Profile Image"
              uploadMode="useForm"
              value={profileImage === null ? [] : profileImage}
              onValueChange={(e) => setProfileImage(e)}
              maxFileSize={MAX_IMAGE_UPLOAD_SIZE}
              accept=".png, .jpg, .jpeg"
            >
              <RequiredRule message={formValidationMsg.IMAGE_REQUIRED} />
            </FileUploader>
          </Item>

          <ButtonItem>
            <ButtonOptions
              text="Submit"
              width={'100%'}
              type={'default'}
              useSubmitBehavior={true}
            />
          </ButtonItem>
        </Form>
      </form>
    </ScrollView>
  );
};
