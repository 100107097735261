import { FC, useCallback, useEffect, useState } from 'react';

import Button from 'devextreme-react/button';
import { Form, GroupItem, RangeRule } from 'devextreme-react/form';
import NumberBox from 'devextreme-react/number-box';
import SelectBox from 'devextreme-react/select-box';
import Switch from 'devextreme-react/switch';
import { Item } from 'devextreme-react/tab-panel';
import Validator, { RequiredRule } from 'devextreme-react/validator';
import { ClickEvent } from 'devextreme/ui/button';
import { DateType } from 'devextreme/ui/date_box';
import { Frequency, RRule, Weekday } from 'rrule';
import { parseString } from 'rrule/dist/esm/parsestring';

import { useGetUsers } from '../../api/users';
import RenderViewSchedule from '../../components/view-schedule-panel/ViewSchedule';
import {
  CST_TIMEZONE_OFFSET,
  CST_TIME_OFFSET_FOR_START_DAY,
} from '../../configs/constants';
import {
  DateTimeFormat,
  Schedule,
  TeamFormData,
  Teams,
} from '../../types/procedure.types';
import DateAndTime from '../../utils/DateandTime';
import { convertDueTimeToCST, convertToCST } from '../../utils/dateTime';
import CustomSelectBox from './CustomSelectBox';
import styles from './procedures.module.scss';

const frequencyData = [
  { ID: 1, frequency: 'daily', displayName: 'Daily', rRuleFreq: RRule.DAILY },
  {
    ID: 2,
    frequency: 'weekly',
    displayName: 'Weekly',
    rRuleFreq: RRule.WEEKLY,
  },
  {
    ID: 3,
    frequency: 'monthly',
    displayName: 'Monthly',
    rRuleFreq: RRule.MONTHLY,
  },
  {
    ID: 4,
    frequency: 'yearly',
    displayName: 'Yearly',
    rRuleFreq: RRule.YEARLY,
  },
  { ID: 4, frequency: 'custom', displayName: 'Custom', rRuleFreq: RRule.DAILY },
];

const WeekDays = [
  { ID: 1, day: RRule.MO, displayName: 'MO', selected: false },
  { ID: 2, day: RRule.TU, displayName: 'TU', selected: false },
  { ID: 3, day: RRule.WE, displayName: 'WE', selected: false },
  { ID: 4, day: RRule.TH, displayName: 'TH', selected: false },
  { ID: 5, day: RRule.FR, displayName: 'FR', selected: false },
  { ID: 6, day: RRule.SA, displayName: 'SA', selected: false },
  { ID: 7, day: RRule.SU, displayName: 'SU', selected: false },
];

interface WeekDayProps {
  ID: number;
  day: Weekday;
  displayName: string;
  selected: boolean;
}

const initialFormData: TeamFormData = {
  responsible: '',
  responsible2: '',
  responsible3: '',
  accounter: '',
  consultant: '',
  informer: '',
  scheduler: null,
  startDate: '',
  dueTime: '',
  endDate: '',
  frequency: '',
  duration: 0,
  custom: [],
  isSchedule: false,
};

type ScheduleAndTeamProps = {
  onScheduleTeam: (data: TeamFormData) => void;
  clearSubFormData: boolean;
  data?: TeamFormData;
  onBackButton: (index: number) => void;
};

const ScheduleAndTeam: FC<ScheduleAndTeamProps> = ({
  onScheduleTeam,
  clearSubFormData,
  data,
  onBackButton,
}) => {
  const [searchText] = useState<string>('');
  const [formData, setFormData] = useState<TeamFormData>({
    ...initialFormData,
    ...data,
  });
  const [switchValue, setSwitchValue] = useState(true);
  const [duration, setDuration] = useState(0);
  const [durationInHours, setDurationInHours] = useState<string | null>(null);
  const { data: userData } = useGetUsers({ search: searchText || '' });
  const { custom, startDate, endDate, dueTime, isSchedule } = formData;
  const [weekdays, setWeekdays] = useState(WeekDays);
  const [selectedFreq, setSelectedFreq] = useState<Frequency>(RRule.DAILY);

  let until: Date | null = null;
  let dtstart: Date | null = null;

  if (!data && startDate) {
    dtstart = new Date(
      convertToCST(
        `${startDate}T${CST_TIME_OFFSET_FOR_START_DAY}`,
        CST_TIMEZONE_OFFSET,
      ),
    );

    if (!isSchedule && dueTime) {
      const startTimeInCST = convertDueTimeToCST(startDate, dueTime);
      until = new Date(
        convertToCST(`${startDate}T${startTimeInCST}:00`, CST_TIMEZONE_OFFSET),
      );
    } else if (isSchedule && dueTime && endDate) {
      const endTimeInCST = convertDueTimeToCST(endDate, dueTime);
      until = new Date(
        convertToCST(`${endDate}T${endTimeInCST}:00`, CST_TIMEZONE_OFFSET),
      );
    } else {
      until = null;
    }
  }

  useEffect(() => {
    if (data) return;
    let rule: RRule;
    if (formData.frequency !== 'custom') {
      rule = new RRule({
        freq: selectedFreq,
        dtstart,
        until,
      });
    } else if (formData.frequency === 'custom') {
      rule = new RRule({
        freq: selectedFreq,
        byweekday: custom,
        dtstart,
        until,
      });
    }
    setFormData((prev) => ({
      ...prev,
      scheduler: JSON.stringify(parseString(rule.toString())),
    }));
  }, [formData?.frequency, selectedFreq, custom, startDate, dueTime, endDate]);

  const handleSubmit = useCallback(
    async (eventObj: ClickEvent) => {
      const { event, validationGroup } = eventObj;

      event?.preventDefault();
      const { isValid } = validationGroup.validate();

      if (!isValid) return;

      const { dueTime, ...rest } = formData;
      if (data) {
        onScheduleTeam(formData);
      } else {
        onScheduleTeam({
          ...rest,
          dueTime: convertDueTimeToCST(startDate, dueTime),
        });
      }
    },
    [formData, dueTime, startDate],
  );

  useEffect(() => {
    if (!clearSubFormData && !data) {
      setFormData(initialFormData);
    }
  }, [clearSubFormData]);

  const switchOnValueChange = () => {
    setSwitchValue(!switchValue);
    if (!switchValue)
      setFormData((prev) => ({
        ...prev,
        frequency: '',
      }));
    setFormData((prev) => ({
      ...prev,
      isSchedule: switchValue,
    }));
  };

  const OPTIONAL_FILEDS = [Teams.RESPONSIBLE2, Teams.RESPONSIBLE3];

  const renderItem = (title: string, teamName: Teams, dataSource: any) => (
    <Item>
      <div className={styles.tableTeam}>
        <div className={styles.tableFont}>{title}</div>
        <CustomSelectBox
          dataSource={dataSource}
          value={formData[teamName] || ''}
          onValueChanged={(data) => {
            setFormData((prev) => ({
              ...prev,
              [teamName]: data,
            }));
          }}
          formData={formData}
        >
          <Validator>
            {!OPTIONAL_FILEDS.includes(teamName) && (
              <RequiredRule message={`${teamName} person is required`} />
            )}
          </Validator>
        </CustomSelectBox>
      </div>
    </Item>
  );

  const renderDate = (
    title: string,
    type: DateType,
    scheduleData: Schedule,
    displayFormat: DateTimeFormat,
    placeholder: string,
  ) => (
    <div className={styles.dateBox}>
      <div className={styles.dateName}>{title}</div>
      <div className="dx-field-value">
        <DateAndTime
          onDateSelected={(date) =>
            setFormData((prev) => ({ ...prev, [scheduleData]: date }))
          }
          type={type}
          displayFormat={displayFormat}
          placeholder={placeholder}
          title={title}
        />
      </div>
    </div>
  );

  useEffect(() => {
    if (duration > 59) {
      setFormData((prev) => ({ ...prev, duration }));
      const hours = Math.floor(duration / 60);

      const minutes = duration % 60;
      const timeString = `${hours}:${minutes < 10 ? '0' + minutes : minutes}`;

      setDurationInHours(timeString);
    } else {
      setFormData((prev) => ({ ...prev, duration }));

      setDurationInHours(null);
    }
  }, [duration]);

  const onWeekdaySelect = (data: WeekDayProps) => {
    const newValue = weekdays.map((e) => {
      if (e.day === data.day) {
        return { ...e, selected: !e.selected };
      } else {
        return e;
      }
    });
    setWeekdays(newValue);
    setFormData((prev) => ({
      ...prev,
      custom: newValue.filter((e) => e.selected).map((a) => a.day),
    }));
  };

  return (
    <>
      <Form
        labelMode={'floating'}
        className={styles.formContainer}
        formData={formData}
        scrollingEnabled
      >
        <GroupItem colCount={2}>
          {data ? (
            RenderViewSchedule({ data })
          ) : (
            <GroupItem caption={'SCHEDULE'} cssClass={styles.group1}>
              <div className={styles.scheduleBtn}>
                <p>Schedule</p>
                <Switch onValueChange={switchOnValueChange} />
              </div>
              <div className={styles.dateBox}>
                {!switchValue && (
                  <div className={styles.dateName}>Frequency</div>
                )}
                <div className="dx-field-value">
                  {!switchValue && (
                    <SelectBox
                      dataSource={frequencyData}
                      valueExpr={'frequency'}
                      displayExpr={'displayName'}
                      searchEnabled
                      value={formData.frequency}
                      onValueChanged={(e) => {
                        setFormData((prev) => ({
                          ...prev,
                          frequency: e.value,
                        }));
                        setSelectedFreq(
                          frequencyData.filter(
                            (value) => value.frequency === e.value,
                          )?.[0]?.rRuleFreq || RRule.DAILY,
                        );
                      }}
                      placeholder={'Select frequency'}
                      className={styles.FrequencySelectBox}
                    />
                  )}
                </div>
              </div>
              <div className={styles.dateBox}>
                {formData.frequency === 'custom' && (
                  <div className={styles.dateName}>Custom (Date)s</div>
                )}
                <div className="dx-field-value">
                  {formData.frequency === 'custom' && (
                    <div className={styles.customWeekdayContainer}>
                      {weekdays?.map((data) => (
                        <div
                          key={data.ID}
                          className={
                            data.selected
                              ? styles.customSelectedWeekday
                              : styles.customWeekday
                          }
                          onClick={() => onWeekdaySelect(data)}
                        >
                          <p>{data.displayName}</p>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              {renderDate(
                'Start Date *',
                'date',
                Schedule.START_DATE,
                DateTimeFormat.DATE,
                'Select start date',
              )}
              {renderDate(
                'Due Time *',
                'time',
                Schedule.START_DUE_TIME,
                DateTimeFormat.TIME,
                'Select Time',
              )}
              {!switchValue &&
                renderDate(
                  'End Date',
                  'date',
                  Schedule.END_DATE,
                  DateTimeFormat.DATE,
                  'Select end date',
                )}
              <div className={styles.dateBox}>
                <div className={styles.dateName}>Duration*</div>

                <div className="dx-field-value">
                  <NumberBox
                    defaultValue={0}
                    value={duration}
                    showSpinButtons={true}
                    showClearButton={true}
                    placeholder={'Minutes'}
                    onValueChange={(minutes) => setDuration(minutes)}
                    min={0}
                    step={5}
                    format="#0 'Mins'"
                  >
                    <Validator>
                      <RangeRule
                        message={`Valid duration is required`}
                        min={5}
                      />
                    </Validator>
                  </NumberBox>
                  {durationInHours && (
                    <div className={styles.dateDuration}>
                      duration: {durationInHours} hours
                    </div>
                  )}
                </div>
              </div>
            </GroupItem>
          )}
          <GroupItem caption={'THE TEAM'} cssClass={styles.group2}>
            <Form id={'teamTableWrapper'}>
              <GroupItem>
                <Item>
                  <div className={styles.tableTeam}>
                    <div className={styles.tableFont}>#</div>
                    <div className={styles.teamSelectBox}>
                      <div className={styles.tableFontTitleName}>NAME</div>
                    </div>
                  </div>
                </Item>
                {renderItem('R', Teams.RESPONSIBLE, userData?.data)}
                {renderItem('R2', Teams.RESPONSIBLE2, userData?.data)}
                {renderItem('R3', Teams.RESPONSIBLE3, userData?.data)}
                {renderItem('A', Teams.ACCOUNTABLE, userData?.data)}
                {renderItem('C', Teams.CONSULTANT, userData?.data)}
                {renderItem('I', Teams.INFORMED, userData?.data)}
              </GroupItem>
            </Form>
          </GroupItem>
        </GroupItem>
      </Form>
      <div className={styles.nextBtn}>
        <Button text="Back" width={'20%'} onClick={() => onBackButton(0)} />
        <Button
          text="Next"
          width={'20%'}
          type={'default'}
          onClick={(e) => handleSubmit(e)}
        />
      </div>
    </>
  );
};

export default ScheduleAndTeam;
