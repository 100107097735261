import { FC } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

import { Footer } from '../components';
import appInfo from '../configs/app-info';
import { SideNavOuterToolbar as SideNavBarLayout } from '../layouts';
import routes from './app-routes';

export const Content: FC = () => (
  <SideNavBarLayout title={appInfo.title}>
    <Routes>
      {routes.map(({ path, element }) => (
        <Route key={path} path={path} element={element} />
      ))}
      <Route path="*" element={<Navigate to="/home" />} />
    </Routes>
    <Footer>
      Copyright © 2022-{new Date().getFullYear()} {appInfo.title}.
      <br />
      All trademarks or registered trademarks are property of their respective
      owners.
    </Footer>
  </SideNavBarLayout>
);
