import { FC } from 'react';

import { Button, Form } from 'devextreme-react';
import { GroupItem, SimpleItem } from 'devextreme-react/form';
import { FieldDataChangedEvent } from 'devextreme/ui/form';

import styles from './procedures.module.scss';

type FormValues = {
  text: string;
};

type MandatesCheckListProps = {
  isChecked: boolean;
  onChecklistValues: (data: string[]) => void;
  values: FormValues[];
  setValues: (data: FormValues[]) => void;
};

const MandatesCheckList: FC<MandatesCheckListProps> = ({
  isChecked,
  onChecklistValues,
  values,
  setValues,
}) => {
  const onAddButtonClick = () => {
    setValues([...values, { text: '' }]);
  };

  const onFieldDataChanged = (e: FieldDataChangedEvent, index: number) => {
    const newValue = values.map((value, i) =>
      i === index ? { ...value, text: e.value } : value,
    );
    setValues(newValue);
    onChecklistValues(newValue.map((item) => item.text));
  };

  const onRemoveButtonClick = (index: number) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setValues(newValues);
    onChecklistValues(newValues.map((item) => item.text));
  };

  return (
    <div>
      {isChecked &&
        values.map((formValues, index) => (
          <div key={index} className={styles.checkListText}>
            <div>
              <Form
                formData={formValues}
                onFieldDataChanged={(e) => onFieldDataChanged(e, index)}
              >
                <GroupItem>
                  <SimpleItem
                    cssClass={styles.checkListItem}
                    dataField="text"
                    label={{ text: 'Type here' }}
                    visibleIndex={index}
                  />
                </GroupItem>
              </Form>
            </div>
            <div>
              {isChecked && (
                <Button
                  className={styles.removeButton}
                  key={index}
                  icon="remove"
                  type="normal"
                  onClick={() => onRemoveButtonClick(index)}
                />
              )}
            </div>
          </div>
        ))}

      {isChecked && (
        <div className={styles.buttonWrapper}>
          <Button
            className={styles.addButton}
            icon="plus"
            type="default"
            onClick={onAddButtonClick}
          />
        </div>
      )}
    </div>
  );
};

export default MandatesCheckList;
