import React, { FC } from 'react';

import { DEFAULT_USER_AVATAR } from '../../configs/constants';
import styles from './avatar.module.scss';

interface Props {
  profile?: boolean;
  imageUrl?: string;
  alt: string;
  keyValue: string | number;
  id: string;
  style?: object;
  className?: string;
}

const Avatar: FC<Props> = ({
  imageUrl,
  profile = null,
  alt,
  keyValue,
  id,
  style = {},
  className = '',
}) => {
  const image = (
    <img
      id={id}
      key={keyValue}
      alt={alt}
      src={imageUrl}
      style={style}
      className={className}
      onError={({ currentTarget }) => {
        currentTarget.onerror = null;
        currentTarget.src = DEFAULT_USER_AVATAR;
      }}
    />
  );
  if (profile) {
    return <div className={profile ? styles.wrapper : ''}>{image}</div>;
  }

  return image;
};

export default Avatar;
