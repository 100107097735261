import React, { FC, FormEvent, useCallback, useState } from 'react';

import { SelectBox } from 'devextreme-react';
import {
  ButtonItem,
  ButtonOptions,
  Form,
  GroupItem,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { useProcedureReAssign } from '../../api/home';
import { useGetUsers } from '../../api/users';
import { errorMsgs, successMsgs } from '../../configs';
import styles from '../../pages/procedures/procedures.module.scss';

interface ReAssignFormProps {
  onHiding: () => void;
  id: number;
  occurDate: string;
  refetch?: () => void;
  queryEnabled: boolean;
}
interface AddReAssignProps {
  name: string;
}

const initialFormData = {
  name: '',
};

export const ReAssignForm: FC<ReAssignFormProps> = ({
  onHiding,
  id,
  occurDate,
  refetch,
  queryEnabled,
}) => {
  const [formData, setFormData] =
    React.useState<AddReAssignProps>(initialFormData);
  const [selectedAssignee, setSelectedAssignee] = useState('');
  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
    },
    [formData],
  );
  const [searchText] = useState<string>('');
  const { data: userData } = useGetUsers({
    search: searchText || '',
    enabled: queryEnabled,
  });

  const { mutate: mutateReAssign, isLoading } = useProcedureReAssign({
    onSuccess: () => {
      notify(successMsgs.USER_REASSIGNED, 'success');
      onHiding();
      setFormData(initialFormData);
      refetch && refetch();
    },
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
  });

  const onReAssign = () => {
    mutateReAssign({
      id: id.toString(),
      occurDate: occurDate,
      responsible: selectedAssignee,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      <Form labelMode={'floating'} formData={formData} height={150}>
        <GroupItem>
          <SelectBox
            dataSource={userData?.data}
            valueExpr={'_id'}
            displayExpr={(item) => {
              if (!item) {
                return '';
              } else {
                return `${item?.firstName} ${item?.lastName} (${
                  item?.role?.charAt(0).toUpperCase() + item?.role?.slice(1)
                })`;
              }
            }}
            searchEnabled
            showClearButton
            placeholder={'Search here...'}
            className={styles.teamSelectBox}
            defaultValue={'search here'}
            onValueChanged={(e) => {
              setSelectedAssignee(e.value);
            }}
          />
        </GroupItem>
        <GroupItem colCount={3}>
          <GroupItem />
          <GroupItem>
            <ButtonItem>
              <ButtonOptions text="Cancel" width={'100%'} onClick={onHiding} />
            </ButtonItem>
          </GroupItem>
          <GroupItem>
            <ButtonItem>
              <ButtonOptions
                text="Re Assign"
                width={'100%'}
                type={'default'}
                useSubmitBehavior={true}
                onClick={onReAssign}
                disabled={isLoading}
              />
            </ButtonItem>
          </GroupItem>
        </GroupItem>
      </Form>
    </form>
  );
};

export default ReAssignForm;
