import { useMutation } from '@tanstack/react-query';

import { ApiServiceErr, DeleteBody, MutOptions, axiosApi } from './apiService';

export interface ValueResponse {
  _id: string;
  valueId: string;
  name: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
}

export interface subjectValueUpdateBody {
  name?: string;
  id?: string;
}

type CreateValueBody = Pick<ValueResponse, 'name' | 'subject'>;

export type subjectValueUpdateResponse = Pick<ValueResponse, '_id'>;

export type DeleteSubjectValueResponse = subjectValueUpdateResponse;

export const useAddValue = (options?: MutOptions<ValueResponse>) =>
  useMutation<ValueResponse, ApiServiceErr, CreateValueBody>(async (data) => {
    const response = await axiosApi.post('value/add', data);
    return response.data;
  }, options);

export const useUpdateValue = (
  options?: MutOptions<subjectValueUpdateResponse>,
) =>
  useMutation<
    subjectValueUpdateResponse,
    ApiServiceErr,
    subjectValueUpdateBody
  >(async (reqData) => {
    const { name, id } = reqData;
    const response = await axiosApi.put(`/value/edit/${id}`, { name });
    return response.data;
  }, options);

export const useDeleteSubjectValue = (
  options?: MutOptions<DeleteSubjectValueResponse>,
) =>
  useMutation<DeleteSubjectValueResponse, ApiServiceErr, DeleteBody>(
    async (data) => {
      const response = await axiosApi.delete(`/value/delete/${data.id}`);
      return response.data;
    },
    options,
  );
