import React, { FC, PropsWithChildren } from 'react';

import styles from './r2-r3.module.scss';

interface Props extends PropsWithChildren {
  title: string;
}

const R2R3Wrapper: FC<Props> = ({ children, title }) => (
  <div className={styles.R2R3Wrapper}>
    <div className={styles.titleBlock}>{title}</div>
    <div className={styles.contentBlock}>{children}</div>
  </div>
);

export default R2R3Wrapper;
