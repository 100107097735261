const errorMsgs = {
  SOMETHING_WRONG: 'Something went wrong',
};

const formValidationMsg = {
  PASSWORD_REQUIRED: 'Password is required',
  CONFIRM_PASSWORD_REQUIRED: 'Confirm password required',
  EMAIL_INVALID: 'Must be a valid email',
  EMAIL_REQUIRED: 'Email is required',
  IMAGE_REQUIRED: 'Profile image is required',
  NEW_PW_REQUIRED: 'New Password is required',
  CURRENT_PW_REQUIRED: 'Current Password is required',
  CONFIRM_PW_REQUIRED: 'Confirm Password is required',
  PASSWORDS_DOES_NOT_MATCH: 'Passwords do not match',
  OBJECTIVE_REQUIRED: 'Objective is required',
  PROCEDURE_NAME_REQUIRED: 'Procedure name is required',
  INSTRUCTION_MANUAL_REQUIRED: 'Instruction manual is required',
  PROCEDURE_ALL_REQUIRED_FIELDS: 'Please fill in all required fields',
  REQUIRED_SUBJECT_AND_VALUE: 'Please Select a Subject & Subject Value',
};

const successMsgs = {
  USER_ADDED: 'User Added Successfully',
  USER_REASSIGNED: 'User ReAssigned Successfully',
  DIFFER_SUCCESS: 'Procedure Differed Successfully',
  USER_UPDATED: 'User Updated Successfully',
  USER_DELETE: 'User deleted successfully',
  RESET_PASSWORD_LINK_SENT:
    "We've sent a link to reset your password. Check your inbox.",
  PASSWORD_CHANGED: 'Password changed successfully',
  PROFILE_UPDATED: 'Profile updated successfully',
  SUBJECT_CREATED: 'New subject created successfully!',
  SUBJECT_DELETED: 'Subject deleted successfully!',
  SUBJECT_VALUE_ADDED: 'New subject value added successfully',
  SUBJECT_VALUE_UPDATED: 'Subject value updated successfully',
  SUBJECT_VALUE_DELETED: 'Subject value deleted successfully',
  CLIENT_CREATED: 'New client created successfully',
  CLIENT_VALUE_UPDATED: 'Client name updated successfully',
  CLIENT_DELETE: 'Client deleted successfully',
  PROCEDURE_CREATE: 'New procedure created successfully',
  PROCEDURE_APPROVED: 'Procedure approved successfully',
  PROCEDURE_LOCKED: 'Procedure locked successfully',
  PROCEDURE_COMPLETED: 'Procedure completed successfully',
  DEFAULT_ACTION_SUCCESS: 'Success',
  PROCEDURE_HELP: 'Help requested successfully',
  PROCEDURE_DELETE: 'Procedure deleted successfully',
  PROCEDURE_UPDATE: 'Procedure updated successfully',
  COMPLETION_RECORD_UPLOADED: 'Completion record uploaded successfully',
  COMPLETION_RECORD_REMOVED: 'Completion record removed successfully',
};

const confirmationMsgs = {
  CALL_FOR_HELP: 'Are you sure you want to Call for Help?',
  LOCK_PROCEDURE: 'Are you sure you want to lock this procedure?',
  COMPLETE_PROCEDURE: 'Are you sure you want to complete this procedure?',
  CLIENT_DELETE: 'Are you sure you want to delete this client?',
  SUBJECT_DELETE: 'Are you sure you want to delete this subject?',
  PROCEDURE_DELETE: 'Are you sure you want to delete this procedure?',
  SUBJECT_VALUE_DELETE: 'Are you sure you want to delete this subject value?',
  User_DELETE: 'Are you sure you want to delete this user?',
  CREATE_NEW_PROCEDURE: 'Are you sure you need to create this new Procedure?',
  UPDATE_PROCEDURE: 'Are you sure you need to update the Procedure?',
  COMPLETION_RECORD_DELETE:
    'Are you sure you need to delete completion record?',
};

export { errorMsgs, formValidationMsg, successMsgs, confirmationMsgs };
