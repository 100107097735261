import { FC, Key, useState } from 'react';

import { Button } from 'devextreme-react';
import { CheckBox } from 'devextreme-react/check-box';
import FileUploader from 'devextreme-react/file-uploader';
import { Form, GroupItem } from 'devextreme-react/form';
import { Item } from 'devextreme-react/tab-panel';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';
import notify from 'devextreme/ui/notify';

import {
  useRemoveCompletionRecord,
  useUpdateTaskProcedure,
  useUploadCompletionRecord,
} from '../../../api/procedure';
import { DeleteModal } from '../../../components';
import {
  confirmationMsgs,
  fileExtensions,
  successMsgs,
} from '../../../configs';
import { errorMsgs } from '../../../configs';
import { getStaticFileUrl } from '../../../utils/getStaticFileUrl';
import styles from '../procedures.module.scss';

type ScoreMandatesFormProps = {
  procedureData: any;
  isOnGoing: boolean;
  refetchData: () => void;
};

const ViewMandatesForm: FC<ScoreMandatesFormProps> = ({
  procedureData,
  isOnGoing,
  refetchData,
}) => {
  const [selectedCompletionRecord, setSelectedCompletionRecord] =
    useState<File>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const {
    _id: procedureId,
    checkList: procedureChecklist,
    isCompleteRecordNeeded,
    uploadComRecord,
    isApprovalNeeded,
  } = procedureData;

  const { comment: completeRecordComment, attachment: completeRecord } =
    uploadComRecord || {};

  const { mutate: mutateUpdateTaskProcedure } = useUpdateTaskProcedure({
    onSuccess: () => {
      refetchData();
    },
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
  });

  const { mutate: mutateUpdateCompletionRecords, isLoading: fileUploading } =
    useUploadCompletionRecord({
      onSuccess: () => {
        notify(successMsgs.COMPLETION_RECORD_UPLOADED, 'success');
        setSelectedCompletionRecord(undefined);
        refetchData();
      },
      onError: (error) => {
        notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
      },
    });

  const { mutate: mutateRemoveCompletionRecord, isLoading: isFileRemoving } =
    useRemoveCompletionRecord({
      onSuccess: () => {
        notify(successMsgs.COMPLETION_RECORD_REMOVED, 'success');
        refetchData();
      },
      onError: (error) => {
        notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
      },
    });

  const onTaskCheck = (name: string, status: boolean) => {
    const reqData = {
      name,
      status: !status,
    };

    mutateUpdateTaskProcedure({
      id: procedureId,
      reqData,
    });
  };

  const onValueChanged = (e: ValueChangedEvent) => {
    if (!e.value) {
      return;
    }

    setSelectedCompletionRecord(e.value[0]);
  };

  const onFileUpload = () => {
    const formData = new FormData();
    formData.append(
      'completionRecordAttachment',
      selectedCompletionRecord || '',
    );

    mutateUpdateCompletionRecords({
      id: procedureId,
      formData,
    });
  };

  const onFileRemove = () => {
    mutateRemoveCompletionRecord({
      id: procedureId,
      key: completeRecord,
    });
  };

  return (
    <>
      <Form labelMode={'floating'} className={styles.formContainer}>
        <GroupItem colCount={2} caption={'MANDATES'} cssClass={styles.group2}>
          <GroupItem>
            <Item visible={procedureChecklist.length}>
              <p className={styles.subTitle}>CHECKLIST</p>
            </Item>
            {procedureChecklist.map(
              (item: {
                _id: Key | null | undefined;
                name: string;
                status: boolean;
              }) => (
                <Item key={item?._id}>
                  <CheckBox
                    id={item?._id?.toString()}
                    text={item?.name}
                    iconSize={20}
                    value={item.status}
                    className={styles.subAlign}
                    disabled={!isOnGoing}
                    onValueChange={() => onTaskCheck(item?.name, item?.status)}
                  />
                </Item>
              ),
            )}

            <Item visible={isCompleteRecordNeeded}>
              <p className={styles.subTitle}>COMPLETION RECORD</p>
            </Item>
            <Item visible={isCompleteRecordNeeded}>
              <CheckBox
                text={'Upload completion record'}
                iconSize={20}
                value={isCompleteRecordNeeded}
                className={styles.subAlign}
                readOnly
              />
            </Item>

            <Item visible={isCompleteRecordNeeded}>
              <div className={styles.commentContainer}>
                <span className={styles.commentsTitle}>
                  Comments :{' '}
                  <span className={styles.comments}>
                    {completeRecordComment}
                  </span>
                </span>
                {completeRecord && (
                  <div>
                    <a
                      href={getStaticFileUrl(completeRecord)}
                      download
                      className={styles.documentLink}
                    >
                      Document
                    </a>
                    <Button
                      icon="remove"
                      stylingMode="text"
                      className={styles.removeIcon}
                      disabled={isFileRemoving || !isOnGoing}
                      onClick={() => setShowDeleteConfirmation(true)}
                    />
                  </div>
                )}
              </div>

              {isOnGoing && !completeRecord && (
                <div className={styles.fileUploadContainer}>
                  <div className={styles.fileUploader}>
                    <FileUploader
                      style={{ marginLeft: 20 }}
                      selectButtonText={'Select attachment'}
                      labelText={''}
                      uploadMode={'useForm'}
                      allowedFileExtensions={fileExtensions}
                      onValueChanged={(e: ValueChangedEvent) => {
                        onValueChanged(e);
                      }}
                    />
                  </div>
                  <Button
                    className={styles.uploadButton}
                    type="default"
                    text={fileUploading ? 'Uploading...' : 'Upload'}
                    onClick={onFileUpload}
                    disabled={fileUploading || !selectedCompletionRecord}
                  />
                </div>
              )}
            </Item>

            <Item>
              <p className={styles.subTitle}>APPROVAL</p>
              <p className={styles.approvalValue}>
                {isApprovalNeeded
                  ? 'Approval is Required'
                  : 'Approval is Not Required'}
              </p>
            </Item>
          </GroupItem>
        </GroupItem>
      </Form>
      <DeleteModal
        visible={showDeleteConfirmation}
        onHiding={() => {
          setShowDeleteConfirmation(false);
        }}
        handleDelete={() => {
          onFileRemove();
          setShowDeleteConfirmation(false);
        }}
        massage={confirmationMsgs.COMPLETION_RECORD_DELETE}
      />
    </>
  );
};

export default ViewMandatesForm;
