import React, { FC } from 'react';

import { PageTitle } from '../../components/text/Text';
import ArchiveTable from './ArchiveTable';

export const Archive: FC = () => {
  document.title = 'Archive';

  return (
    <section>
      <PageTitle>Archive</PageTitle>
      <ArchiveTable />
    </section>
  );
};
