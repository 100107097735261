import { withNavigationWatcher } from '../contexts/navigation';
import {
  ArchivePage,
  ClientPage,
  HomePage,
  ProcedurePage,
  ProfilePage,
  SubjectsPage,
  UserPage,
} from '../pages';

const routes = [
  {
    path: '/home',
    element: HomePage,
  },
  {
    path: '/profile',
    element: ProfilePage,
  },
  {
    path: '/users',
    element: UserPage,
  },
  {
    path: '/clients',
    element: ClientPage,
  },
  {
    path: '/procedures',
    element: ProcedurePage,
  },
  {
    path: '/subjects',
    element: SubjectsPage,
  },
  {
    path: '/Archive',
    element: ArchivePage,
  },
];

export default routes.map((route) => ({
  ...route,
  element: withNavigationWatcher(route.element, route.path),
}));
