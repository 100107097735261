import { FC, useEffect, useState } from 'react';

import { useGetMyProcedures } from '../../../api/home';
import useProcedureSocketTrigger from '../../../customHooks/useProcedureSocketTrigger';
import { ProcedureStatus } from '../../../types/procedure.types';
import { UserRoles } from '../../../types/user.types';
import { SocketEnum } from '../../../types/webSocketEvent.types';
import { formatToISO } from '../../../utils/dateTime';
import { filterProcedureList } from '../../../utils/filterProcedureLIst';
import ConsultantTable from '../../consultant-table/ConsultantTable';
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator';
import { NoData } from '../../no-data/NoData';
import ProcedureCard from '../procedure-card/ProcedureCard';
import ProcedureColumn from '../procedures-column/ProcedureColumn';
import TimeFilter, { today, tonight } from '../time-filter/TimeFilter';
import styles from './my-procedures.module.scss';

const socketEventList = [
  SocketEnum.NewProcedure,
  SocketEnum.LockProcedure,
  SocketEnum.Approved,
  SocketEnum.DeleteActivityProcedure,
  SocketEnum.Reassigned,
];

const MyProcedures: FC<{ selectedTabIndex: number }> = ({
  selectedTabIndex,
}) => {
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tonight);

  const {
    data: list = [],
    refetch,
    isLoading,
  } = useGetMyProcedures({
    teamRole: 'responsible',
    startDate: formatToISO(startDate),
    endDate: formatToISO(endDate),
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedTabIndex === 0) {
      refetch();
    }
  }, [selectedTabIndex]);

  useProcedureSocketTrigger(refetch, [UserRoles.Responsible], socketEventList);

  const nextUpList = filterProcedureList(list, ProcedureStatus.Active);
  const onGoingList = filterProcedureList(list, ProcedureStatus.OnGoing);
  const completedList = filterProcedureList(list, ProcedureStatus.Completed);
  const pendingApprovalList = filterProcedureList(
    list,
    ProcedureStatus.ApprovalsNeeded,
  );

  const nextUp = nextUpList.map((data: any) => (
    <ProcedureCard
      key={data.key}
      data={data}
      type={ProcedureStatus.NextUp}
      refetch={refetch}
    />
  ));

  const onGoing = onGoingList.map((data: any) => (
    <ProcedureCard
      key={data._id}
      data={data}
      type={ProcedureStatus.OnGoing}
      refetch={refetch}
    />
  ));

  const completed = completedList.map((data: any) => (
    <ProcedureCard
      key={data._id}
      data={data}
      type={ProcedureStatus.Completed}
    />
  ));

  const PendingApproval = pendingApprovalList.map((data: any) => (
    <ProcedureCard
      key={data._id}
      data={data}
      type={ProcedureStatus.PendingApproval}
    />
  ));

  const completedNPending = PendingApproval.concat(completed);

  return (
    <section className={styles.mainContainer}>
      <TimeFilter
        type=""
        onFilterChange={(startDate: Date, endDate: Date) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />

      <div className={styles.proceduresContainer}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ProcedureColumn title="Next Up">
              {nextUp.length > 0 ? nextUp : <NoData />}
            </ProcedureColumn>
            <ProcedureColumn title="On Going">
              {onGoing.length > 0 ? onGoing : <NoData />}
            </ProcedureColumn>
            <ProcedureColumn title="Completed">
              {completedNPending.length > 0 ? completedNPending : <NoData />}
            </ProcedureColumn>
          </>
        )}
      </div>

      <ConsultantTable />
    </section>
  );
};

export default MyProcedures;
