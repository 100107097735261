import { useQuery } from '@tanstack/react-query';

import { ProcedureResponse } from '../types/procedure.types';
import { ApiServiceErr, axiosApi } from './apiService';

export const useGetActivityProcedure = () =>
  useQuery<ProcedureResponse[], ApiServiceErr>(
    ['/activity-procedure/get-consultant-procedures'],
    async () => {
      const response = await axiosApi.get(
        `/activity-procedure/get-consultant-procedures`,
      );

      return response.data;
    },
  );
