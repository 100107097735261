import { useEffect } from 'react';

import { getUserInLocal } from '../utils/cacheStorage';
import { socket } from '../utils/socket';

const useProcedureSocketTrigger = (
  refetch: () => void,
  userRoles: string[],
  eventNames: string[],
) => {
  useEffect(() => {
    const onSocketEventTrigger = (value: any) => {
      const user = getUserInLocal();

      const isUser = value.find(
        (userObject: { role: string; id: string }) =>
          userRoles.indexOf(userObject.role) + 1 && userObject.id === user?._id,
      );
      if (isUser) {
        refetch();
      }
    };

    eventNames.forEach((eventName) => {
      socket.on(eventName, onSocketEventTrigger);
    });

    return () => {
      eventNames.forEach((eventName) => {
        socket.off(eventName, onSocketEventTrigger);
      });
    };
  }, [eventNames, refetch]);
};

export default useProcedureSocketTrigger;
