import { FC } from 'react';

import Avatar from '../../components/avatar/Avatar';
import { ChangeOwnPasswordForm } from '../../components/change-own-password-form/ChangeOwnPasswordForm';
import { ProfileForm } from '../../components/profile-form/ProfileForm';
import { PageTitle } from '../../components/text/Text';
import { useAuth } from '../../contexts/auth';
import { getStaticFileUrl } from '../../utils/getStaticFileUrl';
import styles from './profile.module.scss';

export const Profile: FC = () => {
  document.title = 'My Profile';
  const { currentUser } = useAuth();

  if (!currentUser) return null;

  const { email, image } = currentUser;
  return (
    <section>
      <PageTitle>My Profile</PageTitle>
      <div className={styles.container}>
        <h5>Personal Details</h5>
        <div className={styles.personalDetails}>
          <div className={styles.formwrapper}>
            <ProfileForm />
          </div>
          <div>
            {currentUser?.image && (
              <Avatar
                profile
                id={email}
                alt={email}
                keyValue={email}
                imageUrl={getStaticFileUrl(image as string)}
              />
            )}
          </div>
        </div>
        <h5>Password Settings</h5>
        <div className={styles.formwrapper}>
          <ChangeOwnPasswordForm />
        </div>
      </div>
    </section>
  );
};
