import { FC, useState } from 'react';

import { Button, DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Tooltip } from 'devextreme-react/tooltip';
import CustomStore from 'devextreme/data/custom_store';
import { RRule, datetime } from 'rrule';

import { getArchivedProcedures } from '../../api/procedure';
import { TableSearchHeader, TableWrapper } from '../../components';
import { DEFAULT_PAGE_SIZE, errorMsgs } from '../../configs';
import { GridRow } from '../../types';
import { ProcedureResponse } from '../../types/procedure.types';
import { UserRoleEnum } from '../../types/user.types';
import { convertZonedDateTime } from '../../utils/dateTime';
import { createParams, getSortString } from '../../utils/helpers';
import { firstLetterCapital } from '../../utils/letterCase';
import { ruleDates, ruleDueTime } from '../../utils/rruleConverters';
import { useIsUserRole } from '../../utils/useIsUserRole';
import styles from '../procedures/procedures.module.scss';
import ViewProcedureForm from '../procedures/view-procedure/ViewProcedureForm';

type RowType = GridRow<
  Omit<ProcedureResponse, 'scheduler'> & {
    scheduler: { freq: number; dtstart: string; until: string };
    proId: number;
  }
>;

export const getSchedulerToolTip = (data: RowType) => {
  const {
    data: {
      scheduler: { dtstart, until },
      isSchedule,
    },
  } = data;

  const { zonedDate: endDate, zonedTime: endTime } = convertZonedDateTime(
    until,
    'yyyy-MM-dd',
  );

  if (!isSchedule) {
    return `${endDate}  ${endTime}`;
  }

  const { zonedDate: startDate, zonedTime: startTime } = convertZonedDateTime(
    dtstart,
    'yyyy-MM-dd',
  );

  const {
    year: startYear,
    month: startMonth,
    day: startDay,
  } = ruleDates(startDate);

  const { hour, minutes } = ruleDueTime(startTime);

  const { year: endYear, month: endMonth, day: endDay } = ruleDates(endDate);

  const rule = new RRule({
    freq: data.data.scheduler.freq,
    dtstart: datetime(startYear, startMonth, startDay, hour, minutes),
    until: datetime(endYear, endMonth, endDay),
  });

  return rule.toText();
};

const ArchiveTable: FC = () => {
  const [newProcedureModal, setNewProcedureModal] = useState(false);
  const [viewProcedureModal, setViewProcedureModal] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [procedureSearchTxt, setProcedureSearchTxt] = useState('');

  const isUser = useIsUserRole(UserRoleEnum?.User);

  const renderProcedure = (data: RowType) => (
    <Button
      className={styles.renderButton}
      onClick={() => {
        setViewProcedureModal(!viewProcedureModal);
        setSelectedProcedure(data.data._id);
      }}
    >
      {data.data.procedureName}
    </Button>
  );

  const renderScheduler = (data: RowType) => {
    const {
      data: {
        proId,
        isSchedule,
        scheduler: { until },
      },
    } = data;
    const { zonedDate } = convertZonedDateTime(until);
    const toolTipText = getSchedulerToolTip(data);
    return (
      <>
        <span id={`k${proId}`}>
          <i className="dx-icon-info" /> {isSchedule ? 'Scheduled' : zonedDate}
        </span>
        <Tooltip
          target={`#k${proId}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick
        >
          {toolTipText}
        </Tooltip>
      </>
    );
  };

  const customDataSource = new CustomStore({
    key: '_id',
    load: (loadOptions: any) => {
      const { sort } = loadOptions;
      let params = createParams(['skip', 'take'], loadOptions);

      if (procedureSearchTxt) {
        params += `search=${procedureSearchTxt}&`;
      }

      if (sort) {
        if (sort) {
          params += getSortString(sort[0]);
        }
      }

      params = params.slice(0, -1);

      return getArchivedProcedures(params)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.pagination.total,
        }))
        .catch(() => {
          throw errorMsgs.SOMETHING_WRONG;
        });
    },
  });

  return (
    <TableWrapper>
      <TableSearchHeader
        newButtonOnClick={() => {
          setNewProcedureModal(!newProcedureModal);
        }}
        buttonDisabled={isUser}
        searchText={setProcedureSearchTxt}
        archive
      />

      <DataGrid dataSource={customDataSource} remoteOperations={true}>
        <Column
          dataField={'proId'}
          alignment={'left'}
          caption={'ID'}
          width={70}
        />
        <Column
          dataField={'procedureName'}
          caption={'Procedure Name'}
          cellRender={renderProcedure}
        />
        <Column
          dataField={'deleteComment'}
          caption={'Comment'}
          cellRender={(data: RowType) =>
            firstLetterCapital(data.data.deleteComment || '')
          }
        />
        <Column dataField={'objective.text'} caption={'Procedure Objective'} />
        <Column
          dataField={'scheduler'}
          caption={'Schedule'}
          cellRender={renderScheduler}
          width={130}
        />
        <Column
          dataField={'updatedAt'}
          caption={'Updated'}
          cellRender={(data: RowType) => {
            const { zonedDate } = convertZonedDateTime(data.data.updatedAt);
            return zonedDate;
          }}
          width={100}
        />
        <Column
          dataField={'status'}
          width={80}
          cellRender={(data: RowType) => firstLetterCapital(data.data.status)}
        />

        <Paging defaultPageIndex={0} defaultPageSize={DEFAULT_PAGE_SIZE} />
        <Pager
          visible
          allowedPageSizes={DEFAULT_PAGE_SIZE}
          showNavigationButtons
        />
      </DataGrid>
      {viewProcedureModal && (
        <Popup
          visible={viewProcedureModal}
          hideOnOutsideClick={false}
          onHiding={() => setViewProcedureModal(false)}
          title={'View Procedure'}
          resizeEnabled={false}
          showCloseButton
          dragEnabled={false}
        >
          <ViewProcedureForm selectedProcedure={selectedProcedure} />
        </Popup>
      )}
    </TableWrapper>
  );
};

export default ArchiveTable;
