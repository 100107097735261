export const ruleDates = (date: any) => {
  const year = Number(date.toString().slice(0, 4)) || 0;
  const month = Number(date.toString().slice(5, 7)) || 0;
  const day = Number(date.toString().slice(8, 10)) || 0;
  return { year, month, day };
};

export const ruleDueTime = (dueTime: { toString: () => string | any[] }) => {
  const hour = Number(dueTime.toString().slice(0, 2)) || 0;
  const minutes = Number(dueTime.toString().slice(4, 6)) || 0;
  return { hour, minutes };
};
