import { ProcedureResponse } from '../api/home';
import { ProcedureStatus } from '../types/procedure.types';

export const filterProcedureList = (
  list: ProcedureResponse[],
  filterBy: ProcedureStatus,
) =>
  transformProcedureList(
    list.filter((data) => data.status === filterBy),
    filterBy,
  );

export const transformProcedureList = (
  list: ProcedureResponse[],
  prefix?: string,
) =>
  list.map((data, index: number) => ({
    _id: data._id,
    id: data._id,
    key: `${prefix}_${data._id}_${index}`,
    subjectTitle: data.procedureName,
    subjectValueId: data.applicableSubject.value.valueId,
    subjectName: data.applicableSubject.value.name,
    status: data.status,
    occurDate: data.occurDate,
    users: [
      {
        firstName: data.team.responsible?.firstName,
        lastName: data.team.responsible?.lastName,
        image: data.team.responsible?.image,
      },
      {
        firstName: data.team.responsible2?.firstName,
        lastName: data.team.responsible2?.lastName,
        image: data.team.responsible2?.image,
      },
      {
        firstName: data.team.responsible3?.firstName,
        lastName: data.team.responsible3?.lastName,
        image: data.team.responsible3?.image,
      },
      {
        firstName: data.team.accounter?.firstName,
        lastName: data.team.accounter?.lastName,
        image: data.team.accounter?.image,
      },
      {
        firstName: data.team.consultant?.firstName,
        lastName: data.team.consultant?.lastName,
        image: data.team.consultant?.image,
      },
      {
        firstName: data.team.informer?.firstName,
        lastName: data.team.informer?.lastName,
        image: data.team.informer?.image,
      },
    ],
  }));
