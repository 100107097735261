import { useMutation } from '@tanstack/react-query';

import {
  ChangeOwnPasswordBody,
  ChangeOwnPasswordResponse,
  LoginResponse,
  ResetPasswordBody,
  UpdateProfileResponse,
} from '../types/user.types';
import { ApiServiceErr, MutOptions, axiosApi } from './apiService';

// TODO: These are sample hooks. Creating just to resolve errors
export const useResetPassword = (options?: MutOptions<LoginResponse>) =>
  useMutation<LoginResponse, ApiServiceErr, ResetPasswordBody>(async (data) => {
    const response = await axiosApi.post('/auth/reset-password', data);
    return response.data;
  }, options);

export const useChangeOwnPassword = (
  options?: MutOptions<ChangeOwnPasswordResponse>,
) =>
  useMutation<ChangeOwnPasswordResponse, ApiServiceErr, ChangeOwnPasswordBody>(
    async (data) => {
      const response = await axiosApi.put(
        '/user/request/password-change',
        data,
      );
      return response.data;
    },
    options,
  );

export const useUpdateProfile = (options?: MutOptions<UpdateProfileResponse>) =>
  useMutation<UpdateProfileResponse, ApiServiceErr, FormData>(async (data) => {
    const response = await axiosApi.put(
      '/user/update-profile/my-profile',
      data,
      { headers: { 'Content-Type': 'multipart/form-data' } },
    );
    return response.data;
  }, options);
