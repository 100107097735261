import { useMutation } from '@tanstack/react-query';

import {
  ChangeOwnPasswordBody,
  ChangeOwnPasswordResponse,
  GetUserResponse,
  LoginBody,
  LoginResponse,
  ResetPasswordBody,
  UserUpdateBody,
  UserUpdateResponse,
} from '../types/user.types';
import { ApiServiceErr, MutOptions, axiosApi } from './apiService';

export const useLogin = (options?: MutOptions<LoginResponse>) =>
  useMutation<LoginResponse, ApiServiceErr, LoginBody>(async (data) => {
    const response = await axiosApi.post('/auth/login', data);
    return response.data;
  }, options);

export const useRegister = (options?: MutOptions<GetUserResponse>) =>
  useMutation<GetUserResponse, ApiServiceErr, FormData>(async (data) => {
    const response = await axiosApi.post('/user/create', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }, options);

export const useUpdateUser = (options?: MutOptions<UserUpdateResponse>) =>
  useMutation<UserUpdateResponse, ApiServiceErr, UserUpdateBody>(
    async (reqData) => {
      const { id, data } = reqData;
      const response = await axiosApi.put(`/user/update/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    },
    options,
  );

// TODO: These are sample hooks. Creating just to resolve errors
export const useResetPassword = (options?: MutOptions<LoginResponse>) =>
  useMutation<LoginResponse, ApiServiceErr, ResetPasswordBody>(async (data) => {
    const response = await axiosApi.post('/auth/reset-password', data);
    return response.data;
  }, options);
// TODO: These are sample hooks. Creating just to resolve errors
export const useChangePassword = (
  options?: MutOptions<ChangeOwnPasswordResponse>,
) =>
  useMutation<ChangeOwnPasswordResponse, ApiServiceErr, ChangeOwnPasswordBody>(
    async (data) => {
      const response = await axiosApi.put(
        '/user/request/password-change',
        data,
      );
      return response.data;
    },
    options,
  );
