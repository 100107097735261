import { UseMutationOptions } from '@tanstack/react-query';
import axios, { HttpStatusCode } from 'axios';

import { API_URL, errorMsgs } from '../configs';
import { Nullable } from '../types';
import { getTokenInLocal, removeTokenAndUser } from '../utils/cacheStorage';

const { SOMETHING_WRONG } = errorMsgs;

export interface ApiServiceErr {
  msg: string;
  status: HttpStatusCode;
}

export interface DeleteBody {
  id?: string;
}

export type MutOptions<Response, TVariables = unknown> = UseMutationOptions<
  Response,
  ApiServiceErr,
  TVariables,
  unknown
>;

const getApiError = (error: any, defaultMessage?: string) => {
  if (!error || !error.response) {
    return SOMETHING_WRONG;
  }

  if (error.response.data) {
    if (typeof error.response.data === 'string') {
      return error.response.data;
    }
    if (typeof error.response.data.message === 'string') {
      return error.response.data.message;
    }
    if (typeof error.response.data.error === 'string') {
      return error.response.data.error;
    }
  }

  return defaultMessage || SOMETHING_WRONG;
};

const getBearToken = (token: Nullable<string>) =>
  token ? `Bearer ${token}` : null;

const axiosApi = axios.create({ baseURL: API_URL });

// Handle api error
axiosApi.interceptors.response.use(undefined, (error) => {
  const errorResponse = {
    msg: getApiError(error),
    status: error.response.status || 500,
  };

  // Redirect to login if 401 or token issue
  if (
    errorResponse.status === 401 ||
    (errorResponse.status === 500 &&
      (errorResponse.msg.includes('signature') ||
        errorResponse.msg.includes('token')))
  ) {
    // Redirect to the login page
    removeTokenAndUser();
    window.location.replace('/login');
  }

  return Promise.reject(errorResponse);
});

axiosApi.interceptors.request.use((config: any) => {
  (config as any).headers.authorization = getBearToken(getTokenInLocal());
  return config;
});

export { axiosApi, getApiError };
