import { FC } from 'react';

import { SelectBox } from 'devextreme-react';

import { SubjectResponse, useGetSubjectValueById } from '../../api/subject';
import useTable from '../../utils/useTable';

interface SearchProps {
  subjectId: string;
  onSelectedValues: (item: string) => void;
  selected: string;
  visible: boolean;
}

const SearchSubjects: FC<SearchProps> = ({
  subjectId,
  onSelectedValues,
  selected,
  visible,
}) => {
  const { setSearchText, ...params } = useTable({});
  const { data } = useGetSubjectValueById({
    ...params,
    id: subjectId,
  });

  const formattedOptions = data?.data?.map((option: SubjectResponse) => ({
    _id: option._id,
    name: option.name,
  }));

  const dataSource = {
    store: formattedOptions,
    valueExpr: '_id',
    searchExpr: 'name', // The field to search on
    searchMode: 'contains', // The search mode
  };

  return (
    <SelectBox
      visible={visible}
      value={selected}
      dataSource={dataSource}
      valueExpr={'_id'}
      displayExpr={'name'}
      searchEnabled
      onValueChange={(e) => onSelectedValues(e)}
      placeholder={'Search here...'}
      onKeyUp={(e) => {
        const target = e?.event?.target as HTMLInputElement;
        setSearchText(target.value);
      }}
    />
  );
};

export default SearchSubjects;
