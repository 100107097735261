import { FC, PropsWithChildren } from 'react';

import styles from './text.module.scss';

export const PageTitle: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.pageTitle}>
    <h1>{children}</h1>
  </div>
);

export const SectionTitle: FC<PropsWithChildren> = ({ children }) => (
  <div className={styles.sectionTitle}>
    <h2>{children}</h2>
  </div>
);
