import { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import {
  ButtonItem,
  ButtonOptions,
  Form,
  SimpleItem,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { SubjectResponse } from '../../api/subject';
import { useUpdateValue } from '../../api/value';
import { errorMsgs, successMsgs } from '../../configs';

const addValueOptions = {
  stylingMode: 'filled',
  placeholder: 'Edit Value',
  mode: 'text',
};

interface EditSubjectValueFormProps {
  defaultVal: SubjectResponse | undefined;
  onFinished: () => void;
}

interface EditValueProps {
  name?: string;
  id?: string;
}

const initialFormData = {
  name: '',
  id: '',
};

export const EditSubjectValueForm: FC<EditSubjectValueFormProps> = ({
  defaultVal,
  onFinished,
}) => {
  const [formData, setFormData] = useState<EditValueProps>(initialFormData);

  const { mutate, isLoading } = useUpdateValue({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.SUBJECT_VALUE_UPDATED, 'success');
      setFormData(initialFormData);
      onFinished();
    },
  });

  useEffect(() => {
    if (defaultVal) {
      setFormData({
        name: defaultVal.name,
        id: defaultVal._id,
      });
    }
  }, [defaultVal]);

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      mutate(formData);
    },
    [mutate, formData],
  );

  return (
    <form onSubmit={onSubmit}>
      <Form labelMode={'floating'} formData={formData} disabled={isLoading}>
        <SimpleItem
          dataField={'name'}
          label={{ text: 'Edit Value' }}
          isRequired
          editorOptions={addValueOptions}
        />
        <ButtonItem>
          <ButtonOptions
            text="Submit"
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          />
        </ButtonItem>
      </Form>
    </form>
  );
};
