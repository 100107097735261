import { FC, useState } from 'react';

import ScrollView from 'devextreme-react/scroll-view';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { useGetProcedureById } from '../../../api/procedure';
import ViewMandatesForm from './ViewMandatesForm';
import ViewProcedureSubjectsForm from './ViewProcedureSujectsForm';
import ViewScheduleAndTeam from './ViewScheduleAndTeam';

type ViewProcedureFormProps = {
  selectedProcedure: string;
  isOnGoing?: boolean;
};

const ViewProcedureForm: FC<ViewProcedureFormProps> = ({
  selectedProcedure,
  isOnGoing = false,
}) => {
  const { data: procedureData, refetch } =
    useGetProcedureById(selectedProcedure);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  return (
    <ScrollView height="100%" width="100%">
      <TabPanel
        id={'add-procedure-tab-panel-view'}
        animationEnabled
        selectedIndex={selectedTabIndex}
        onOptionChanged={(e: any) =>
          setSelectedTabIndex(e.component.option('selectedIndex'))
        }
      >
        <Item title={'Procedure and Applicable Subjects'}>
          <ViewProcedureSubjectsForm procedureData={procedureData} />
        </Item>
        <Item title={'Schedule and Team'}>
          <ViewScheduleAndTeam procedureData={procedureData} />
        </Item>
        <Item title={'Mandates'}>
          <ViewMandatesForm
            procedureData={procedureData}
            isOnGoing={isOnGoing}
            refetchData={refetch}
          />
        </Item>
      </TabPanel>
    </ScrollView>
  );
};

export default ViewProcedureForm;
