import { Nullable } from './index';

export enum UserRoleEnum {
  Admin = 'admin',
  User = 'user',
}

interface User {
  _id: string;
  email: string;
  image: string | FormData | null;
  firstName: string;
  lastName: string;
  role: Nullable<UserRoleEnum>;
  createdAt: string;
  updatedAt: string;
  recoveryCode?: string;
}

interface Password {
  password: string;
}
interface Token {
  token: string;
}

export type MeResponse = Omit<User, 'createdAt' | 'updatedAt' | 'recoveryCode'>;

export type LoginResponse = Omit<User, 'recoveryCode'> & Token;

export type LoginBody = Pick<User, 'email'> & Password;

export type ResetPasswordBody = Pick<User, 'email'>;

export type ChangePasswordBody = Pick<User, 'recoveryCode'> & Password;

export interface ChangeOwnPasswordBody {
  currentPassword: string;
  newPassword: string;
  confirmPassword: string;
}

export interface ChangeOwnPasswordResponse {
  user: string;
}

export type UpdateProfileResponse = Pick<
  User,
  '_id' | 'firstName' | 'lastName' | 'email' | 'image' | 'role'
>;
export type GetUserResponse = Omit<User, 'recoveryCode'>;

export type RegisterBody = Omit<
  User,
  'recoveryCode' | '_id' | 'createdAt' | 'updatedAt'
>;

export interface UserUpdateBody {
  data: FormData;
  id: string;
}

export interface UserUpdateResponse {
  id: string;
}

export type DeleteUserResponse = UserUpdateResponse;
export type ResetPasswordResponse = GetUserResponse;

export interface ResetPasswordAdminBody {
  id: string;
  newPassword: string;
}

export enum UserRoles {
  Responsible = 'R1',
  Responsible2 = 'R2',
  Responsible3 = 'R3',
  Informer = 'I',
  Consultant = 'C',
  Accounter = 'A',
}
