import React, { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import Form, {
  ButtonItem,
  ButtonOptions,
  SimpleItem,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { useAddValue } from '../../api/value';
import { errorMsgs, successMsgs } from '../../configs';

const addValueOptions = {
  stylingMode: 'filled',
  placeholder: 'Value',
  mode: 'text',
};

interface AddSubjectValueFormProps {
  subjectId: string;
  onFinished: () => void;
}

interface AddValueProps {
  name: string;
  subject: string;
}

export const AddSubjectValueForm: FC<AddSubjectValueFormProps> = ({
  subjectId,
  onFinished,
}) => {
  const initialFormData = { name: '', subject: subjectId };
  const [formData, setFormData] = useState<AddValueProps>(initialFormData);

  const { mutate, isLoading } = useAddValue({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.SUBJECT_VALUE_ADDED, 'success');
      setFormData(initialFormData);
      onFinished();
    },
  });

  useEffect(() => {
    setFormData(initialFormData);
  }, [onFinished]);

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      mutate(formData);
    },
    [mutate, formData],
  );

  return (
    <form onSubmit={onSubmit}>
      <Form labelMode={'floating'} formData={formData} disabled={isLoading}>
        <SimpleItem
          dataField={'name'}
          label={{ text: 'Add Value' }}
          isRequired
          editorOptions={addValueOptions}
        />
        <ButtonItem>
          <ButtonOptions
            text="Submit"
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          />
        </ButtonItem>
      </Form>
    </form>
  );
};
