import { UserRoleEnum } from '../types/user.types';

export const navigation = [
  {
    text: 'Home',
    path: '/home',
    icon: 'home',
    allowed: [UserRoleEnum.Admin, UserRoleEnum.User],
  },
  {
    text: 'Procedures',
    path: '/procedures',
    icon: 'checklist',
    allowed: [UserRoleEnum.Admin, UserRoleEnum.User],
  },

  {
    text: 'Subjects',
    path: '/subjects',
    icon: 'detailslayout',
    allowed: [UserRoleEnum.Admin],
  },
  {
    text: 'Users',
    path: '/users',
    icon: 'user',
    allowed: [UserRoleEnum.Admin],
  },
  {
    text: 'Archive',
    path: '/Archive',
    icon: 'inactivefolder',
    allowed: [UserRoleEnum.Admin],
  },
];
