import { ProcedureResponse } from '../types/procedure.types';

export default (data: ProcedureResponse) => {
  const {
    _id,
    objective: { text: objText, attachment: objAttachment },
    instructionManual: { text: insText, attachment: insAttachment },
    applicableSubject,
    team: {
      accounter,
      consultant,
      informer,
      responsible,
      responsible2,
      responsible3,
    },
    uploadComRecord,
    frequency,
    dueTime,
    scheduler,
    procedureName,
    checkList = [],
    isCompleteRecordNeeded,
    isApprovalNeeded,
    duration,
    isSchedule,
  } = data;
  return {
    _id,
    procedureSubjects: {
      objective: objText,
      objectiveAttachments: objAttachment || [],
      procedureName,
      instructionManual: insText,
      instructionAttachments: insAttachment || [],
      subject: applicableSubject,
      removeFiles: [],
    },
    scheduleAndTeam: {
      accounter,
      consultant,
      dueTime,
      duration,
      informer,
      responsible,
      responsible2,
      responsible3,
      frequency,
      startDate: scheduler.dtstart,
      scheduler: JSON.stringify(scheduler),
      endDate: scheduler.until,
      custom: [],
      isSchedule,
    },
    mandates: {
      checkList: checkList?.map((item) => item.name),
      approval: isApprovalNeeded,
      isCompleteRecordNeeded,
      uploadDocument: uploadComRecord?.comment || '',
    },
  };
};
