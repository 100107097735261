import { FC, FormEvent, useRef, useState } from 'react';

import FileUploader from 'devextreme-react/file-uploader';
import {
  ButtonItem,
  EmptyItem,
  Form,
  Item,
  SimpleItem,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { useUpdateProfile } from '../../api/profile';
import {
  ALLOWED_IMAGE_TYPES,
  MAX_IMAGE_UPLOAD_SIZE,
  errorMsgs,
  successMsgs,
} from '../../configs';
import { useAuth } from '../../contexts/auth';

const { SOMETHING_WRONG } = errorMsgs;
const { PROFILE_UPDATED } = successMsgs;

export const ProfileForm: FC = () => {
  const [disabled, setDisabled] = useState(true);

  const { currentUser, setCurrentUser } = useAuth();
  const { mutate } = useUpdateProfile({
    onError: (error) => {
      notify(error?.msg || SOMETHING_WRONG, 'error');
    },
    onSuccess: (data) => {
      notify(PROFILE_UPDATED, 'success');
      setCurrentUser(data);
      setDisabled(true);
      setProfileImage([]);
    },
  });

  const [profileImage, setProfileImage] = useState([]);
  const formData = useRef({
    email: currentUser?.email,
    firstName: currentUser?.firstName,
    lastName: currentUser?.lastName,
    role: currentUser?.role,
  });

  const onSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const { firstName, lastName } = formData.current;
    const reqFormData = new FormData();
    reqFormData.append('firstName', firstName as string);
    reqFormData.append('lastName', lastName as string);

    if (profileImage) {
      reqFormData.append('image', profileImage?.[0]);
    }
    mutate(reqFormData);
  };

  const checkDataChange = () => {
    const edited = formData.current;
    const { firstName, lastName } = currentUser || {};

    if (edited.firstName !== firstName || edited.lastName !== lastName) {
      setDisabled(false);
      return;
    }
    setDisabled(true);
  };

  return (
    <div>
      <form id="profile-details" onSubmit={onSubmit}>
        <Form
          labelMode={'floating'}
          alignItemLabels={true}
          colCount={3}
          formData={formData.current}
        >
          <SimpleItem
            dataField={'email'}
            label={{ text: 'User Email' }}
            editorOptions={{
              disabled: true,
            }}
          />

          <SimpleItem
            dataField="role"
            label={{ text: 'User Role' }}
            editorOptions={{
              disabled: true,
            }}
          />

          <EmptyItem colSpan={1} />
          <SimpleItem
            dataField="firstName"
            label={{ text: 'First Name' }}
            isRequired
            editorOptions={{
              onValueChanged: checkDataChange,
            }}
          />

          <SimpleItem
            dataField="lastName"
            label={{ text: 'Last Name' }}
            isRequired
            editorOptions={{
              onValueChanged: checkDataChange,
            }}
          />
          <Item dataField={'profileImage'}>
            <FileUploader
              allowedFileExtensions={ALLOWED_IMAGE_TYPES}
              selectButtonText="Select photo"
              labelText="Profile Image"
              uploadMode="useForm"
              value={profileImage === null ? [] : profileImage}
              onValueChange={(e) => {
                setProfileImage(e);
                setDisabled(false);
              }}
              maxFileSize={MAX_IMAGE_UPLOAD_SIZE}
              accept=".png, .jpg, .jpeg"
            />
          </Item>

          <EmptyItem colSpan={2} />
          <ButtonItem
            horizontalAlignment="right"
            buttonOptions={{
              text: 'Update Profile',
              type: 'default',
              useSubmitBehavior: true,
              disabled: disabled,
            }}
          />
        </Form>
      </form>
    </div>
  );
};
