export enum SocketEnum {
  NewProcedure = 'new-procedure',
  DeleteProcedure = 'delete-procedure',
  LockProcedure = 'lock-procedure',
  Complete = 'complete',
  Approved = 'approved',
  HelpRequest = 'help-request',
  DeleteActivityProcedure = 'delete-procedure-instance',
  Reassigned = 'reassigned',
}
