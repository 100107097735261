import { FC, useEffect, useState } from 'react';

import { useGetMyProcedures } from '../../../api/home';
import useProcedureSocketTrigger from '../../../customHooks/useProcedureSocketTrigger';
import { ProcedureStatus } from '../../../types/procedure.types';
import { UserRoles } from '../../../types/user.types';
import { SocketEnum } from '../../../types/webSocketEvent.types';
import { formatToISO } from '../../../utils/dateTime';
import { transformProcedureList } from '../../../utils/filterProcedureLIst';
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator';
import { NoData } from '../../no-data/NoData';
import InformedWrapper from '../informed-wrapper/InformedWrapper';
import ProcedureCard from '../procedure-card/ProcedureCard';
import TimeFilter, { today, tonight } from '../time-filter/TimeFilter';
import styles from './informed.module.scss';

const socketEventList = [
  SocketEnum.NewProcedure,
  SocketEnum.LockProcedure,
  SocketEnum.Approved,
  SocketEnum.DeleteActivityProcedure,
  SocketEnum.Reassigned,
];

const Informed: FC<{ selectedTabIndex: number }> = ({ selectedTabIndex }) => {
  const [completeBtn, setCompleteBtn] = useState(false);
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tonight);

  const {
    data: list = [],
    refetch,
    isLoading,
  } = useGetMyProcedures({
    teamRole: 'informer',
    startDate: formatToISO(startDate),
    endDate: formatToISO(endDate),
    completeStatus: completeBtn,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, completeBtn]);

  useEffect(() => {
    if (selectedTabIndex === 3) {
      refetch();
    }
  }, [selectedTabIndex]);

  useProcedureSocketTrigger(refetch, [UserRoles.Informer], socketEventList);

  // TODO: in here, we transwer whatever we get from the api to the format
  // Don't we need to filter this by informer - using filterProcedureList function? @vidurangasw
  const approvalsNeededList = transformProcedureList(list);

  const approvalsNeeded = approvalsNeededList.map((data: any) => (
    <ProcedureCard
      key={data._id}
      data={data}
      type={ProcedureStatus.Informed}
      refetch={refetch}
      minSize
    />
  ));
  return (
    <section className={styles.mainContainer}>
      <TimeFilter
        type="informed"
        onFilterChange={(startDate: Date, endDate: Date) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        onComplete={(value) => setCompleteBtn(value)}
      />
      <div className={styles.informedContainer}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <InformedWrapper title={''} type={''}>
            {approvalsNeeded.length > 0 ? approvalsNeeded : <NoData />}
          </InformedWrapper>
        )}
      </div>
    </section>
  );
};

export default Informed;
