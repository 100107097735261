import React, { FC } from 'react';

import Button from 'devextreme-react/button';
import Toolbar, { Item } from 'devextreme-react/toolbar';

import { UserPanel } from '../user-panel/UserPanel';

import './Header.module.scss';

import { Template } from 'devextreme-react/core/template';

import type { HeaderProps } from '../../types';
import headerStyles from './Header.module.scss';

export const Header: FC<HeaderProps> = ({
  menuToggleEnabled,
  title,
  toggleMenu,
}) => (
  <header>
    <Toolbar className={headerStyles.header}>
      <Item
        visible={menuToggleEnabled}
        location={'before'}
        widget={'dxButton'}
        cssClass={'menu-button'}
      >
        <Button icon="menu" stylingMode="text" onClick={toggleMenu} />
      </Item>
      <Item location={'before'} cssClass={'header-title'} visible={!!title}>
        <div className={headerStyles.brandLogo}>
          <img
            src={require('../../images/AuroraRCM-Logo.png')}
            alt={'aurora-logo'}
          />
          <div className={headerStyles.title}>{title}</div>
        </div>
      </Item>
      <Item
        location={'after'}
        locateInMenu={'auto'}
        menuItemTemplate={'userPanelTemplate'}
      >
        <Button
          className={'user-button authorization'}
          width={210}
          height={'100%'}
          stylingMode={'text'}
        >
          <UserPanel menuMode={'context'} />
        </Button>
      </Item>
      <Template name={'userPanelTemplate'}>
        <UserPanel menuMode={'list'} />
      </Template>
    </Toolbar>
  </header>
);
