import { FC, useState } from 'react';

import { Button, DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import { Popup } from 'devextreme-react/popup';
import { Tooltip } from 'devextreme-react/tooltip';
import CustomStore from 'devextreme/data/custom_store';
import { RRule, datetime } from 'rrule';

import { getProcedures } from '../../api/procedure';
import {
  ActionDropDownMenu,
  DeleteModal,
  TableSearchHeader,
  TableWrapper,
} from '../../components';
import { DEFAULT_PAGE_SIZE, confirmationMsgs, errorMsgs } from '../../configs';
import { GridRow } from '../../types';
import {
  EditProcedureData,
  ProcedureResponse,
  ProcedureStatus,
} from '../../types/procedure.types';
import { UserRoleEnum } from '../../types/user.types';
import { convertZonedDateTime, convertZonedTime } from '../../utils/dateTime';
import { createParams, getSortString } from '../../utils/helpers';
import { firstLetterCapital } from '../../utils/letterCase';
import { ruleDates, ruleDueTime } from '../../utils/rruleConverters';
import transformToEditData from '../../utils/transformToEditData';
import { useIsUserRole } from '../../utils/useIsUserRole';
import styles from '../procedures/procedures.module.scss';
import { ProcedureDeleteForm } from './ProcedureDeleteForm';
import ProcedureForm from './ProcedureForm';
import ViewProcedureForm from './view-procedure/ViewProcedureForm';

type RowType = GridRow<
  Omit<ProcedureResponse, 'scheduler'> & {
    scheduler: { freq: number; dtstart: string; until: string };
    proId: number;
    dueTime: string;
  }
>;

export const getSchedulerToolTip = (data: RowType) => {
  let untilDate;
  const {
    data: {
      scheduler: { dtstart, until },
      isSchedule,
      dueTime,
    },
  } = data;

  if (until) {
    const { zonedDate: endDate } = convertZonedDateTime(until, 'yyyy-MM-dd');

    const { zonedDueTime } = convertZonedTime(dueTime);

    if (!isSchedule) {
      return `${endDate}  ${zonedDueTime}`;
    }

    const { year: endYear, month: endMonth, day: endDay } = ruleDates(endDate);
    untilDate = datetime(endYear, endMonth, endDay);
  }

  const { zonedDate: startDate, zonedTime: startTime } = convertZonedDateTime(
    dtstart,
    'yyyy-MM-dd',
  );

  const {
    year: startYear,
    month: startMonth,
    day: startDay,
  } = ruleDates(startDate);

  const { hour, minutes } = ruleDueTime(startTime);

  const rule = new RRule({
    freq: data.data.scheduler.freq,
    dtstart: datetime(startYear, startMonth, startDay, hour, minutes),
    ...(untilDate && { until: untilDate }),
  });

  return rule.toText();
};

const ProcedureTable: FC = () => {
  const [newProcedureModal, setNewProcedureModal] = useState(false);
  const [editProcedureModal, setEditProcedureModal] = useState(false);
  const [viewProcedureModal, setViewProcedureModal] = useState(false);
  const [showDeleteReasonModal, setShowDeleteReasonModal] = useState(false);
  const [selectedProcedure, setSelectedProcedure] = useState('');
  const [selectedProcedureData, setSelectedProcedureData] =
    useState<EditProcedureData>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [procedureSearchTxt, setProcedureSearchTxt] = useState('');

  const isUser = useIsUserRole(UserRoleEnum?.User);

  const renderProcedure = (data: RowType) => (
    <Button
      className={styles.renderButton}
      onClick={() => {
        setViewProcedureModal(!viewProcedureModal);
        setSelectedProcedure(data.data._id);
      }}
    >
      {data.data.procedureName}
    </Button>
  );

  const renderScheduler = (data: RowType) => {
    const {
      data: {
        proId,
        isSchedule,
        scheduler: { until },
      },
    } = data;
    const { zonedDate } = convertZonedDateTime(until);
    const toolTipText = getSchedulerToolTip(data);
    return (
      <>
        <span id={`k${proId}`}>
          <i className="dx-icon-info" /> {isSchedule ? 'Scheduled' : zonedDate}
        </span>
        <Tooltip
          target={`#k${proId}`}
          showEvent="dxhoverstart"
          hideEvent="dxhoverend"
          hideOnOutsideClick
        >
          {toolTipText}
        </Tooltip>
      </>
    );
  };

  const renderActionButton = (data: RowType) => {
    const { status, _id } = data.data;

    const isDisabled = status === ProcedureStatus.Completed;

    const handleDelete = () => {
      setShowDeleteConfirmation(true);
      setSelectedProcedure(_id);
    };

    const handleEdit = () => {
      setEditProcedureModal(true);
      setSelectedProcedureData(transformToEditData(data.data));
    };

    return (
      <ActionDropDownMenu
        onClickDelete={handleDelete}
        onClickEdit={handleEdit}
        disabled={isUser}
        disableEdit={isDisabled}
        disableDelete={isDisabled}
      />
    );
  };

  const resetEditProcedureModel = () => {
    setEditProcedureModal(false);
    setSelectedProcedureData(undefined);
  };

  const customDataSource = new CustomStore({
    key: '_id',
    load: (loadOptions: any) => {
      const { sort } = loadOptions;
      let params = createParams(['skip', 'take'], loadOptions);

      if (procedureSearchTxt) {
        params += `search=${procedureSearchTxt}&`;
      }

      if (sort) {
        if (sort) {
          params += getSortString(sort[0]);
        }
      }

      params = params.slice(0, -1);

      return getProcedures(params)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.pagination.total,
        }))
        .catch(() => {
          throw errorMsgs.SOMETHING_WRONG;
        });
    },
  });

  return (
    <TableWrapper>
      <TableSearchHeader
        newButtonText={'New Procedure'}
        newButtonOnClick={() => {
          setNewProcedureModal(!newProcedureModal);
        }}
        buttonDisabled={isUser}
        searchText={setProcedureSearchTxt}
        exportBtn
      />

      <DataGrid dataSource={customDataSource} remoteOperations={true}>
        <Column
          dataField={'proId'}
          alignment={'left'}
          caption={'ID'}
          width={70}
        />
        <Column
          dataField={'procedureName'}
          caption={'Procedure Name'}
          cellRender={renderProcedure}
        />
        <Column dataField={'objective.text'} caption={'Procedure Objective'} />
        <Column
          dataField={'scheduler'}
          caption={'Schedule'}
          cellRender={renderScheduler}
          width={130}
        />
        <Column
          dataField={'updatedAt'}
          caption={'Updated'}
          cellRender={(data: RowType) => {
            const { zonedDate } = convertZonedDateTime(data.data.updatedAt);
            return zonedDate;
          }}
          width={100}
        />
        <Column
          dataField={'status'}
          width={80}
          cellRender={(data: RowType) => firstLetterCapital(data.data.status)}
        />
        <Column
          caption={'Actions'}
          cellRender={renderActionButton}
          width={80}
        />
        <Paging defaultPageIndex={0} defaultPageSize={DEFAULT_PAGE_SIZE} />
        <Pager
          visible
          allowedPageSizes={DEFAULT_PAGE_SIZE}
          showNavigationButtons
        />
      </DataGrid>
      {viewProcedureModal && (
        <Popup
          visible={viewProcedureModal}
          hideOnOutsideClick={false}
          onHiding={() => setViewProcedureModal(false)}
          title={'View Procedure'}
          resizeEnabled={false}
          showCloseButton
          dragEnabled={false}
        >
          <ViewProcedureForm selectedProcedure={selectedProcedure} />
        </Popup>
      )}
      {newProcedureModal && (
        <Popup
          height={'auto'}
          visible={newProcedureModal}
          hideOnOutsideClick={false}
          onHiding={() => setNewProcedureModal(false)}
          title={'Create New Procedure'}
          resizeEnabled={false}
          showCloseButton
          dragEnabled={false}
        >
          <ProcedureForm
            onFinished={() => {
              setNewProcedureModal(false);
            }}
            clearCache={newProcedureModal}
          />
        </Popup>
      )}
      {editProcedureModal && (
        <Popup
          visible={editProcedureModal}
          hideOnOutsideClick={false}
          onHiding={() => resetEditProcedureModel()}
          title={'Edit Procedure'}
          resizeEnabled={false}
          showCloseButton
          dragEnabled={false}
        >
          <ProcedureForm
            onFinished={() => {
              resetEditProcedureModel();
            }}
            clearCache={false}
            data={selectedProcedureData}
            type={'EDIT'}
          />
        </Popup>
      )}
      <DeleteModal
        visible={showDeleteConfirmation}
        onHiding={() => {
          setShowDeleteConfirmation(false);
        }}
        handleDelete={() => {
          setShowDeleteReasonModal(true);
          setShowDeleteConfirmation(false);
        }}
        massage={confirmationMsgs.PROCEDURE_DELETE}
      />
      <ProcedureDeleteForm
        selectedProcedureId={selectedProcedure}
        visible={showDeleteReasonModal}
        onHiding={() => {
          setShowDeleteReasonModal(false);
        }}
      />
    </TableWrapper>
  );
};

export default ProcedureTable;
