import { FC, useRef, useState } from 'react';

import { Button } from 'devextreme-react';
import Form, {
  ButtonItem,
  ButtonOptions,
  CompareRule,
  GroupItem,
  Item,
  Label,
  RequiredRule,
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';

import { UseResetPasswordAdmin } from '../../api/users';
import { formValidationMsg } from '../../configs';
import { errorMsgs, successMsgs } from '../../configs';
import eye from '../../images/hide.png';
import eyeSlash from '../../images/show.png';
import styles from './ResetPasswordModal.module.scss';

const { PASSWORD_CHANGED } = successMsgs;
const { SOMETHING_WRONG } = errorMsgs;
const {
  PASSWORD_REQUIRED,
  CONFIRM_PASSWORD_REQUIRED,
  PASSWORDS_DOES_NOT_MATCH,
} = formValidationMsg;

interface ResetPasswordModalProps {
  visible: boolean;
  onHiding: () => void;
  id?: string;
}

enum passwordTypes {
  NEW_PASSWORD = 'newPassword',
  CONFIRM_PASSWORD = 'confirmPassword',
}

const ResetPasswordModal: FC<ResetPasswordModalProps> = ({
  visible,
  onHiding,
  id = null,
}) => {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const formData = useRef({ newPassword: '', confirmPassword: '' });
  const { mutate, isLoading } = UseResetPasswordAdmin({
    onError: (error) => {
      notify(error?.msg || SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(PASSWORD_CHANGED, 'success');
      resetForm();
    },
  });

  if (!visible || !id) {
    return null;
  }

  const onSubmit = async (e: any) => {
    e.preventDefault();
    const { newPassword } = formData.current;
    mutate({ newPassword, id });
  };

  const resetForm = () => {
    formData.current = { newPassword: '', confirmPassword: '' };
    onHiding();
  };

  const handlePasswordsVisibility = (passwordType: string) => {
    if (passwordType === passwordTypes.NEW_PASSWORD) {
      setShowNewPassword(!showNewPassword);
    } else {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };

  const newPasswordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'New Password',
    mode: showNewPassword ? 'text' : 'password',
    onIconClick: { handlePasswordsVisibility },
  };

  const confirmPasswordEditorOptions = {
    ...newPasswordEditorOptions,
    placeholder: 'Confirm Password',
    mode: showConfirmPassword ? 'text' : 'password',
  };

  return (
    <Popup
      visible={visible}
      onHiding={() => resetForm()}
      title={'Reset Password'}
      width={500}
      height="auto"
      resizeEnabled={false}
      showCloseButton
    >
      <form onSubmit={onSubmit}>
        <Form formData={formData.current} disabled={isLoading}>
          <GroupItem cssClass={styles.groupItem} colCount={1}>
            <GroupItem cssClass={styles.passwordEditor}>
              <Item
                cssClass={styles.Item}
                dataField={'newPassword'}
                editorType={'dxTextBox'}
                editorOptions={newPasswordEditorOptions}
              >
                <RequiredRule message={PASSWORD_REQUIRED} />
                <CompareRule
                  message={PASSWORDS_DOES_NOT_MATCH}
                  comparisonTarget={() => formData.current.newPassword}
                />
                <Label visible={false} />
              </Item>
            </GroupItem>
            <GroupItem cssClass={styles.showHiddenIcon}>
              <Item>
                <Button
                  type="normal"
                  icon={showNewPassword ? eye : eyeSlash}
                  onClick={() => {
                    handlePasswordsVisibility(passwordTypes.NEW_PASSWORD);
                  }}
                />
              </Item>
            </GroupItem>
          </GroupItem>

          <GroupItem colCount={1}>
            <GroupItem cssClass={styles.passwordEditor}>
              <Item
                cssClass={styles.Item}
                dataField={'confirmPassword'}
                editorType={'dxTextBox'}
                editorOptions={confirmPasswordEditorOptions}
              >
                <RequiredRule message={CONFIRM_PASSWORD_REQUIRED} />
                <CompareRule
                  message={PASSWORDS_DOES_NOT_MATCH}
                  comparisonTarget={() => formData.current.newPassword}
                />
                <Label visible={false} />
              </Item>
            </GroupItem>
            <GroupItem cssClass={styles.showHiddenIcon}>
              <Item>
                <Button
                  type="normal"
                  icon={showConfirmPassword ? eye : eyeSlash}
                  onClick={() => {
                    handlePasswordsVisibility(passwordTypes.CONFIRM_PASSWORD);
                  }}
                />
              </Item>
            </GroupItem>
          </GroupItem>

          <ButtonItem>
            <ButtonOptions
              text="Reset"
              width={'100%'}
              type={'default'}
              useSubmitBehavior={true}
            />
          </ButtonItem>
        </Form>
      </form>
    </Popup>
  );
};

export default ResetPasswordModal;
