import React, { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import ContextMenu, { Position } from 'devextreme-react/context-menu';
import List from 'devextreme-react/list';

import { useAuth } from '../../contexts/auth';

import './UserPanel.scss';

import type { UserPanelProps } from '../../types';
import { getStaticFileUrl } from '../../utils/getStaticFileUrl';
import Avatar from '../avatar/Avatar';

export const UserPanel: FC<UserPanelProps> = ({ menuMode }) => {
  const { currentUser, logout } = useAuth();
  const navigate = useNavigate();

  if (!currentUser) return null;
  const { email, firstName, image } = currentUser;

  function navigateToProfile() {
    navigate('/profile');
  }
  const menuItems = useMemo(
    () => [
      {
        text: 'Profile',
        icon: 'user',
        onClick: navigateToProfile,
      },
      {
        text: 'Logout',
        icon: 'runner',
        onClick: logout,
      },
    ],
    [logout],
  );
  return (
    <div className={'user-panel'}>
      <div className={'user-info'}>
        <div className={'image-container'}>
          <Avatar
            id="header_avatar"
            alt={firstName?.charAt(0).toLocaleUpperCase()}
            keyValue={email}
            className={'user-image'}
            imageUrl={getStaticFileUrl(image as string)}
            style={{
              backgroundSize: 'cover',
            }}
          />
        </div>
        <div className={'user-name'}>{currentUser?.email}</div>
      </div>

      {menuMode === 'context' && (
        <ContextMenu
          items={menuItems}
          target={'.user-button'}
          showEvent={'dxclick'}
          width={210}
          cssClass={'user-menu'}
        >
          <Position my={'top center'} at={'bottom center'} />
        </ContextMenu>
      )}
      {menuMode === 'list' && (
        <List className={'dx-toolbar-menu-action'} items={menuItems} />
      )}
    </div>
  );
};
