export const isNotEmpty = (value: any) =>
  value !== undefined && value !== null && value !== '';

export const createParams = (fields: string[], loadOptions: any) => {
  let params = '?';
  fields.forEach(function (param) {
    if (param in loadOptions && isNotEmpty(loadOptions[param])) {
      params += `${param}=${JSON.stringify(loadOptions[param])}&`;
    }
  });
  return params;
};

export const getSortString = (sort: { desc: boolean; selector: string }) => {
  const order = sort.desc ? 'desc' : 'asc';
  const sortby = sort.selector;
  return `sortby=${sortby}&order=${order}&`;
};
