import { FC } from 'react';

import { Form, GroupItem } from 'devextreme-react/form';
import { Item } from 'devextreme-react/tab-panel';

import { displayDate } from '../../../utils/dateTime';
import { fieldText } from '../../../utils/fieldText';
import { getStaticFileUrl } from '../../../utils/getStaticFileUrl';
import styles from '../procedures.module.scss';

const ViewProcedureSubjectsForm: FC<any> = ({ procedureData }) => (
  <Form labelMode={'floating'} className={styles.formContainer}>
    <GroupItem colCount={2}>
      <GroupItem caption={'THE PROCEDURE'} cssClass={styles.group1}>
        {fieldText('Objective', procedureData?.objective?.text)}
        {(procedureData?.objective.attachment !== undefined &&
          procedureData?.objective.attachment.length) > 0 && (
          <Item>
            {procedureData?.objective?.attachment &&
              procedureData?.objective?.attachment.map(
                (attachment: string, index: number) => (
                  <a
                    key={index}
                    href={getStaticFileUrl(attachment)}
                    download
                    className={styles.documentLink}
                  >
                    Document {index + 1}
                  </a>
                ),
              )}
          </Item>
        )}

        {fieldText('Created Date', displayDate(procedureData?.createdAt))}

        {fieldText('Procedure Name', procedureData?.procedureName)}

        {fieldText('Procedure ID', procedureData?.proId)}

        {fieldText(
          'Instruction Manual',
          procedureData?.instructionManual?.text,
        )}
        {(procedureData?.instructionManual?.attachment !== undefined &&
          procedureData?.instructionManual?.attachment.length) > 0 && (
          <Item>
            {procedureData?.instructionManual?.attachment &&
              procedureData?.instructionManual?.attachment.map(
                (attachment: string, index: number) => (
                  <a
                    key={index}
                    href={getStaticFileUrl(attachment)}
                    download
                    className={styles.documentLink}
                  >
                    Document {index + 1}
                  </a>
                ),
              )}
          </Item>
        )}
      </GroupItem>
      <GroupItem caption={'APPLICABLE SUBJECTS'} cssClass={styles.group2}>
        {fieldText(
          procedureData?.applicableSubject?.subjectId?.title,
          procedureData?.applicableSubject?.value?.name,
        )}
      </GroupItem>
    </GroupItem>
  </Form>
);

export default ViewProcedureSubjectsForm;
