import { FC, useCallback, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import Form, {
  ButtonItem,
  ButtonOptions,
  CustomRule,
  Item,
  Label,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useChangePassword } from '../../api/auth';
import { errorMsgs, successMsgs } from '../../configs';
import { ValidationType } from '../../types';

const { SOMETHING_WRONG } = errorMsgs;
const { PASSWORD_CHANGED } = successMsgs;

export const ChangePasswordForm: FC = () => {
  const navigate = useNavigate();
  const formData = useRef({ password: '' });
  const { recoveryCode } = useParams();

  const { isLoading } = useChangePassword({
    onError: (error) => {
      notify(error?.msg || SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(PASSWORD_CHANGED, 'success');
      navigate('/login');
    },
  });

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
    },
    [navigate, recoveryCode],
  );

  const confirmPassword = useCallback(
    ({ value }: ValidationType) => value === formData.current.password,
    [],
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={isLoading}>
        <Item
          dataField={'password'}
          editorType={'dxTextBox'}
          editorOptions={passwordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmedPassword'}
          editorType={'dxTextBox'}
          editorOptions={confirmedPasswordEditorOptions}
        >
          <RequiredRule message="Password is required" />
          <CustomRule
            message={'Passwords do not match'}
            validationCallback={confirmPassword}
          />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {isLoading ? (
                <LoadIndicator width={'24px'} height={'24px'} visible={true} />
              ) : (
                'Continue'
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
};

const passwordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Password',
  mode: 'password',
};
const confirmedPasswordEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Confirm Password',
  mode: 'password',
};
