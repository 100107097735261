import { FC, ReactNode } from 'react';

import { TextBox } from 'devextreme-react';
import { Button } from 'devextreme-react/button';
import { ClickEvent } from 'devextreme/ui/button';

import { CsvExport } from '../csv-export/csvExport';
import styles from './TableSearchHeader.module.scss';

interface TableSearchHeaderProps {
  newButtonText?: string;
  newButtonOnClick: (e: ClickEvent) => void;
  searchText?: (text: string) => void;
  buttonDisabled?: boolean;
  subjectId?: string;
  settingBtn?: (e: ClickEvent) => void;
  filterBtn?: (e: ClickEvent) => void;
  isRequiredFilterBtn?: boolean;
  singleFilter?: ReactNode;
  exportBtn?: boolean;
  archive?: boolean;
}

export const TableSearchHeader: FC<TableSearchHeaderProps> = ({
  newButtonText = 'Add New',
  newButtonOnClick,
  searchText,
  buttonDisabled,
  subjectId,
  settingBtn,
  filterBtn,
  isRequiredFilterBtn,
  singleFilter,
  exportBtn,
  archive,
}) => (
  <div className={styles.filtersBlock}>
    {(subjectId && (
      <Button
        className={styles.filtersBtn}
        type={'default'}
        icon={'preferences'}
        onClick={settingBtn}
        disabled={buttonDisabled}
      />
    )) ||
      (isRequiredFilterBtn && (
        <Button
          className={styles.filtersBtn}
          text={'filters'}
          type={'default'}
          icon={'filter'}
          onClick={filterBtn}
        />
      ))}

    {exportBtn && <CsvExport />}

    {!isRequiredFilterBtn && !singleFilter && !subjectId && (
      <div className={styles.filtersBtn} />
    )}

    {singleFilter && singleFilter}

    <TextBox
      className={styles.searchBox}
      mode={'search'}
      placeholder={'Search Here'}
      valueChangeEvent={'input'}
      onValueChange={searchText}
    />

    {!isRequiredFilterBtn && !singleFilter && !subjectId && (
      <div className={styles.addBtn} />
    )}

    {!archive && (
      <Button
        className={styles.addBtn}
        text={newButtonText}
        type={'default'}
        icon={'add'}
        onClick={newButtonOnClick}
        disabled={buttonDisabled}
      />
    )}
  </div>
);
