import React, {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from 'react';

import { useQueryClient } from '@tanstack/react-query';

import { Nullable } from '../types';
import { MeResponse } from '../types/user.types';
import {
  getTokenInLocal,
  getUserInLocal,
  saveTokenInLocal,
  saveUserInLocal,
} from '../utils/cacheStorage';

type State = {
  isLoggedIn: boolean;
  currentUser: Nullable<MeResponse>;
  setToken: (token: string) => void;
  setCurrentUser: (user: MeResponse) => void;
  logout: () => void;
};

const AuthStateContext = createContext<State | undefined>(undefined);

const useAuth = () => {
  const context = useContext(AuthStateContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within a AuthProvider');
  }
  return context;
};

function AuthProvider(props: PropsWithChildren<unknown>) {
  const [currentUser, setCurrentUser] = useState<Nullable<MeResponse>>(
    getUserInLocal(),
  );
  const [token, setToken] = useState(getTokenInLocal());
  const queryClient = useQueryClient();

  const logout = () => {
    localStorage.clear();
    queryClient.clear();
    queryClient.resetQueries();
    setCurrentUser(null);
    setToken(null);
  };

  useEffect(() => {
    saveTokenInLocal(token || '');
  }, [token]);

  useEffect(() => {
    saveUserInLocal(currentUser);
  }, [currentUser]);

  return (
    <AuthStateContext.Provider
      value={{
        currentUser,
        setToken,
        setCurrentUser,
        isLoggedIn: !!token,
        logout,
      }}
      {...props}
    />
  );
}

export { AuthProvider, useAuth };
