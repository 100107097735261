import { FC, useState } from 'react';

import DataGrid, { Column, Pager, Paging } from 'devextreme-react/data-grid';
import Popup from 'devextreme-react/popup';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';

import {
  SubjectResponse,
  getSubjectValueById,
  useDeleteSubject,
  useGetSubjects,
} from '../../api/subject';
import { useDeleteSubjectValue } from '../../api/value';
import {
  ActionDropDownMenu,
  DeleteModal,
  TableSearchHeader,
  TableWrapper,
} from '../../components';
import { AddSubjectValueForm } from '../../components/add-subject-value-form/AddSubjectValueForm';
import { EditSubjectValueForm } from '../../components/edit-subject-value-form/EditSubjectValueForm';
import {
  DEFAULT_PAGE_SIZE,
  confirmationMsgs,
  errorMsgs,
  successMsgs,
} from '../../configs';
import { GridRow } from '../../types';
import { UserRoleEnum } from '../../types/user.types';
import { displayDate } from '../../utils/dateTime';
import { createParams, getSortString } from '../../utils/helpers';
import { useIsUserRole } from '../../utils/useIsUserRole';
import useTable from '../../utils/useTable';

type RowType = GridRow<SubjectResponse>;

interface SubjectTabProps {
  selectedTab: string;
  selectedItem?: string;
}

const SubjectTable: FC<SubjectTabProps> = ({
  selectedTab,
}: SubjectTabProps) => {
  const subjectId = selectedTab;

  const { refetch: refetchSubject } = useGetSubjects();
  const { mutate } = useDeleteSubject({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.SUBJECT_DELETED, 'success');
      refetchSubject();
      setShowDeleteBtn(false);
    },
  });

  const { setSearchText, search } = useTable({});
  const { mutate: mutateDeleteValue } = useDeleteSubjectValue({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.SUBJECT_VALUE_DELETED, 'success');
      setShowDeleteValueBtn(false);
    },
  });

  const [showAddValue, setShowAddValue] = useState(false);
  const [showValueEdit, setShowValueEdit] = useState(false);
  const [showDeleteBtn, setShowDeleteBtn] = useState(false);
  const [activeValue, setActiveValue] = useState<SubjectResponse>();
  const [showDeleteValueBtn, setShowDeleteValueBtn] = useState(false);

  const isUser = useIsUserRole(UserRoleEnum?.User);

  const renderActionButton = (row: RowType) => (
    <ActionDropDownMenu
      onClickEdit={() => {
        setShowValueEdit(true);
        setActiveValue(row.data);
      }}
      onClickDelete={() => {
        setShowDeleteValueBtn(true);
        setActiveValue(row.data);
      }}
      disabled={isUser}
    />
  );

  const customDataSource = new CustomStore({
    key: '_id',
    load: (loadOptions: any) => {
      const { sort } = loadOptions;
      let params = createParams(['skip', 'take'], loadOptions);

      if (search) {
        params += `search=${search}&`;
      }

      if (sort) {
        params += getSortString(sort[0]);
      }

      params = params.slice(0, -1);

      return getSubjectValueById(params, subjectId)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.pagination.total,
        }))
        .catch(() => {
          throw errorMsgs.SOMETHING_WRONG;
        });
    },
  });

  return (
    <TableWrapper>
      <TableSearchHeader
        newButtonText={'New Value'}
        newButtonOnClick={() => setShowAddValue(true)}
        buttonDisabled={isUser}
        searchText={setSearchText}
        subjectId={subjectId}
        settingBtn={() => setShowDeleteBtn(true)}
      />
      <div>
        <DataGrid dataSource={customDataSource} remoteOperations={true}>
          <Column dataField={'valueId'} />
          <Column dataField={'name'} caption={'Name'} />
          <Column
            dataField={'createdAt'}
            caption={'Created Date'}
            cellRender={(data: RowType) => displayDate(data.data.createdAt)}
          />
          <Column
            dataField={'updatedAt'}
            caption={'Updated Date'}
            cellRender={(data: RowType) => displayDate(data.data.updatedAt)}
          />
          <Column caption={'Actions'} cellRender={renderActionButton} />
          <Paging defaultPageIndex={0} defaultPageSize={DEFAULT_PAGE_SIZE} />
          <Pager
            visible
            allowedPageSizes={DEFAULT_PAGE_SIZE}
            showNavigationButtons
          />
        </DataGrid>

        <Popup
          visible={showAddValue}
          hideOnOutsideClick
          onHiding={() => setShowAddValue(false)}
          title={'Subject Value'}
          width={500}
          height={250}
          resizeEnabled={false}
          showCloseButton
        >
          <AddSubjectValueForm
            subjectId={subjectId}
            onFinished={() => {
              setShowAddValue(false);
            }}
          />
        </Popup>
        <Popup
          visible={showValueEdit}
          onHiding={() => setShowValueEdit(false)}
          title={'Edit Subject Value'}
          width={500}
          height={250}
          resizeEnabled={false}
          showCloseButton
        >
          <EditSubjectValueForm
            onFinished={() => {
              setShowValueEdit(false);
            }}
            defaultVal={activeValue}
          />
        </Popup>
        <DeleteModal
          visible={showDeleteBtn}
          onHiding={() => setShowDeleteBtn(false)}
          handleDelete={() => mutate({ id: subjectId })}
          massage={confirmationMsgs.SUBJECT_DELETE}
        />
        <DeleteModal
          visible={showDeleteValueBtn}
          onHiding={() => setShowDeleteValueBtn(false)}
          handleDelete={() => mutateDeleteValue({ id: activeValue?._id })}
          massage={confirmationMsgs.SUBJECT_VALUE_DELETE}
        />
      </div>
    </TableWrapper>
  );
};

export default SubjectTable;
