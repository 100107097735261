import { FC, useEffect, useState } from 'react';

import { SelectBox } from 'devextreme-react';

import { TeamFormData } from '../../types/procedure.types';
import { GetUserResponse } from '../../types/user.types';
import styles from './procedures.module.scss';

type CustomSelectBoxProps = {
  dataSource: any;
  value: string;
  onValueChanged: (data: string) => void;
  children?: React.ReactNode;
  formData: TeamFormData;
};

const CustomSelectBox: FC<CustomSelectBoxProps> = ({
  dataSource,
  value,
  onValueChanged,
  children,
  formData,
}) => {
  const [userList, setUserList] = useState(dataSource);

  useEffect(() => {
    const selectedUsers = Object.values(formData) as string[];
    const availableUsers = dataSource?.filter(
      (user: GetUserResponse) =>
        !selectedUsers.includes(user._id) || user._id === value.toString(),
    );
    setUserList(availableUsers);
  }, [dataSource, formData]);

  return (
    <SelectBox
      dataSource={userList}
      valueExpr={'_id'}
      displayExpr={(item) => {
        if (!item) {
          return '';
        } else {
          return `${item?.firstName} ${item?.lastName} (${
            item?.role?.charAt(0).toUpperCase() + item?.role.slice(1)
          })`;
        }
      }}
      searchEnabled
      showClearButton
      value={value}
      onValueChanged={(e) => onValueChanged(e.value)}
      placeholder={'Search here...'}
      className={styles.teamSelectBox}
      defaultValue={'search here'}
    >
      {children}
    </SelectBox>
  );
};

export default CustomSelectBox;
