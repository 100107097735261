import { FC } from 'react';

import { Form, GroupItem } from 'devextreme-react/form';
import ValidationSummary from 'devextreme-react/validation-summary';

import RenderViewSchedule from '../../../components/view-schedule-panel/ViewSchedule';
import { firstLetterCapital } from '../../../utils/letterCase';
import styles from '../procedures.module.scss';

// TODO: Remove any type
const ViewScheduleAndTeam: FC<any> = ({ procedureData }) => {
  const tableData = [
    {
      role: 'R',
      level: firstLetterCapital(procedureData?.team?.responsible?.role || ''),
      name: `${procedureData?.team?.responsible?.firstName || ''} ${
        procedureData?.team?.responsible?.lastName || ''
      }`,
    },
    {
      role: 'R2',
      level: firstLetterCapital(procedureData?.team?.responsible2?.role || ''),
      name: `${procedureData?.team?.responsible2?.firstName || ''} ${
        procedureData?.team?.responsible2?.lastName || ''
      }`,
    },
    {
      role: 'R3',
      level: firstLetterCapital(procedureData?.team?.responsible3?.role || ''),
      name: `${procedureData?.team?.responsible3?.firstName || ''} ${
        procedureData?.team?.responsible3?.lastName || ''
      }`,
    },
    {
      role: 'A',
      level: firstLetterCapital(procedureData?.team?.accounter?.role || ''),
      name: `${procedureData?.team?.accounter?.firstName || ''} ${
        procedureData?.team?.accounter?.lastName || ''
      }`,
    },
    {
      role: 'C',
      level: firstLetterCapital(procedureData?.team?.consultant?.role || ''),
      name: `${procedureData?.team?.consultant?.firstName || ''} ${
        procedureData?.team?.consultant?.lastName || ''
      }`,
    },
    {
      role: 'I',
      level: firstLetterCapital(procedureData?.team?.informer?.role || ''),
      name: `${procedureData?.team?.informer?.firstName || ''} ${
        procedureData?.team?.informer?.lastName || ''
      }`,
    },
  ];

  const renderHeader = () => (
    <tr className={styles.headerRow}>
      <th>#</th>
      <th>Clearance Level</th>
      <th>Name</th>
    </tr>
  );

  const renderRow = (
    rowData: { name: string; level: string; role: string },
    index: number,
  ) => (
    <tr
      className={index % 2 === 0 ? styles.evenRow : styles.oddRow}
      key={index}
    >
      <td>{rowData.role}</td>
      <td>{rowData.level}</td>
      <td>{rowData.name}</td>
    </tr>
  );

  return (
    <Form
      labelMode={'floating'}
      className={styles.formContainer}
      formData={procedureData}
    >
      <GroupItem colCount={2}>
        {RenderViewSchedule({ data: procedureData })}
        <GroupItem caption={'THE TEAM'} cssClass={styles.group2}>
          <GroupItem>
            <table className={styles.table}>
              <thead>{renderHeader()}</thead>
              <tbody>
                {tableData.map((rowData, index) => renderRow(rowData, index))}
              </tbody>
            </table>
          </GroupItem>
        </GroupItem>
      </GroupItem>
      <GroupItem>
        <ValidationSummary />
      </GroupItem>
    </Form>
  );
};

export default ViewScheduleAndTeam;
