import React, { FC, useEffect, useState } from 'react';

import { TabPanel } from 'devextreme-react';
import { Item } from 'devextreme-react/tab-panel';

import Accountable from '../../components/home/accountable/Accountable';
import Informed from '../../components/home/informed/Informed';
import MyProcedures from '../../components/home/my-procedures/MyProcedures';
import R2R3 from '../../components/home/r2-r3/R2R3';
import { PageTitle } from '../../components/text/Text';
import { socket } from '../../utils/socket';
import styles from './home.module.scss';

export const Home: FC = () => {
  document.title = 'Home';
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);

  useEffect(() => {
    socket.connect();

    socket.on('disconnect', (reason) => {
      if (reason === 'io server disconnect') {
        socket.connect();
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <section>
      <PageTitle>Home</PageTitle>
      <TabPanel
        className={styles.tabsContainer}
        onSelectedIndexChange={(index) => setSelectedTabIndex(index)}
      >
        <Item title="My Procedures (R1)">
          <MyProcedures selectedTabIndex={selectedTabIndex} />
        </Item>
        <Item title="R2/R3">
          <R2R3 selectedTabIndex={selectedTabIndex} />
        </Item>
        <Item title="Accountable">
          <Accountable selectedTabIndex={selectedTabIndex} />
        </Item>
        <Item title="Informed">
          <Informed selectedTabIndex={selectedTabIndex} />
        </Item>
      </TabPanel>
    </section>
  );
};
