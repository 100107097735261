import { FC } from 'react';

import { Button } from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';

import styles from './submitModal.module.scss';

interface SubmitPopUpProps {
  visible: boolean;
  onHiding: () => void;
  handleSubmit: () => void;
  massage: string;
}

export const SubmitModal: FC<SubmitPopUpProps> = ({
  visible,
  onHiding,
  handleSubmit,
  massage,
}) => (
  <Popup
    visible={visible}
    onHiding={onHiding}
    title={'Warning'}
    width={500}
    height={200}
    resizeEnabled={false}
    showCloseButton
  >
    <div className={styles.contentMsg}>{massage}</div>

    <div className={styles.submitBtn}>
      <Button
        className={styles.submitYesNOBtn}
        type="default"
        width={'20%'}
        onClick={onHiding}
      >
        No
      </Button>
      <Button
        className={styles.submitYesNOBtn}
        type="default"
        width={'20%'}
        onClick={handleSubmit}
      >
        Yes
      </Button>
    </div>
  </Popup>
);
