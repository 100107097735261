import React, { FC, PropsWithChildren } from 'react';

import { ProcedureStatus } from '../../../types/procedure.types';
import styles from './informed.module.scss';

interface ProcedureProps extends PropsWithChildren {
  title: string;
  type: string;
}

const InformedWrapper: FC<ProcedureProps> = ({ children, title, type }) => {
  const isMyProcedure = type === ProcedureStatus.Informed;

  return (
    <div className={styles.informedWrapper}>
      {isMyProcedure && <div className={styles.titleBlock}>{title}</div>}
      <div className={styles.contentBlock}>{children}</div>
    </div>
  );
};

export default InformedWrapper;
