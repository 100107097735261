import { FC, useEffect, useState } from 'react';

import TabPanel, { Item } from 'devextreme-react/tab-panel';
import notify from 'devextreme/ui/notify';

import { useCreateProcedure, useUpdateProcedure } from '../../api/procedure';
import { errorMsgs, formValidationMsg, successMsgs } from '../../configs';
import {
  CreateProcedureBody,
  EditProcedureData,
  MandateFormValues,
  SubjectsFormData,
  TeamFormData,
} from '../../types/procedure.types';
import getRequestFormData from '../../utils/getRequestFormData';
import ProcedureSubjectsForm from './ProcedureSubjectsForm';
import ScheduleAndTeam from './ScheduleAndTeam';
import ScoreMandatesForm from './ScoreMandatesForm';

const { PROCEDURE_CREATE, PROCEDURE_UPDATE } = successMsgs;

type FormType = 'NEW' | 'EDIT';
interface ProcedureFormProps {
  onFinished: () => void;
  clearCache: boolean;
  data?: EditProcedureData | null;
  type?: FormType;
}

type SubFormStates = MandateFormValues | SubjectsFormData | TeamFormData;

const initialFormData: CreateProcedureBody = {
  procedureName: '',
  objectiveText: '',
  instructionText: '',
  objectiveAttachment: [],
  instructionManualAttachment: [],
  applicableSubject: {
    subjectId: '',
    value: '',
  },
  frequency: 'daily',
  scheduler: null,
  duration: 0,
  responsible: '',
  responsible2: '',
  responsible3: '',
  accounter: '',
  consultant: '',
  informer: '',
  checkListValues: [],
  comment: '',
  isApprovalNeeded: false,
  isCompleteRecordNeeded: false,
  startDate: '',
  dueTime: '',
  endDate: '',
  approval: false,
  endTime: '',
  isSchedule: false,
};

const ProcedureForm: FC<ProcedureFormProps> = ({
  onFinished,
  clearCache,
  data,
  type = 'NEW',
}) => {
  const [formData, setFormData] =
    useState<CreateProcedureBody>(initialFormData);
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [mandates, setMandates] = useState<MandateFormValues>();
  const [scheduleTeam, setScheduleTeam] = useState<TeamFormData>();
  const [procedureSubjects, setProcedureSubjects] =
    useState<SubjectsFormData>();

  const { mutate } = useCreateProcedure({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(PROCEDURE_CREATE, 'success');
      setFormData(initialFormData);
      setSelectedTabIndex(0);
      onFinished();
    },
  });

  const { mutate: update } = useUpdateProcedure({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(PROCEDURE_UPDATE, 'success');
      setFormData(initialFormData);
      setSelectedTabIndex(0);
      onFinished();
    },
  });

  const clearAll = () => {
    setFormData(initialFormData);
    setSelectedTabIndex(0);
  };

  useEffect(() => {
    if (!clearCache) {
      clearAll();
    }
  }, [clearCache]);

  useEffect(() => {
    clearAll();
  }, []);

  useEffect(() => {
    if (procedureSubjects) {
      const {
        objective,
        objectiveAttachments,
        procedureName,
        instructionManual,
        instructionAttachments,
        subject,
        removeFiles,
      } = procedureSubjects;

      setFormData({
        ...formData,
        objectiveText: objective,
        objectiveAttachment: objectiveAttachments as string[],
        procedureName: procedureName,
        instructionText: instructionManual,
        instructionManualAttachment: instructionAttachments as string[],
        applicableSubject: subject,
        removeFiles,
      });
    }
  }, [JSON.stringify(procedureSubjects)]);

  useEffect(() => {
    if (scheduleTeam) {
      const {
        responsible,
        responsible2,
        responsible3,
        accounter,
        consultant,
        informer,
        frequency,
        duration,
        scheduler,
        endDate,
        dueTime,
        startDate,
        isSchedule,
      } = scheduleTeam;
      setFormData({
        ...formData,
        scheduler,
        responsible,
        responsible2,
        responsible3,
        accounter,
        consultant,
        informer,
        frequency: frequency?.toLowerCase(),
        duration,
        startDate,
        dueTime,
        endDate,
        isSchedule,
      });
    }
  }, [JSON.stringify(scheduleTeam)]);

  useEffect(() => {
    if (mandates) {
      const { checkList, approval, uploadDocument, isCompleteRecordNeeded } =
        mandates;
      setFormData({
        ...formData,
        checkListValues: checkList,
        isApprovalNeeded: approval,
        comment: uploadDocument,
        isCompleteRecordNeeded,
      });
    }
  }, [JSON.stringify(mandates)]);

  const isFormDataValid = (formData: any) => {
    const requiredFields = [
      'procedureName',
      'applicableSubject',
      'responsible',
      'accounter',
      'consultant',
      'informer',
    ];
    for (const field of requiredFields) {
      if (!formData[field]) {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    const reqForm = getRequestFormData(formData);

    if (isFormDataValid(formData)) {
      if (type === 'NEW') {
        mutate(reqForm);
      } else {
        update({ data: reqForm, id: data?._id || '' });
      }
    } else {
      notify(formValidationMsg.PROCEDURE_ALL_REQUIRED_FIELDS, 'error');
    }
  };

  const handleBackButton = (index: number) => setSelectedTabIndex(index);

  const compareState = (next: SubFormStates, prev?: SubFormStates): boolean =>
    JSON.stringify(prev) === JSON.stringify(next);

  return (
    <TabPanel
      id={'add-procedure-tab-panel'}
      animationEnabled
      selectedIndex={selectedTabIndex}
      onOptionChanged={(e: any) =>
        setSelectedTabIndex(e.component.option('selectedIndex'))
      }
    >
      <Item
        key={'create-procedure-1'}
        title={'Procedure and Applicable Subjects'}
      >
        <ProcedureSubjectsForm
          onProcedureSubject={(data) => {
            if (!compareState(data, procedureSubjects)) {
              setProcedureSubjects(data);
            }
            setSelectedTabIndex(1);
          }}
          clearSubFormData={clearCache}
          data={data?.procedureSubjects}
        />
      </Item>
      <Item key={'create-procedure-2'} title={'Schedule and Team'}>
        <ScheduleAndTeam
          onBackButton={(index: number) => handleBackButton(index)}
          onScheduleTeam={(teamData) => {
            if (!compareState(teamData, scheduleTeam)) {
              setScheduleTeam(teamData);
            }
            setSelectedTabIndex(2);
          }}
          clearSubFormData={clearCache}
          data={data?.scheduleAndTeam}
        />
      </Item>
      <Item key={'create-procedure-3'} title={'Mandates'}>
        <ScoreMandatesForm
          onBackButton={(index: number) => handleBackButton(index)}
          onMandates={(mandates) => {
            setMandates(mandates);
          }}
          clearSubFormData={clearCache}
          onSubmit={(event) => {
            handleSubmit(event);
          }}
          data={data?.mandates}
        />
      </Item>
    </TabPanel>
  );
};

export default ProcedureForm;
