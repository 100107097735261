import React, { FC } from 'react';

import { PageTitle } from '../../components/text/Text';
import ProcedureTable from './ProcedureTable';

export const Procedures: FC = () => {
  document.title = 'Procedures';

  return (
    <section>
      <PageTitle>Procedures</PageTitle>
      <ProcedureTable />
    </section>
  );
};
