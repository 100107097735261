import { Weekday } from 'rrule';

export enum ProcedureStatus {
  Active = 'active',
  Schedule = 'schedule',
  NextUp = 'nextUp',
  OnGoing = 'ongoing',
  PendingApproval = 'pendingApproval',
  ApprovalsNeeded = 'approvalNeeded',
  Completed = 'completed',
  Informed = 'informed',
  CallForHelp = 'help',
  Accounter = 'accounter',
  Critical = 'critical',
}

export enum ProcedureActionType {
  Lock = 'lock',
  Approve = 'approve',
  Complete = 'complete',
  ReAssign = 're-assign',
}

export interface ProcedureData {
  _id: string;
  id: number;
  key: string;
  subjectTitle?: string;
  subjectName: string;
  subjectValueId: string;
  status: ProcedureStatus;
  occurDate: string;
  users: { firstName: string; lastName: string; image: string }[];
}

export interface ProcedureProps {
  id?: string;
  data: ProcedureData;
  type: ProcedureStatus;
  minSize?: boolean;
}

export enum Teams {
  RESPONSIBLE = 'responsible',
  RESPONSIBLE2 = 'responsible2',
  RESPONSIBLE3 = 'responsible3',
  ACCOUNTABLE = 'accounter',
  CONSULTANT = 'consultant',
  INFORMED = 'informer',
}

export enum Schedule {
  START_DATE = 'startDate',
  END_DATE = 'endDate',
  START_DUE_TIME = 'dueTime',
  END_DUE_DATE = 'endDueTime',
  DURATION = 'duration',
}

export enum DateTimeFormat {
  DATE = 'dd/MM/yyyy',
  TIME = 'HH:mm',
  DURATION = 'mm',
}

export interface Attachment {
  text: string;
  attachment?: string[];
}

export interface Team {
  accounter: string;
  consultant: string;
  informer: string;
  responsible: string;
  responsible2: string;
  responsible3: string;
}

export interface UploadComRecord {
  comment: string;
}

export interface ApplicableSubject {
  subjectId: string;
  value: string;
}

export interface CreateProcedureBody {
  frequency?: string;
  startDate: string;
  dueTime: string;
  endTime: string;
  objectiveText: string;
  objectiveAttachment: string[];
  procedureName: string;
  instructionText: string;
  instructionManualAttachment: string[];
  applicableSubject: ApplicableSubject;
  responsible: string;
  responsible2: string;
  responsible3?: string;
  accounter: string;
  consultant: string;
  informer: string;
  isApprovalNeeded: boolean;
  isCompleteRecordNeeded: boolean;
  checkListValues: string[];
  approval: boolean;
  scheduler?: string | null | SchedulerData;
  duration: number;
  endDate: string;
  comment?: string;
  removeFiles?: string[];
  isSchedule: boolean;
}

export type CheckList = { name: string; status: boolean; _id: string }[];

export type SchedulerData = {
  dtstart: string;
  freq: number;
  until: string;
};

export interface ProcedureResponse {
  applicableSubject: ApplicableSubject;
  approval: boolean;
  assignee: string;
  checkList: CheckList;
  createdAt: string;
  instructionManual: Attachment;
  objective: Attachment;
  proId: number;
  procedureName: string;
  schedule: string[];
  scheduler: SchedulerData;
  status: string;
  subjectId: string;
  team: Team;
  updatedAt: string;
  uploadComRecord: UploadComRecord;
  _id: string;
  frequency: string;
  isSchedule: boolean;
  dueTime: string;
  duration: number;
  isApprovalNeeded: boolean;
  isCompleteRecordNeeded: boolean;
  deleteComment?: string;
}

export interface ProcedureDeleteBody {
  id?: string;
  comment?: string;
}

export type SubjectsFormData = {
  objective: string;
  objectiveAttachments: string[];
  procedureName: string;
  instructionManual: string;
  instructionAttachments: string[];
  subject: ApplicableSubject;
  removeFiles?: string[];
};

export type MandateFormValues = {
  checkList: string[];
  uploadDocument: string;
  approval: boolean;
  isCompleteRecordNeeded: boolean;
};

interface Custom {
  custom: Weekday[];
}

export type TeamFormData =
  | Pick<
      CreateProcedureBody,
      | 'responsible'
      | 'responsible2'
      | 'responsible3'
      | 'accounter'
      | 'consultant'
      | 'informer'
      | 'dueTime'
      | 'startDate'
      | 'endDate'
      | 'frequency'
      | 'duration'
      | 'isSchedule'
    > & { scheduler?: SchedulerData | string | null } & Custom;

export type EditProcedureData = {
  _id: string;
  mandates: MandateFormValues;
  scheduleAndTeam: TeamFormData;
  procedureSubjects: SubjectsFormData;
};
interface CheckListItem {
  name: string;
  status: boolean;
}

export interface ProcedureUpdateTaskBody {
  id: string;
  reqData: CheckListItem;
}

export interface ProcedureCompletionRecordBody {
  id: string;
  formData: FormData;
}

export interface RemoveCompletionRecordBody {
  id: string;
  key: string;
}
