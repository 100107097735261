import {
  ProcedureProps,
  ProcedureStatus,
} from '../../../types/procedure.types';

const useActionButtons = ({ type }: ProcedureProps) => {
  const isNextUp = type === ProcedureStatus.NextUp;
  const isOngoing = type === ProcedureStatus.OnGoing;
  const isInformed = type === ProcedureStatus.Informed;
  const isApprovalsNeeded = type === ProcedureStatus.ApprovalsNeeded;
  const isSchedule = type === ProcedureStatus.Schedule;
  const isCallForHelp = type === ProcedureStatus.CallForHelp;

  return {
    showComplete: isOngoing,
    showApprove: isApprovalsNeeded,
    showLock: isNextUp || isSchedule || isCallForHelp,
    showCallForHelp: isNextUp || isOngoing || isCallForHelp,
    showDiffer: isInformed || isSchedule || isCallForHelp || isApprovalsNeeded,
    showReAssign:
      isSchedule || isCallForHelp || isInformed || isApprovalsNeeded,
  };
};

export default useActionButtons;
