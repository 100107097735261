import { useState } from 'react';

interface UseTableOptions {
  page?: number;
  limit?: number;
}

const useTable = ({ page = 0, limit = 100 }: UseTableOptions) => {
  const [searchText, setSearchText] = useState('');

  return {
    page: page + 1,
    limit,
    search: searchText,
    setSearchText,
  };
};

export default useTable;
