import { format, parseISO } from 'date-fns';
import { utcToZonedTime, zonedTimeToUtc } from 'date-fns-tz';

import { today } from '../components/home/time-filter/TimeFilter';
import { CST_TIMEZONE_OFFSET } from '../configs/constants';

type ZoneDateTime = { zonedDate: string; zonedTime: string };
export const displayDate = (dateString?: string) => {
  if (!dateString) {
    return '-';
  }

  const date = new Date(dateString);
  const zonedDate = utcToZonedTime(date, 'UTC'); // convert UTC time to local time zone
  return format(zonedDate, 'dd/MM/yyyy');
};

export const displayTime = (date?: string) =>
  date ? format(new Date(date), 'hh:mm') : '-';

export function formatStartEndDates(startDate: Date, endDate: Date) {
  const formattedStartDate = format(startDate, `yyyy-MM-dd\'T\'HH:mm:ss.SSS`);
  const formattedEndDate = format(endDate, `yyyy-MM-dd\'T\'HH:mm:ss.SSS`);
  return { firstDate: formattedStartDate, secondDate: formattedEndDate };
}

export const formatToISO = (date: Date) =>
  format(date, `yyyy-MM-dd\'T\'HH:mm:ss.SSS`);

export const formatDate = (date: Date) => {
  if (!date) {
    return '';
  }
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`;
};

export const convertZonedDateTime = (
  date: string | null,
  dateFormat = 'dd/MM/yyyy',
  timeFormat = 'HH:mm',
): ZoneDateTime => {
  if (!date) {
    return { zonedDate: '', zonedTime: '' };
  }
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const zonedDateTime = utcToZonedTime(date, timeZone);
  const zonedDate = format(zonedDateTime, dateFormat);
  const zonedTime = format(zonedDateTime, timeFormat);

  // will be more human readable
  // const zonedTime = format(zonedDateTime, 'hh:mm aa');

  return { zonedDate, zonedTime };
};

export const convertZonedTime = (time: string | null, timeFormat = 'HH:mm') => {
  const date = formatDate(today);
  const stringDate = new Date(`${date}T${time}:00.000Z`);
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  const zonedDateTime = utcToZonedTime(stringDate, timeZone);

  const zonedDueTime = format(zonedDateTime, timeFormat);

  return { zonedDueTime };
};

export const convertToCST = (
  dateTimeString: string,
  timezone: string,
): string => {
  const parsedDateTime = parseISO(dateTimeString);
  const utcDateTime = zonedTimeToUtc(parsedDateTime, timezone);
  const cstDateTime = utcToZonedTime(utcDateTime, 'UTC');

  return format(cstDateTime, "yyyy-MM-dd'T'HH:mm:ssXXX");
};

export const convertDueTimeToCST = (date: string, dueTime: string) => {
  const stringTime = new Date(
    convertToCST(`${date}T${dueTime}:00`, CST_TIMEZONE_OFFSET),
  );

  const hours = stringTime.getHours();
  const minutes = stringTime.getMinutes();

  // Pad single digits with leading zeros
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};
