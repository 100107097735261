import { FC, useState } from 'react';
import { DateRange } from 'react-date-range';

import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';

import { endOfDay } from 'date-fns';
import { Button, Popover } from 'devextreme-react';

import styles from './time-filter.module.scss';

interface CustomDatePickerProps {
  visible: boolean;
  startCustomDate: Date;
  endCustomDate: Date;
  setStartCustomDate: (date: Date) => void;
  setEndCustomDate: (date: Date) => void;
  onHiding: () => void;
}

interface DateRangeSelect {
  startDate: Date;
  endDate: Date;
  key: 'selection';
}

const CustomDatePicker: FC<CustomDatePickerProps> = ({
  visible,
  onHiding,
  startCustomDate,
  endCustomDate,
  setEndCustomDate,
  setStartCustomDate,
}) => {
  const [state, setState] = useState<DateRangeSelect[]>([
    { startDate: new Date(), endDate: new Date(), key: 'selection' },
  ]);

  const [uniqueKey] = useState(new Date().getTime());

  const onRangeSelected = () => {
    if (state && state.length && state[0].startDate && state[0].endDate) {
      const [{ startDate, endDate }] = state;

      if (startDate && endDate) {
        setStartCustomDate(startDate);
        setEndCustomDate(endOfDay(endDate));
        onHiding();
      }
    }
  };

  return (
    <>
      <div className={styles.customDateDisplay}>
        {startCustomDate && (
          <div>
            {startCustomDate.toDateString()} - {endCustomDate.toDateString()}{' '}
          </div>
        )}
        <a id={`target-${uniqueKey}`} />
      </div>
      <Popover
        target={`#target-${uniqueKey}`}
        position="top"
        visible={visible}
        onHiding={() => onHiding()}
        shading={true}
        shadingColor="rgba(0, 0, 0, 0.5)"
        width={365}
        height={450}
        hideOnOutsideClick
      >
        <div className={styles.customDateBox}>
          <DateRange
            onChange={(item: any) => {
              setState([item.selection]);
            }}
            ranges={state as []}
            rangeColors={['#bd55bb']}
          />
          <Button type="default" onClick={onRangeSelected}>
            Select Range
          </Button>
        </div>
      </Popover>
    </>
  );
};

export default CustomDatePicker;
