import { FC } from 'react';

import { GroupItem } from 'devextreme-react/form';
import { Switch } from 'devextreme-react/switch';
import { Item } from 'devextreme-react/tab-panel';

import styles from '../../pages/procedures/procedures.module.scss';
import { TeamFormData } from '../../types/procedure.types';
import { SchedulerData } from '../../types/procedure.types';
import { convertZonedDateTime, convertZonedTime } from '../../utils/dateTime';
import { fieldText } from '../../utils/fieldText';
import { firstLetterCapital } from '../../utils/letterCase';

interface RenderViewScheduleProps {
  data: TeamFormData;
}

const RenderViewSchedule: FC<RenderViewScheduleProps> = ({ data }) => {
  if (!data) return null;

  const { frequency, duration, dueTime } = data;

  let { scheduler } = data;

  if (scheduler && typeof scheduler !== 'object') {
    scheduler = JSON.parse(scheduler);
  }

  const { until, dtstart } = scheduler as SchedulerData;

  const { zonedDate: endZonedDate } = convertZonedDateTime(until);
  const { zonedDueTime } = convertZonedTime(dueTime);
  const { zonedDate: startZonedDate } = convertZonedDateTime(dtstart);

  return (
    <GroupItem caption={'SCHEDULE'} cssClass={styles.group1}>
      <Item>
        <div className={styles.scheduleBtn}>
          <Switch
            value={data?.isSchedule}
            className={styles.itemSwitch}
            disabled
          />
          <div className={styles.itemTitle}>Scheduled</div>
        </div>
      </Item>
      {fieldText('Frequency', firstLetterCapital(frequency || ''))}

      {fieldText('Start Date', startZonedDate || '-')}

      {fieldText('Due Time', zonedDueTime || '-')}

      {fieldText('End Date', endZonedDate || '-')}

      {fieldText('Duration (Minutes)', String(duration))}
    </GroupItem>
  );
};

export default RenderViewSchedule;
