import 'devextreme/dist/css/dx.common.css';
import './themes/generated/theme.base.css';
import './themes/generated/theme.additional.css';

import { BrowserRouter as Router } from 'react-router-dom';

import './dx-styles.scss';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import { AuthProvider, useAuth } from './contexts/auth';
import { NavigationProvider } from './contexts/navigation';
import { Content } from './routes/Content';
import { UnauthenticatedContent } from './routes/UnauthenticatedContent';
import { useScreenSizeClass } from './utils/media-query';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: false,
      cacheTime: 1,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  },
});

function App() {
  const { currentUser } = useAuth();

  if (currentUser) {
    return <Content />;
  }

  return <UnauthenticatedContent />;
}

export default function Root() {
  const screenSizeClass = useScreenSizeClass();

  return (
    <Router>
      <QueryClientProvider client={queryClient}>
        <AuthProvider>
          <NavigationProvider>
            <div className={`app ${screenSizeClass}`}>
              <App />
            </div>
          </NavigationProvider>
        </AuthProvider>
      </QueryClientProvider>
    </Router>
  );
}
