import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { PagingOptions } from '../types/pagination.types';
import { ApiServiceErr, DeleteBody, MutOptions, axiosApi } from './apiService';

interface CreateSubjectBody {
  title: string;
  subjectPrefix: string;
}

export interface CreateSubjectResponse {
  _id: string;
  title: string;
  values: string[];
  subjectPrefix: string;
  sequenceValue: number;
  createdAt: string;
  updatedAt: string;
}

export interface SubjectResponse {
  _id: string;
  valueId: string;
  name: string;
  subject: string;
  createdAt: string;
  updatedAt: string;
}

export interface AllSubjectNameResponse {
  subjectId: string;
  subjectName: string;
}

type DeleteSubjectResponse = Pick<CreateSubjectResponse, '_id'>;

interface GetSubjectByValueByIdParams extends PagingOptions {
  id: string;
}

export const useCreateSubject = (options?: MutOptions<CreateSubjectResponse>) =>
  useMutation<CreateSubjectResponse, ApiServiceErr, CreateSubjectBody>(
    async (data) => {
      const response = await axiosApi.post('subject/add', data);
      return response.data;
    },
    options,
  );

export const useGetSubjects = () =>
  useQuery<AllSubjectNameResponse[], ApiServiceErr>(
    ['/subject/all'],
    async () => {
      const response = await axiosApi.get('/subject/all');
      return response.data;
    },
  );

export const useGetSubjectValueById = (params: GetSubjectByValueByIdParams) =>
  useQuery<PagingResponse<SubjectResponse>, ApiServiceErr>(
    ['/subject/${id}?limit=${limit}&page=${page}', params],
    async () => {
      const response = await axiosApi.get(`/subject/${params.id}`, {
        params: params,
      });
      return response.data;
    },
  );

export const useDeleteSubject = (options?: MutOptions<DeleteSubjectResponse>) =>
  useMutation<DeleteSubjectResponse, ApiServiceErr, DeleteBody>(
    async (data) => {
      const response = await axiosApi.delete(`/subject/delete/${data.id}`);
      return response.data;
    },
    options,
  );

export const getSubjectValueById = (params: string, subjectId: string) =>
  axiosApi.get(`/subject/${subjectId}${params}`);
