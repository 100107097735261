import { FC, useState } from 'react';

import { Button, Popup } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import { useProcedureActionButton, useProcedureHelp } from '../../../api/home';
import { confirmationMsgs, errorMsgs, successMsgs } from '../../../configs';
import {
  ProcedureActionType,
  ProcedureProps,
} from '../../../types/procedure.types';
import { DeleteModal } from '../../delete-modal/DeleteModal';
import DifferForm from '../../differ/DifferForm';
import ReAssignForm from '../../re-assign/ReAssignForm';
import styles from './procedureCard.module.scss';
import useActionButtons from './useActionButtons';

const ActionButton: FC<{ text: string; onClick?: () => void }> = (props) => (
  <Button className={styles.actionButton} type="default" {...props} />
);

const ActionButtons: FC<{
  data: ProcedureProps;
  id: number;
  occurDate: string;
  refetch?: () => void;
}> = ({
  data,
  id,
  occurDate,
  refetch,
}: {
  data: ProcedureProps;
  id: number;
  occurDate: string;
  refetch?: () => void;
}) => {
  const {
    showComplete,
    showApprove,
    showLock,
    showCallForHelp,
    showDiffer,
    showReAssign,
  } = useActionButtons(data);

  const [showDifferForm, setShowDifferForm] = useState(false);
  const [showReAssignForm, setShowReAssignForm] = useState(false);
  const [showDeleteBtnLock, setShowDeleteBtnLock] = useState(false);
  const [showDeleteBtnCall, setShowDeleteBtnCall] = useState(false);
  const [showDeleteBtnComplete, setShowDeleteBtnComplete] = useState(false);
  let actionState = successMsgs.DEFAULT_ACTION_SUCCESS;

  const { mutate } = useProcedureActionButton({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(actionState, 'success');
      refetch && refetch();
    },
  });

  const { mutate: mutateHelp } = useProcedureHelp({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.PROCEDURE_HELP, 'success');
      refetch && refetch();
    },
  });

  return (
    <div className={styles.actions}>
      <div className={styles.headerBlock}>
        {showLock && (
          <ActionButton
            text="Lock"
            onClick={() => {
              setShowDeleteBtnLock(true);
              actionState = successMsgs.PROCEDURE_LOCKED;
            }}
          />
        )}

        {showComplete && (
          <ActionButton
            text="Complete"
            onClick={() => {
              setShowDeleteBtnComplete(true);
              actionState = successMsgs.PROCEDURE_COMPLETED;
            }}
          />
        )}
        {showDiffer && (
          <ActionButton text="Differ" onClick={() => setShowDifferForm(true)} />
        )}
        {showApprove && (
          <ActionButton
            text="Approve"
            onClick={() => {
              mutate({
                id: id.toString(),
                type: ProcedureActionType.Approve,
                occurDate,
              });
              actionState = successMsgs.PROCEDURE_APPROVED;
            }}
          />
        )}
        {showReAssign && (
          <ActionButton
            text="ReAssign"
            onClick={() => setShowReAssignForm(true)}
          />
        )}
        {showCallForHelp && (
          <Button
            className={styles.callForHelpButton}
            icon={'tel'}
            onClick={() => {
              setShowDeleteBtnCall(true);
              actionState = successMsgs.PROCEDURE_HELP;
            }}
          />
        )}
      </div>
      <Popup
        className={styles.reAssignDiffer}
        visible={showDifferForm}
        hideOnOutsideClick
        onHiding={() => setShowDifferForm(false)}
        title={'Are you sure you want to differ this Procedure?'}
        width={500}
        font-size="17px"
        height="auto"
        resizeEnabled={false}
        showCloseButton
      >
        <DifferForm
          onHiding={() => setShowDifferForm(false)}
          id={id}
          occurDate={occurDate}
          refetch={refetch}
        />
      </Popup>
      <Popup
        className={styles.reAssignDiffer}
        visible={showReAssignForm}
        hideOnOutsideClick
        onHiding={() => setShowReAssignForm(false)}
        title={'Re Assign User'}
        width={500}
        font-size="17px"
        height="auto"
        resizeEnabled={false}
        showCloseButton
      >
        <ReAssignForm
          onHiding={() => setShowReAssignForm(false)}
          id={id}
          occurDate={occurDate}
          refetch={refetch}
          queryEnabled={showReAssignForm}
        />
      </Popup>
      <DeleteModal
        visible={showDeleteBtnLock}
        onHiding={() => setShowDeleteBtnLock(false)}
        handleDelete={() => {
          mutate({
            id: id.toString(),
            type: ProcedureActionType.Lock,
            occurDate,
          });
          setShowDeleteBtnLock(false);
        }}
        massage={confirmationMsgs.LOCK_PROCEDURE}
      />
      <DeleteModal
        visible={showDeleteBtnCall}
        onHiding={() => setShowDeleteBtnCall(false)}
        handleDelete={() => {
          mutateHelp({ id: id.toString(), occurDate });
          setShowDeleteBtnCall(false);
        }}
        massage={confirmationMsgs.CALL_FOR_HELP}
      />
      <DeleteModal
        visible={showDeleteBtnComplete}
        onHiding={() => setShowDeleteBtnComplete(false)}
        handleDelete={() => {
          mutate({
            id: id.toString(),
            type: ProcedureActionType.Complete,
            occurDate,
          });
          setShowDeleteBtnComplete(false);
        }}
        massage={confirmationMsgs.COMPLETE_PROCEDURE}
      />
    </div>
  );
};

export default ActionButtons;
