import { FC } from 'react';
import { CSVLink } from 'react-csv';

import { Button } from 'devextreme-react';
import { RRule, datetime } from 'rrule';

import { useGetProceduresToExport } from '../../api/procedure';
import { convertZonedDateTime } from '../../utils/dateTime';
import { ruleDates, ruleDueTime } from '../../utils/rruleConverters';
import styles from './csvExport.module.scss';

interface ExportData {
  [key: string]: any;
  text?: string;
  subjectId?: { title: string };
  value?: { name: string };
  responsible?: { firstName: string };
  responsible2?: { firstName: string };
  responsible3?: { firstName: string };
  accounter?: { firstName: string };
  consultant?: { firstName: string };
  informer?: { firstName: string };
  freq: number;
  dtstart: string;
  until: string;
  proId: number;
}

const getScheduler = (object: ExportData) => {
  const { dtstart, until } = object;
  const { zonedDate: startDate, zonedTime: startTime } = convertZonedDateTime(
    dtstart,
    'yyyy-MM-dd',
  );
  const {
    year: startYear,
    month: startMonth,
    day: startDay,
  } = ruleDates(startDate);
  const { hour, minutes } = ruleDueTime(startTime);

  const { zonedDate: endDate } = convertZonedDateTime(until, 'yyyy-MM-dd');

  const { year: endYear, month: endMonth, day: endDay } = ruleDates(endDate);

  const rule = new RRule({
    freq: object.freq,
    dtstart: datetime(startYear, startMonth, startDay, hour, minutes),
    until: datetime(endYear, endMonth, endDay),
  });

  return rule.toText();
};

const team = (object: ExportData) => {
  const teamNames = [
    object.responsible?.firstName || '-',
    object.responsible2?.firstName || '-',
    object.responsible3?.firstName || '-',
    object.accounter?.firstName || '-',
    object.consultant?.firstName || '-',
    object.informer?.firstName || '-',
  ];
  return teamNames;
};

function textValue(object: ExportData) {
  switch (Object.keys(object)[0]) {
    case 'text':
      return object.text;
    case 'dtstart':
      return getScheduler(object);
    case 'subjectId':
      return [object?.subjectId?.title, object?.value?.name];
    case 'responsible':
      return team(object);
    default:
      return `Not schedule`;
  }
}

export const CsvExport: FC = () => {
  const { data: procedureData } = useGetProceduresToExport();

  if (!procedureData) return null;

  function csvExport() {
    const data = procedureData as unknown as ExportData[];

    const keys = [
      'proId',
      'procedureName',
      'objective',
      'instructionManual',
      'applicableSubject',
      'team',
      'frequency',
      'dueTime',
      'isSchedule',
      'scheduler',
      'isCompleteRecordNeeded',
      'isApprovalNeeded',
      'approval',
      'status',
      'duration',
      'createdAt',
      'updatedAt',
    ];
    const header = keys.map((key) => key.toUpperCase());
    const rows = data?.map((obj) =>
      keys.map((key) => {
        if (key === 'dueTime') {
          const { zonedTime } = convertZonedDateTime(obj.scheduler.until);
          return zonedTime;
        }

        if (key === 'createdAt') {
          const { zonedDate, zonedTime } = convertZonedDateTime(obj.createdAt);
          return `${zonedDate} ${zonedTime}`;
        }
        if (key === 'updatedAt') {
          const { zonedDate, zonedTime } = convertZonedDateTime(obj.updatedAt);
          return `${zonedDate} ${zonedTime}`;
        }

        return typeof obj[key] === 'object' ? textValue(obj[key]) : obj[key];
      }),
    );
    return (
      <CSVLink
        className={styles.cvsLink}
        data={[header, ...rows]}
        filename="mydata.csv"
      >
        <Button type="default" text={'Export to CSV'} />
      </CSVLink>
    );
  }

  return <div>{csvExport()}</div>;
};
