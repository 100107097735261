import { CreateProcedureBody } from '../types/procedure.types';

export default (formData: CreateProcedureBody) => {
  const reqForm = new FormData();
  if (formData.frequency) {
    reqForm.append('frequency', formData.frequency);
  }
  reqForm.append('isSchedule', formData.isSchedule.toString());
  reqForm.append('startDate', formData.startDate);
  reqForm.append('dueTime', formData.dueTime);
  if (formData.endDate) {
    reqForm.append('endTime', formData.endTime);
  }
  reqForm.append('objectiveText', formData.objectiveText);
  reqForm.append('procedureName', formData.procedureName);
  reqForm.append('instructionText', formData.instructionText);
  reqForm.append(
    'applicableSubject',
    JSON.stringify(formData.applicableSubject),
  );

  reqForm.append('responsible', formData.responsible);

  if (formData.responsible2) {
    reqForm.append('responsible2', formData.responsible2);
  }
  if (formData.responsible3) {
    reqForm.append('responsible3', formData.responsible3);
  }
  reqForm.append('accounter', formData.accounter);
  reqForm.append('consultant', formData.consultant);
  reqForm.append('informer', formData.informer);
  reqForm.append('isApprovalNeeded', formData.isApprovalNeeded.toString());
  reqForm.append(
    'isCompleteRecordNeeded',
    formData.isCompleteRecordNeeded.toString(),
  );
  for (let i = 0; i < formData.checkListValues.length; i++) {
    reqForm.append(`checkListValues[${i}]`, formData.checkListValues[i]);
  }

  if (formData?.removeFiles?.length) {
    for (let i = 0; i < formData?.removeFiles?.length; i++) {
      reqForm.append(`removeFiles[${i}]`, formData.removeFiles[i]);
    }
  }

  for (let i = 0; i < formData?.objectiveAttachment?.length; i++) {
    reqForm.append(`objectiveAttachment`, formData.objectiveAttachment[i]);
  }

  for (let i = 0; i < formData?.instructionManualAttachment?.length; i++) {
    reqForm.append(
      'instructionManualAttachment',
      formData.instructionManualAttachment[i],
    );
  }

  reqForm.append('approval', formData.approval.toString());
  if (formData.scheduler === null) {
    reqForm.append('scheduler', String(formData.scheduler));
  } else if (formData.scheduler) {
    reqForm.append('scheduler', String(formData.scheduler));
  }
  reqForm.append('duration', String(formData.duration));
  if (formData.endDate) {
    reqForm.append('endDate', formData.endDate);
  }
  if (formData.comment) {
    reqForm.append('comment', formData.comment);
  }

  return reqForm;
};
