import { FC, useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import Button from 'devextreme-react/button';
import Form, {
  ButtonItem,
  ButtonOptions,
  EmailRule,
  GroupItem,
  Item,
  Label,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useLogin } from '../../api/auth';
import { errorMsgs, formValidationMsg } from '../../configs';
import { useAuth } from '../../contexts/auth';
import eye from '../../images/hide.png';
import eyeSlash from '../../images/show.png';
import styles from './LoginForm.module.scss';

const { SOMETHING_WRONG } = errorMsgs;

export const LoginForm: FC = () => {
  document.title = 'Sign In';
  const navigate = useNavigate();
  const formData = useRef({ email: '', password: '' });
  const [showPassword, setShowPassword] = useState(false);

  const { setToken, setCurrentUser } = useAuth();
  const { isLoading, mutate } = useLogin({
    onError: (error) => {
      notify(error?.msg || SOMETHING_WRONG, 'error');
    },
    onSuccess: (response) => {
      const { _id, token, firstName, lastName, email, role, image } = response;
      setToken(token);
      const currentUser = { _id, firstName, lastName, email, role, image };
      setCurrentUser(currentUser);
      navigate('/');
    },
  });

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const { email, password } = formData.current;
      mutate({ email, password });
    },
    [mutate],
  );

  const handlePasswordVisibility = useCallback(() => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  }, []);

  const passwordEditorOptions = {
    stylingMode: 'filled',
    placeholder: 'Password',
    mode: showPassword ? 'text' : 'password',
    onIconClick: { handlePasswordVisibility },
  };

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={isLoading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message={formValidationMsg.EMAIL_REQUIRED} />
          <EmailRule message={formValidationMsg.EMAIL_INVALID} />
          <Label visible={false} />
        </Item>

        <GroupItem colCount={3}>
          <GroupItem cssClass={styles.passwordEditor}>
            <Item
              dataField={'password'}
              editorType={'dxTextBox'}
              editorOptions={passwordEditorOptions}
            >
              <RequiredRule message={formValidationMsg.PASSWORD_REQUIRED} />
              <Label visible={false} />
            </Item>
          </GroupItem>

          <GroupItem cssClass={styles.showHiddenIcon}>
            <Item>
              <Button
                id="button1"
                type="normal"
                icon={showPassword ? eyeSlash : eye}
                onClick={() => {
                  handlePasswordVisibility();
                }}
              />
            </Item>
          </GroupItem>
        </GroupItem>
        <ButtonItem>
          <ButtonOptions
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {isLoading ? (
                <LoadIndicator width={'24px'} height={'24px'} visible={true} />
              ) : (
                'Login'
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
      </Form>
    </form>
  );
};

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
};
