import React, { FC, useState } from 'react';

import { Button, Popup } from 'devextreme-react';
import TabPanel, { Item } from 'devextreme-react/tab-panel';

import { useGetSubjects } from '../../api/subject';
import { AddSubjectForm } from '../../components/subject-form/AddSubjectForm';
import { UserRoleEnum } from '../../types/user.types';
import { useIsUserRole } from '../../utils/useIsUserRole';
import styles from './Subject.module.scss';
import SubjectTable from './SubjectTable';

const SubjectsTabs: FC = () => {
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState();
  const { data: SubjectData, refetch } = useGetSubjects();

  return (
    <>
      <div className={styles.buttonContainer}>
        <Button
          className={styles.addButton}
          text="Add Subject"
          icon="add"
          type="default"
          onClick={() => setShowSubjectModal(!showSubjectModal)}
          disabled={useIsUserRole(UserRoleEnum.User)}
        />
      </div>
      <div>
        <TabPanel
          onSelectedItemChange={(selectedItemKeys) =>
            setSelectedItem(selectedItemKeys)
          }
        >
          {SubjectData?.map((data) => (
            <Item key={data.subjectId} title={data?.subjectName}>
              {
                <SubjectTable
                  selectedTab={data.subjectId}
                  selectedItem={selectedItem}
                />
              }
            </Item>
          ))}
        </TabPanel>
      </div>

      <Popup
        visible={showSubjectModal}
        hideOnOutsideClick
        onHiding={() => setShowSubjectModal(false)}
        title={'Create New Subject'}
        width={500}
        height={325}
        resizeEnabled
        showCloseButton
      >
        <AddSubjectForm
          onFinished={() => {
            refetch();
            setShowSubjectModal(false);
          }}
        />
      </Popup>
    </>
  );
};

export default SubjectsTabs;
