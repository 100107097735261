import { FC } from 'react';

import { Button } from 'devextreme-react/button';
import Popup from 'devextreme-react/popup';

import styles from './deleteModal.module.scss';

interface DeletePopUpProps {
  visible: boolean;
  onHiding: () => void;
  handleDelete: () => void;
  massage: string;
}

export const DeleteModal: FC<DeletePopUpProps> = ({
  visible,
  onHiding,
  handleDelete,
  massage,
}) => (
  <Popup
    visible={visible}
    onHiding={onHiding}
    title={'Warning'}
    width={500}
    height={200}
    resizeEnabled={false}
    showCloseButton
  >
    <div className={styles.contentMsg}>{massage}</div>

    <div className={styles.deleteBtn}>
      <Button
        className={styles.deleteYesNOBtn}
        type="default"
        width={'20%'}
        onClick={onHiding}
      >
        No
      </Button>
      <Button
        className={styles.deleteYesNOBtn}
        type="default"
        width={'20%'}
        onClick={handleDelete}
      >
        Yes
      </Button>
    </div>
  </Popup>
);
