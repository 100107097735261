import React, { FC } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';

import { useGetActivityProcedure } from '../../api/activityProcedure';
import { TableWrapper } from '../../components';
import { SectionTitle } from '../text/Text';
import styles from './consultantTable.module.scss';

const ConsultantTable: FC = () => {
  const { data: procedureData } = useGetActivityProcedure();
  if (!procedureData || !procedureData.length) return null;

  return (
    <div className={styles.consultantTableWrapper}>
      <SectionTitle>Consultants</SectionTitle>
      <TableWrapper>
        <DataGrid dataSource={procedureData} keyExpr={'_id'}>
          <Column dataField={'procedureName'} caption={'Procedure Name'} />
          <Column
            dataField="applicableSubject.value.name"
            caption={'Subject / Client'}
          />
          <Column dataField="team.informer.firstName" caption={'User Name'} />
          <Paging defaultPageIndex={0} defaultPageSize={5} />
          <Pager visible allowedPageSizes={[5, 10, 20]} showNavigationButtons />
        </DataGrid>
      </TableWrapper>
    </div>
  );
};
export default ConsultantTable;
