import { FC, useCallback, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import Form, {
  ButtonItem,
  CustomRule,
  EmptyItem,
  Item,
  Label,
  RequiredRule,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { useChangeOwnPassword } from '../../api/profile';
import { errorMsgs, formValidationMsg, successMsgs } from '../../configs';
import { ValidationType } from '../../types';

const { SOMETHING_WRONG } = errorMsgs;
const { PASSWORD_CHANGED } = successMsgs;
const {
  CURRENT_PW_REQUIRED,
  NEW_PW_REQUIRED,
  CONFIRM_PW_REQUIRED,
  PASSWORDS_DOES_NOT_MATCH,
} = formValidationMsg;

export const ChangeOwnPasswordForm: FC = () => {
  const navigate = useNavigate();
  const formData = useRef({
    newPassword: '',
    currentPassword: '',
    confirmPassword: '',
  });

  const { mutate, isLoading } = useChangeOwnPassword({
    onError: (error) => {
      notify(error?.msg || SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(PASSWORD_CHANGED, 'success');
      formData.current = {
        newPassword: '',
        currentPassword: '',
        confirmPassword: '',
      };
    },
  });

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      mutate(formData.current);
    },
    [navigate],
  );

  const testPassword = useCallback(
    ({ value }: ValidationType) => value === formData.current.newPassword,
    [],
  );

  return (
    <form onSubmit={onSubmit}>
      <Form
        formData={formData.current}
        labelMode={'floating'}
        disabled={isLoading}
        colCount={3}
      >
        <Item
          dataField={'currentPassword'}
          editorType={'dxTextBox'}
          editorOptions={passwordInputOptions}
        >
          <RequiredRule message={CURRENT_PW_REQUIRED} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'newPassword'}
          editorType={'dxTextBox'}
          editorOptions={passwordInputOptions}
        >
          <RequiredRule message={NEW_PW_REQUIRED} />
          <Label visible={false} />
        </Item>
        <Item
          dataField={'confirmPassword'}
          editorType={'dxTextBox'}
          editorOptions={passwordInputOptions}
        >
          <RequiredRule message={CONFIRM_PW_REQUIRED} />
          <CustomRule
            message={PASSWORDS_DOES_NOT_MATCH}
            validationCallback={testPassword}
          />
          <Label visible={false} />
        </Item>
        {/* TODO:Find a better way to align items inside a Form */}
        <EmptyItem colSpan={2} />
        <ButtonItem
          horizontalAlignment="right"
          buttonOptions={{
            text: 'Change Password',
            type: 'default',
            useSubmitBehavior: true,
          }}
        />
      </Form>
    </form>
  );
};

const passwordInputOptions = {
  mode: 'password',
};
