import { FC, useEffect, useState } from 'react';

import { useGetMyProcedures } from '../../../api/home';
import useProcedureSocketTrigger from '../../../customHooks/useProcedureSocketTrigger';
import { ProcedureStatus } from '../../../types/procedure.types';
import { UserRoles } from '../../../types/user.types';
import { SocketEnum } from '../../../types/webSocketEvent.types';
import { formatToISO } from '../../../utils/dateTime';
import { filterProcedureList } from '../../../utils/filterProcedureLIst';
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator';
import { NoData } from '../../no-data/NoData';
import ProcedureCard from '../procedure-card/ProcedureCard';
import ProcedureColumn from '../procedures-column/ProcedureColumn';
import TimeFilter, { today, tonight } from '../time-filter/TimeFilter';
import styles from './accountable.module.scss';

const socketEventList = [
  SocketEnum.NewProcedure,
  SocketEnum.HelpRequest,
  SocketEnum.Complete,
  SocketEnum.LockProcedure,
  SocketEnum.DeleteActivityProcedure,
  SocketEnum.Reassigned,
];

const Accountable: FC<{ selectedTabIndex: number }> = ({
  selectedTabIndex,
}) => {
  const [completeBtn, setCompleteBtn] = useState(false);
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tonight);

  const {
    data: list = [],
    refetch,
    isLoading,
  } = useGetMyProcedures({
    teamRole: 'accounter',
    startDate: formatToISO(startDate),
    endDate: formatToISO(endDate),
    completeStatus: completeBtn,
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate, completeBtn]);

  useEffect(() => {
    if (selectedTabIndex === 2) {
      refetch();
    }
  }, [selectedTabIndex]);

  useProcedureSocketTrigger(refetch, [UserRoles.Accounter], socketEventList);

  const approvalsNeededList = filterProcedureList(
    list,
    ProcedureStatus.ApprovalsNeeded,
  );
  const scheduleList = filterProcedureList(list, ProcedureStatus.Active);
  const completedList = filterProcedureList(list, ProcedureStatus.Completed);
  const callForHelpList = filterProcedureList(
    list,
    ProcedureStatus.CallForHelp,
  );

  useEffect(() => {
    refetch();
  }, [startDate, endDate, completeBtn]);

  const approvalsNeeded = approvalsNeededList?.map((data: any) => (
    <ProcedureCard
      key={data.id}
      data={data}
      type={ProcedureStatus.ApprovalsNeeded}
      refetch={refetch}
    />
  ));

  const schedule = scheduleList?.map((data: any) => (
    <ProcedureCard
      key={data.id}
      data={data}
      type={ProcedureStatus.Schedule}
      refetch={refetch}
    />
  ));

  const completed = completedList?.map((data: any) => (
    <ProcedureCard
      key={data.id}
      data={data}
      type={ProcedureStatus.Completed}
      refetch={refetch}
    />
  ));

  const callForHelp = callForHelpList?.map((data: any) => (
    <ProcedureCard
      key={data.id}
      data={data}
      type={ProcedureStatus.CallForHelp}
      refetch={refetch}
    />
  ));

  const completedOrScheduled = completeBtn ? completed : schedule;

  return (
    <section className={styles.mainContainer}>
      <TimeFilter
        type="informed"
        onFilterChange={(startDate: Date, endDate: Date) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
        onComplete={(value) => setCompleteBtn(value)}
      />
      <div className={styles.accountableContainer}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            <ProcedureColumn title="Approvals Needed">
              {approvalsNeeded.length > 0 ? approvalsNeeded : <NoData />}
            </ProcedureColumn>
            <ProcedureColumn title="Schedule">
              {completedOrScheduled.length > 0 ? (
                completedOrScheduled
              ) : (
                <NoData />
              )}
            </ProcedureColumn>
            <ProcedureColumn title="Call For Help Requested">
              {callForHelp.length > 0 ? callForHelp : <NoData />}
            </ProcedureColumn>
          </>
        )}
      </div>
    </section>
  );
};

export default Accountable;
