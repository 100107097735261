import { FC } from 'react';

import { Popup } from 'devextreme-react';
import notify from 'devextreme/ui/notify';

import { useRegister, useUpdateUser } from '../../api/auth';
import { errorMsgs, successMsgs } from '../../configs';
import { GetUserResponse } from '../../types/user.types';
import { UserForm } from './UserForm';

interface UserModalProps {
  visible: boolean;
  onHiding: () => void;
  selectedUser?: GetUserResponse;
}

const UserModal: FC<UserModalProps> = ({ visible, selectedUser, onHiding }) => {
  const { isLoading: isLoadingRegister, mutate: mutateRegister } = useRegister({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.USER_ADDED, 'success');
      onHiding();
    },
  });

  const { isLoading: isLoadingUserUpdate, mutate: mutateUserUpdate } =
    useUpdateUser({
      onError: (error) => {
        notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
      },
      onSuccess: () => {
        notify(successMsgs.USER_UPDATED, 'success');
        onHiding();
      },
    });

  if (!visible) {
    return null;
  }
  return (
    <Popup
      visible={visible}
      onHiding={() => onHiding()}
      title={selectedUser ? 'Edit User' : 'Add User'}
      width={500}
      height="auto"
      resizeEnabled={false}
      showCloseButton
    >
      <UserForm
        selectedUser={selectedUser}
        isLoading={isLoadingRegister || isLoadingUserUpdate}
        onSubmit={(data: any) => {
          if (selectedUser) {
            mutateUserUpdate({ id: selectedUser?._id, data });
          } else {
            mutateRegister(data);
          }
        }}
        onHiding={onHiding}
      />
    </Popup>
  );
};

export default UserModal;
