import { FC } from 'react';

import Menu, { Item } from 'devextreme-react/menu';

interface SubjectActionMenuProps {
  onClickEdit?: () => void;
  onClickDelete: () => void;
  onClickResetPassword?: () => void;
  disabled: boolean;
  disableEdit?: boolean;
  enableResetPassword?: boolean;
  disableDelete?: boolean;
}

export const ActionDropDownMenu: FC<SubjectActionMenuProps> = ({
  onClickEdit,
  onClickDelete,
  onClickResetPassword,
  disabled,
  disableEdit = false,
  enableResetPassword = false,
  disableDelete = false,
}) => {
  const onItemClick = (e: any) => {
    switch (e.itemData.text) {
      case 'Edit':
        if (onClickEdit) onClickEdit();
        break;
      case 'Delete':
        onClickDelete();
        break;
      case 'Reset Password':
        if (onClickResetPassword) onClickResetPassword();
        break;
    }
  };

  return (
    <div id="container">
      <Menu width={400} onItemClick={onItemClick} disabled={disabled}>
        <Item icon={'overflow'}>
          {!disableEdit && <Item text={'Edit'} />}
          {!disableDelete && <Item text={'Delete'} />}
          {enableResetPassword && <Item text={'Reset Password'} />}
        </Item>
      </Menu>
    </div>
  );
};
