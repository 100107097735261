import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { SearchOptions } from '../types/pagination.types';
import { ApiServiceErr, DeleteBody, MutOptions, axiosApi } from './apiService';

export interface ClientResponse {
  _id: string;
  clientId: string;
  name: string;
  createdAt: string;
  updatedAt: string;
}

export type CreateClientBody = Pick<ClientResponse, 'name'>;

export interface ClientUpdateBody {
  data: CreateClientBody;
  id: string;
}

export const useGetClients = ({ search }: SearchOptions) =>
  useQuery<PagingResponse<ClientResponse[]>, ApiServiceErr>(
    ['/client/all', search],
    async () => {
      const response = await axiosApi.get(
        `/client/all?search=${search?.toString()}`,
      );
      return response.data;
    },
  );

export const useCreateClient = (options?: MutOptions<ClientResponse>) =>
  useMutation<ClientResponse, ApiServiceErr, CreateClientBody>(async (data) => {
    const response = await axiosApi.post(`/client/create`, data);
    return response.data;
  }, options);

export const useUpdateClient = (options?: MutOptions<ClientResponse>) =>
  useMutation<ClientResponse, ApiServiceErr, ClientUpdateBody>(
    async (reqData) => {
      const { id, data } = reqData;
      const response = await axiosApi.put(`/client/update/${id}`, data);
      return response.data;
    },
    options,
  );

export const useDeleteClient = (options?: MutOptions<ClientResponse>) =>
  useMutation<ClientResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/client/delete/${data.id}`);
    return response.data;
  }, options);
