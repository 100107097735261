import { FC, useEffect, useState } from 'react';

import {
  addDays,
  addWeeks,
  endOfDay,
  endOfWeek,
  startOfDay,
  startOfWeek,
} from 'date-fns';

import 'react-date-range/dist/styles.css';
// main style file
import 'react-date-range/dist/theme/default.css';

// theme css file
import cx from 'classnames';
import { Switch } from 'devextreme-react/switch';

import { ProcedureStatus } from '../../../types/procedure.types';
import CustomDatePicker from './CustomDatePicker';
import styles from './time-filter.module.scss';

const CUSTOM_FILTER = 'Custom';

const now = new Date();
export const today = startOfDay(now);
export const tonight = endOfDay(now); // NOTE: tonight is today because we handle tonight in dateTime.ts
const tomorrow = addDays(today, 1);
const tomorrowNight = addDays(tonight, 1); // NOTE: tomorrowNight is tomorrow because we handle tonight in dateTime.ts
const thisWeekStart = startOfWeek(today, { weekStartsOn: 1 });
const thisWeekEnd = endOfWeek(tonight, { weekStartsOn: 1 });
const nextWeekStart = addWeeks(thisWeekStart, 1);
const endOfNextWeek = addWeeks(thisWeekEnd, 1);

type FilterItem = {
  label: string;
  start: Date | null;
  end: Date | null;
};

const filterList: FilterItem[] = [
  {
    label: 'Today',
    start: today,
    end: tonight,
  },
  {
    label: 'Today & Tomorrow',
    start: today,
    end: tomorrowNight,
  },
  {
    label: 'Tomorrow',
    start: tomorrow,
    end: tomorrowNight,
  },
  { label: 'This Week', start: thisWeekStart, end: thisWeekEnd },
  { label: 'Next Week', start: nextWeekStart, end: endOfNextWeek },
  { label: CUSTOM_FILTER, start: null, end: null },
];

interface Props {
  type: string;
  onFilterChange?: (start: Date, end: Date) => void;
  onComplete?: (value: boolean) => void;
}

const DaysFilter: FC<Props> = ({ type, onFilterChange, onComplete }) => {
  const [startCustomDate, setStartCustomDate] = useState<Date>(today);
  const [endCustomDate, setEndCustomDate] = useState<Date>(tonight);
  const [toggleButton, setToggleButton] = useState(false);
  const [showCustomPop, setShowCustomPop] = useState(false);
  const [filter, setFilter] = useState(filterList[0]);

  const isInformed = type === ProcedureStatus.Informed;

  const handleFilterChange = (newFilter: FilterItem) => {
    setFilter(newFilter);
    if (
      newFilter.start &&
      newFilter.end &&
      onFilterChange &&
      newFilter.label !== CUSTOM_FILTER
    ) {
      onFilterChange(newFilter.start, newFilter.end);
    }

    if (newFilter.label === CUSTOM_FILTER) {
      setShowCustomPop(true);
    }
  };

  useEffect(() => {
    if (onFilterChange) {
      onFilterChange(startCustomDate, endCustomDate);
    }
  }, [startCustomDate, endCustomDate]);

  const filters = filterList.map((filterItem, index) => (
    <li key={index} className={cx({ [styles.active]: filter === filterItem })}>
      <a onClick={() => handleFilterChange(filterItem)}>{filterItem.label}</a>
    </li>
  ));

  useEffect(() => {
    if (onComplete) onComplete(toggleButton);
  }, [toggleButton]);

  return (
    <div className={styles.wrapper}>
      <ul className={styles.filtersList}>
        {filters}
        {filter.label === CUSTOM_FILTER && (
          <CustomDatePicker
            onHiding={() => setShowCustomPop(false)}
            visible={showCustomPop}
            startCustomDate={startCustomDate}
            endCustomDate={endCustomDate}
            setEndCustomDate={setEndCustomDate}
            setStartCustomDate={setStartCustomDate}
          />
        )}
      </ul>

      <div className={styles.toggleButton}>
        {isInformed && (
          <>
            <h6>Completed</h6>
            <Switch
              defaultValue={false}
              onValueChange={() => setToggleButton(!toggleButton)}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default DaysFilter;
