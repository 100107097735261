import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { Button } from 'devextreme-react';
import FileUploader from 'devextreme-react/file-uploader';
import { Form, GroupItem, SimpleItem } from 'devextreme-react/form';
import { Item } from 'devextreme-react/tab-panel';
import { RequiredRule } from 'devextreme-react/validator';
import { ClickEvent } from 'devextreme/ui/button';
import { ValueChangedEvent } from 'devextreme/ui/file_uploader';

import { useGetSubjects } from '../../api/subject';
import {
  ALLOWED_DOCUMENT_FILE_TYPE,
  ALLOWED_FILE_TYPE,
  TIME_OUT,
  fileExtensions,
  formValidationMsg,
} from '../../configs';
import { SubjectsFormData } from '../../types/procedure.types';
import SearchSubjects from './SearchSubjects';
import styles from './procedures.module.scss';

const initialFormData = {
  objective: '',
  objectiveAttachments: [],
  procedureName: '',
  instructionManual: '',
  instructionAttachments: [],
  subject: {
    subjectId: '',
    value: '',
  },
};

type ProcedureSubjectsFormProps = {
  onProcedureSubject: (data: SubjectsFormData) => void;
  clearSubFormData: boolean;
  data?: SubjectsFormData | undefined;
};

const getFileName = (file: any) => {
  if (typeof file === 'string') {
    const pathArr = file.split('/');
    return pathArr[pathArr.length - 1].replace(/_[^_]*(?=\.)/, '');
  }
  return file.name;
};

const ProcedureSubjectsForm: FC<ProcedureSubjectsFormProps> = ({
  onProcedureSubject,
  clearSubFormData,
  data,
}) => {
  const { data: subjects } = useGetSubjects();
  const [selectedSubject, setSelectedSubject] = useState('');
  const [selectedSubjectValue, setSelectedSubjectValue] = useState('');
  const [formData, setFormData] = useState<SubjectsFormData>({
    ...initialFormData,
    ...data,
  });
  const [showSubjectError, setShowSubjectError] = useState(false);
  const formRef: any = useRef(null);
  const [fileTypeError, setFileTypeError] = useState('');

  const isValidFileType = (file: any, allowedFileTypes: string[]) => {
    if (!(file instanceof File)) {
      return false;
    }
    return allowedFileTypes.includes(file.type);
  };

  const onValueChanged = (e: ValueChangedEvent, key: string) => {
    if (!e.value) {
      return;
    }
    const { value } = e;
    if (value) {
      for (const file of value) {
        if (!isValidFileType(file, ALLOWED_DOCUMENT_FILE_TYPE)) {
          setFileTypeError(
            `Unsupported file type. Only specific file types are allowed ${ALLOWED_FILE_TYPE}.`,
          );
          setTimeout(() => {
            setFileTypeError('');
          }, TIME_OUT);
          return;
        }
      }
    }

    const currentData = formData[key as keyof SubjectsFormData];
    setFormData({
      ...formData,
      [key]: [...(currentData as string[]), ...e.value],
    });
  };

  const removeAttachment = (index: number, key: string) => {
    const currentData = [
      ...(formData[key as keyof SubjectsFormData] as string[]),
    ];
    const file = currentData[index];
    const removeFiles = [];
    if (typeof file === 'string') removeFiles.push(file);

    if (formData.removeFiles) {
      removeFiles.push(...formData.removeFiles);
    }

    currentData?.splice(index, 1);
    setFormData({
      ...formData,
      [key]: currentData,
      removeFiles,
    });
  };

  useEffect(() => {
    if (clearSubFormData) {
      setFormData({
        ...initialFormData,
        objective: '',
        procedureName: '',
        instructionManual: '',
      });
      setSelectedSubject('');
      setSelectedSubjectValue('');
    }
  }, [clearSubFormData]);

  useEffect(() => {
    if (data) {
      const {
        subject: { subjectId, value },
      } = data;

      setSelectedSubject(subjectId);
      setSelectedSubjectValue(value);
    }
  }, [data]);

  const validateSubject = () => {
    const { subject } = formData;
    if (subject.subjectId === '' || subject.value === '') {
      setShowSubjectError(true);
      return false;
    }
    setShowSubjectError(false);
    return true;
  };

  const handleSubmit = useCallback(
    async (event: ClickEvent) => {
      const { event: e } = event;
      e?.preventDefault();
      const { isValid } = formRef.current?.instance.validate();

      if (!isValid) return;

      const validatedSubject = validateSubject();

      if (!validatedSubject) {
        return;
      }
      onProcedureSubject(formData);
    },
    [formData],
  );

  const handleSubjectChange = (event: ChangeEvent<HTMLInputElement>) => {
    const subjectId = event.target.id;
    setSelectedSubject(subjectId);
    setSelectedSubjectValue('');
    setFormData({
      ...formData,
      subject: {
        subjectId,
        value: '',
      },
    });
  };

  const subjectList = subjects?.map(({ subjectId, subjectName }) => (
    <Item key={subjectId}>
      <div className={styles.radioButtonWrapper}>
        <input
          id={subjectId}
          type="radio"
          name="subject"
          value={subjectName}
          onChange={handleSubjectChange}
          checked={selectedSubject === subjectId}
        />
        <label htmlFor={subjectId}>{subjectName}</label>
      </div>

      <SearchSubjects
        visible={selectedSubject === subjectId}
        subjectId={subjectId}
        selected={selectedSubjectValue}
        onSelectedValues={(data) => {
          setSelectedSubjectValue(data);
          setFormData((prev) => ({
            ...prev,
            subject: {
              subjectId: selectedSubject,
              value: data,
            },
          }));
        }}
      />
    </Item>
  ));

  return (
    <>
      <Form
        ref={formRef}
        labelMode={'floating'}
        className={styles.formContainer}
        formData={formData}
        scrollingEnabled
      >
        <GroupItem colCount={2}>
          <GroupItem caption={'THE PROCEDURE'} cssClass={styles.group1}>
            <SimpleItem
              dataField={'objective'}
              label={{ text: 'Objective' }}
              isRequired
            >
              <RequiredRule message={formValidationMsg.OBJECTIVE_REQUIRED} />
            </SimpleItem>
            <Item>
              <FileUploader
                selectButtonText={'Upload attachments'}
                labelText={''}
                uploadMode={'useForm'}
                allowedFileExtensions={fileExtensions}
                multiple
                onValueChanged={(e: ValueChangedEvent) => {
                  onValueChanged(e, 'objectiveAttachments');
                }}
                accept={ALLOWED_FILE_TYPE}
              />
              <ul>
                {formData.objectiveAttachments?.map(
                  (file: any, index: number) => (
                    <li className={styles.attachmentsListWrapper} key={index}>
                      {getFileName(file)}
                      <Button
                        icon="remove"
                        stylingMode="text"
                        className={styles.removeIcon}
                        onClick={() => {
                          removeAttachment(index, 'objectiveAttachments');
                        }}
                      />
                    </li>
                  ),
                )}
              </ul>
            </Item>
            <SimpleItem
              dataField={'procedureName'}
              label={{ text: 'Procedure Name' }}
              isRequired
            >
              <RequiredRule
                message={formValidationMsg.PROCEDURE_NAME_REQUIRED}
              />
            </SimpleItem>
            <SimpleItem
              dataField={'instructionManual'}
              label={{ text: 'Instruction Manual' }}
              isRequired
            >
              <RequiredRule
                message={formValidationMsg.INSTRUCTION_MANUAL_REQUIRED}
              />
            </SimpleItem>
            <Item>
              <FileUploader
                selectButtonText={'Upload attachments'}
                labelText={''}
                uploadMode={'useForm'}
                allowedFileExtensions={fileExtensions}
                onValueChanged={(e: ValueChangedEvent) => {
                  onValueChanged(e, 'instructionAttachments');
                }}
                accept={ALLOWED_FILE_TYPE}
              />
              <ul>
                {formData.instructionAttachments?.map(
                  (file: any, index: number) => (
                    <li className={styles.attachmentsListWrapper} key={index}>
                      {getFileName(file)}
                      <Button
                        icon="remove"
                        stylingMode="text"
                        className={styles.removeIcon}
                        onClick={() => {
                          removeAttachment(index, 'instructionAttachments');
                        }}
                      />
                    </li>
                  ),
                )}
              </ul>
            </Item>
          </GroupItem>
          <GroupItem caption={'APPLICABLE SUBJECTS'} cssClass={styles.group2}>
            {subjectList}
            {showSubjectError && (
              <GroupItem cssClass={styles.error}>
                {formValidationMsg.REQUIRED_SUBJECT_AND_VALUE}
              </GroupItem>
            )}
          </GroupItem>
        </GroupItem>
      </Form>
      {fileTypeError && <p style={{ color: 'red' }}>{fileTypeError}</p>}
      <div className={styles.nextBtn}>
        <Button
          text="Next"
          type={'default'}
          width={'20%'}
          onClick={(e) => handleSubmit(e)}
        />
      </div>
    </>
  );
};

export default ProcedureSubjectsForm;
