import React, {
  FC,
  PropsWithChildren,
  useCallback,
  useEffect,
  useMemo,
  useRef,
} from 'react';

import TreeView from 'devextreme-react/tree-view';

import { useNavigation } from '../../contexts/navigation';
import { useScreenSize } from '../../utils/media-query';

import './SideNavigationMenu.scss';

import * as events from 'devextreme/events';

import { navigation } from '../../configs/app-navigation';
import { useAuth } from '../../contexts/auth';
import type { SideNavigationMenuProps } from '../../types';
import { UserRoleEnum } from '../../types/user.types';

export const SideNavigationMenu: FC<
  PropsWithChildren<SideNavigationMenuProps>
> = (props) => {
  const { children, selectedItemChanged, openMenu, compactMode, onMenuReady } =
    props;

  const { isLarge } = useScreenSize();
  const { currentUser } = useAuth();
  if (!currentUser) {
    return null;
  }

  const normalizePath = () =>
    navigation.filter((item) => {
      if (item.allowed?.includes(currentUser.role as UserRoleEnum)) {
        return {
          ...item,
          expanded: isLarge,
          path:
            item.path && !/^\//.test(item.path) ? `/${item.path}` : item.path,
        };
      }
    });

  const items = useMemo(normalizePath, []);

  const {
    navigationData: { currentPath },
  } = useNavigation();

  const treeViewRef = useRef<TreeView>(null);
  const wrapperRef = useRef<HTMLDivElement>();
  const getWrapperRef = useCallback(
    (element: HTMLDivElement) => {
      const prevElement = wrapperRef.current;
      if (prevElement) {
        events.off(prevElement, 'dxclick');
      }

      wrapperRef.current = element;
    },
    [openMenu],
  );

  useEffect(() => {
    const treeView = treeViewRef.current && treeViewRef.current.instance;
    if (!treeView) {
      return;
    }

    if (currentPath && currentPath !== '/profile') {
      treeView.selectItem(currentPath);
      treeView.expandItem(currentPath);
    } else if (currentPath === '/profile') {
      treeView.unselectAll();
    }

    if (compactMode) {
      treeView.collapseAll();
    }
  }, [currentPath, compactMode]);

  return (
    <div
      className={'dx-swatch-additional side-navigation-menu'}
      ref={getWrapperRef}
    >
      {children}
      <div className={'menu-container'}>
        <TreeView
          ref={treeViewRef}
          items={items}
          keyExpr={'path'}
          selectionMode={'single'}
          focusStateEnabled={false}
          expandEvent={'click'}
          onItemClick={selectedItemChanged}
          onContentReady={onMenuReady}
          width={'100%'}
        />
      </div>

      {!compactMode && (
        <img
          src={require('../../images/AuroraRCM-Logo.png')}
          alt="AuroraRCM-Logo"
          className="bottom-image"
        />
      )}
    </div>
  );
};
