import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import { SearchOptions } from '../types/pagination.types';
import {
  DeleteUserResponse,
  GetUserResponse,
  ResetPasswordAdminBody,
  ResetPasswordResponse,
} from '../types/user.types';
import { ApiServiceErr, DeleteBody, MutOptions, axiosApi } from './apiService';

export const useGetUsers = ({ search, role, enabled = true }: SearchOptions) =>
  useQuery<PagingResponse<GetUserResponse[]>, ApiServiceErr>(
    ['/user/all', search],
    async () => {
      let url = `/user/all?search=${search?.toString()}`;

      if (role) {
        url = url + `&role=${role}`;
      }

      const response = await axiosApi.get(url);
      return response.data;
    },
    {
      enabled,
    },
  );

export const useDeleteUser = (options?: MutOptions<DeleteUserResponse>) =>
  useMutation<DeleteUserResponse, ApiServiceErr, DeleteBody>(async (data) => {
    const response = await axiosApi.delete(`/user/delete/${data.id}`);
    return response.data;
  }, options);

export const UseResetPasswordAdmin = (
  options?: MutOptions<ResetPasswordResponse>,
) =>
  useMutation<ResetPasswordResponse, ApiServiceErr, ResetPasswordAdminBody>(
    async (data) => {
      const response = await axiosApi.put(
        `/user/request/reset-password/${data.id}`,
        data,
      );
      return response.data;
    },
    options,
  );

export const getUsers = (params: string) => axiosApi.get(`/user/all${params}`);
