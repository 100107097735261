import React, { FC, useState } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import notify from 'devextreme/ui/notify';

import {
  ClientResponse,
  useDeleteClient,
  useGetClients,
} from '../../api/clients';
import {
  ActionDropDownMenu,
  DeleteModal,
  TableSearchHeader,
  TableWrapper,
} from '../../components';
import { confirmationMsgs, errorMsgs, successMsgs } from '../../configs';
import { GridRow, Nullable } from '../../types';
import { UserRoleEnum } from '../../types/user.types';
import { displayDate } from '../../utils/dateTime';
import { useIsUserRole } from '../../utils/useIsUserRole';
import { ClientForm } from './ClientForm';

type RowType = GridRow<ClientResponse>;

const ClientTable: FC = () => {
  const isUser = useIsUserRole(UserRoleEnum.User);

  const [showAddClient, setShowAddClient] = useState(false);
  const [showEditClient, setShowEditClient] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [activeClient, setActiveClient] = useState<Nullable<ClientResponse>>();
  const [clientSearchTxt, setClientSearchTxt] = useState<string>('');

  const { data: clientsData, refetch } = useGetClients({
    search: clientSearchTxt,
  });
  const { mutate: mutateDeleteClient } = useDeleteClient({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.CLIENT_DELETE, 'success');
      refetch();
    },
  });

  const renderActionButton = (data: RowType) => (
    <ActionDropDownMenu
      onClickEdit={() => {
        setShowEditClient(true);
        setActiveClient(data?.data);
      }}
      onClickDelete={() => {
        setShowDeleteConfirmation(true);
        setActiveClient(data?.data);
      }}
      disabled={isUser}
    />
  );

  return (
    <TableWrapper>
      <TableSearchHeader
        newButtonText={'New Client'}
        newButtonOnClick={() => {
          setShowAddClient(!showAddClient);
          setActiveClient(null);
        }}
        searchText={setClientSearchTxt}
        buttonDisabled={isUser}
      />

      <DataGrid dataSource={clientsData?.data} keyExpr={'_id'}>
        <Column dataField={'clientId'} caption={'Client ID'} />
        <Column dataField={'name'} caption={'Client Name'} />
        <Column
          dataField={'createdAt'}
          caption={'Created Date'}
          cellRender={(data: RowType) => displayDate(data?.data?.createdAt)}
        />
        <Column
          dataField={'updatedAt'}
          caption={'Updated Date'}
          cellRender={(data: RowType) => displayDate(data?.data?.updatedAt)}
        />
        <Column caption={'Actions'} cellRender={renderActionButton} />

        <Paging defaultPageIndex={0} defaultPageSize={5} />
        <Pager visible allowedPageSizes={5} showNavigationButtons />
      </DataGrid>
      <DeleteModal
        visible={showDeleteConfirmation}
        onHiding={() => {
          setActiveClient(null);
          setShowDeleteConfirmation(false);
        }}
        handleDelete={() => {
          mutateDeleteClient({ id: activeClient?._id });
          setActiveClient(null);
          setShowDeleteConfirmation(false);
        }}
        massage={confirmationMsgs.CLIENT_DELETE}
      />
      <ClientForm
        visible={showAddClient || showEditClient}
        onHiding={() => {
          refetch();
          setShowAddClient(false);
          setShowEditClient(false);
        }}
        selectedClient={activeClient}
      />
    </TableWrapper>
  );
};

export default ClientTable;
