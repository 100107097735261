import defaultUserAvatar from '../images/aurora-logo.png';
import { UserRoleEnum } from '../types/user.types';

const API_URL = process.env.REACT_APP_API || 'https://api.aurorarcm.com/api';

const WEB_SOCKET_URL = 'https://api.aurorarcm.com';

const USER_ROLES = [
  { name: 'Admin', value: UserRoleEnum.Admin },
  { name: 'User', value: UserRoleEnum.User },
];

const ALLOWED_IMAGE_TYPES = ['.jpg', '.jpeg', '.png'];
const MAX_IMAGE_UPLOAD_SIZE = 20000000;

const STATIC_BASE_URI = 'https://aurorarcm.sgp1.digitaloceanspaces.com';

const DEFAULT_USER_AVATAR = defaultUserAvatar;

const CST_TIMEZONE_OFFSET = 'America/Jamaica'; // '-05:00'

const CST_TIME_OFFSET_FOR_START_DAY = '05:30:00';

const fileExtensions = [
  '.doc',
  '.docx',
  '.odt',
  '.pdf',
  '.jpg',
  '.jpeg',
  '.png',
  '.txt',
  '.xls',
  '.xlsx',
];

const DEFAULT_PAGE_SIZE = 10;

const ALLOWED_FILE_TYPE =
  '.jpg, .jpeg, .png, .pdf, .doc, .docx, .txt, .xls, .xlsx';

const ALLOWED_DOCUMENT_FILE_TYPE = [
  'image/jpeg',
  'image/png',
  'application/pdf',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'text/plain',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
];

const TIME_OUT = 5000;

export {
  API_URL,
  WEB_SOCKET_URL,
  USER_ROLES,
  STATIC_BASE_URI,
  ALLOWED_IMAGE_TYPES,
  MAX_IMAGE_UPLOAD_SIZE,
  DEFAULT_USER_AVATAR,
  CST_TIMEZONE_OFFSET,
  CST_TIME_OFFSET_FOR_START_DAY,
  fileExtensions,
  DEFAULT_PAGE_SIZE,
  ALLOWED_FILE_TYPE,
  ALLOWED_DOCUMENT_FILE_TYPE,
  TIME_OUT,
};
