import React, { FC } from 'react';

import { PageTitle } from '../../components/text/Text';
import UserTable from './UserTable';

export const Users: FC = () => {
  document.title = 'Users';
  return (
    <section>
      <PageTitle>Users</PageTitle>
      <UserTable />
    </section>
  );
};
