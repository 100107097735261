import { MeResponse } from '../types/user.types';

const USER = 'USER';
const TOKEN = 'TOKEN';

export const getTokenInLocal = () => localStorage.getItem(TOKEN);
export const getUserInLocal = () => {
  const user = localStorage.getItem(USER);
  if (!user) {
    return null;
  }
  return JSON.parse(user) as MeResponse;
};

export const saveTokenInLocal = (userToken: string) => {
  localStorage.setItem(TOKEN, userToken);
};

export const saveUserInLocal = (user: MeResponse | null) => {
  if (user) {
    localStorage.setItem(USER, JSON.stringify(user));
  } else {
    localStorage.removeItem(USER);
  }
};

export const removeTokenAndUser = () => {
  localStorage.removeItem(USER);
  localStorage.removeItem(TOKEN);
};
