import React, { FC, FormEvent, useCallback } from 'react';

import {
  ButtonItem,
  ButtonOptions,
  Form,
  SimpleItem,
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';

import { useDeleteProcedure } from '../../api/procedure';
import { errorMsgs, successMsgs } from '../../configs';

interface ProcedureDeleteFormProps {
  visible: boolean;
  onHiding: () => void;
  selectedProcedureId?: string;
}

export const ProcedureDeleteForm: FC<ProcedureDeleteFormProps> = ({
  visible,
  onHiding,
  selectedProcedureId,
}) => {
  const [formData, setFormData] = React.useState({ reason: '' });

  const { mutate: mutateDeleteProcedure, isLoading } = useDeleteProcedure({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.PROCEDURE_DELETE, 'success');
      setFormData({ reason: '' });
      onHiding();
    },
  });

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      mutateDeleteProcedure({
        id: selectedProcedureId,
        comment: formData.reason,
      });
    },
    [formData, selectedProcedureId],
  );

  return (
    <Popup
      visible={visible}
      hideOnOutsideClick
      onHiding={onHiding}
      title={'Reason for delete this procedure'}
      width={500}
      height="auto"
      resizeEnabled={false}
      showCloseButton
    >
      <form onSubmit={handleSubmit}>
        <Form labelMode={'floating'} disabled={isLoading} formData={formData}>
          <SimpleItem
            dataField={'reason'}
            label={{ text: 'Delete Reason' }}
            isRequired
          />

          <ButtonItem>
            <ButtonOptions
              text="Delete procedure"
              width={'100%'}
              type={'default'}
              useSubmitBehavior={true}
            />
          </ButtonItem>
        </Form>
      </form>
    </Popup>
  );
};
