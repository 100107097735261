import { FC, useCallback, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import Form, {
  ButtonItem,
  ButtonOptions,
  EmailRule,
  Item,
  Label,
  RequiredRule,
} from 'devextreme-react/form';
import LoadIndicator from 'devextreme-react/load-indicator';
import notify from 'devextreme/ui/notify';

import { useResetPassword } from '../../api/auth';
import { errorMsgs, successMsgs } from '../../configs';
import styles from './ResetPasswordForm.module.scss';

const { RESET_PASSWORD_LINK_SENT } = successMsgs;
const { SOMETHING_WRONG } = errorMsgs;

export const ResetPasswordForm: FC = () => {
  const navigate = useNavigate();
  const formData = useRef({ email: '', password: '' });
  const { mutate, isLoading } = useResetPassword({
    onError: (error) => {
      notify(error?.msg || SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(RESET_PASSWORD_LINK_SENT, 'success');
    },
  });

  const onSubmit = useCallback(
    async (e: any) => {
      e.preventDefault();
      const { email } = formData.current;
      mutate({ email });
    },
    [navigate],
  );

  return (
    <form onSubmit={onSubmit}>
      <Form formData={formData.current} disabled={isLoading}>
        <Item
          dataField={'email'}
          editorType={'dxTextBox'}
          editorOptions={emailEditorOptions}
        >
          <RequiredRule message="Email is required" />
          <EmailRule message="Email is invalid" />
          <Label visible={false} />
        </Item>
        <ButtonItem>
          <ButtonOptions
            elementAttr={submitButtonAttributes}
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          >
            <span className="dx-button-text">
              {isLoading ? (
                <LoadIndicator width={'24px'} height={'24px'} visible={true} />
              ) : (
                'Reset my password'
              )}
            </span>
          </ButtonOptions>
        </ButtonItem>
        <Item>
          <div className={styles.loginLink}>
            Return to <Link to={'/login'}>Login</Link>
          </div>
        </Item>
      </Form>
    </form>
  );
};

const emailEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Email',
  mode: 'email',
};
const submitButtonAttributes = { class: styles.submitButton };
