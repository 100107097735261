import React, { FC, FormEvent, useCallback, useEffect } from 'react';

import {
  ButtonItem,
  ButtonOptions,
  Form,
  SimpleItem,
} from 'devextreme-react/form';
import { Popup } from 'devextreme-react/popup';
import notify from 'devextreme/ui/notify';

import {
  ClientResponse,
  useCreateClient,
  useUpdateClient,
} from '../../api/clients';
import { errorMsgs, successMsgs } from '../../configs';
import { Nullable } from '../../types';

interface ClientFormProps {
  visible: boolean;
  onHiding: () => void;
  selectedClient?: Nullable<ClientResponse>;
}

interface AddClientProps {
  name: string;
}

export const ClientForm: FC<ClientFormProps> = ({
  visible,
  onHiding,
  selectedClient,
}) => {
  const { isLoading: isLoadingCreateClient, mutate: mutateCreateClient } =
    useCreateClient({
      onError: (error) => {
        notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
      },
      onSuccess: () => {
        notify(successMsgs.CLIENT_CREATED, 'success');
        setFormData({ name: '' });
        onHiding();
      },
    });
  const { isLoading: isLoadingUpdateClient, mutate: mutateUpdateClient } =
    useUpdateClient({
      onError: (error) => {
        notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
      },
      onSuccess: () => {
        notify(successMsgs.CLIENT_VALUE_UPDATED, 'success');
        setFormData({ name: '' });
        onHiding();
      },
    });

  const [formData, setFormData] = React.useState<AddClientProps>({
    name: selectedClient?.name || '',
  });

  useEffect(() => {
    if (selectedClient?.name) {
      setFormData({ name: selectedClient?.name });
    } else {
      setFormData({ name: '' });
    }
  }, [selectedClient]);

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      if (selectedClient?.name) {
        mutateUpdateClient({ data: formData, id: selectedClient._id });
      } else {
        mutateCreateClient(formData);
      }
    },
    [formData],
  );

  return (
    <Popup
      visible={visible}
      hideOnOutsideClick
      onHiding={onHiding}
      title={selectedClient ? 'Edit Client' : 'Create New Client'}
      width={500}
      height="auto"
      resizeEnabled={false}
      showCloseButton
    >
      <form onSubmit={handleSubmit}>
        <Form
          labelMode={'floating'}
          disabled={isLoadingCreateClient || isLoadingUpdateClient}
          formData={formData}
        >
          <SimpleItem
            dataField={'name'}
            label={{ text: 'Client Name' }}
            isRequired
          />

          <ButtonItem>
            <ButtonOptions
              text="Submit"
              width={'100%'}
              type={'default'}
              useSubmitBehavior={true}
            />
          </ButtonItem>
        </Form>
      </form>
    </Popup>
  );
};
