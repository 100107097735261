import { useMutation, useQuery } from '@tanstack/react-query';

import { PagingResponse } from '../types';
import {
  ProcedureCompletionRecordBody,
  ProcedureDeleteBody,
  ProcedureResponse,
  ProcedureUpdateTaskBody,
  RemoveCompletionRecordBody,
} from '../types/procedure.types';
import { ApiServiceErr, MutOptions, axiosApi } from './apiService';

type UpdateData = {
  data: FormData;
  id: string;
};

export const useGetProceduresToExport = () =>
  useQuery<PagingResponse<ProcedureResponse[]>, ApiServiceErr>(
    ['/procedure/export'],
    async () => {
      const response = await axiosApi.get(`/procedure/export`);
      return response.data;
    },
  );

export const useCreateProcedure = (options?: MutOptions<ProcedureResponse>) =>
  useMutation<ProcedureResponse, ApiServiceErr, FormData>(async (data) => {
    const response = await axiosApi.post('procedure/create', data, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    return response.data;
  }, options);

export const useGetProcedureById = (id: string) =>
  useQuery<PagingResponse<ProcedureResponse>, ApiServiceErr>(
    ['/procedure', id],
    async () => {
      const response = await axiosApi.get(`/procedure/${id}`);
      return response.data;
    },
  );

export const useDeleteProcedure = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, ProcedureDeleteBody>(async (data) => {
    const response = await axiosApi.put(`/procedure/delete/${data.id}`, {
      comment: data.comment,
    });
    return response.data;
  }, options);

export const useUpdateProcedure = (options?: MutOptions<ProcedureResponse>) =>
  useMutation<ProcedureResponse, ApiServiceErr, UpdateData>(
    async ({ data, id }) => {
      const response = await axiosApi.put(`procedure/update/${id}`, data, {
        headers: { 'Content-Type': 'multipart/form-data' },
      });
      return response.data;
    },
    options,
  );

export const useUpdateTaskProcedure = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, ProcedureUpdateTaskBody>(async (data) => {
    const response = await axiosApi.put(
      `activity-procedure/update-checklist/${data.id}`,
      data.reqData,
    );
    return response.data;
  }, options);

export const useUploadCompletionRecord = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, ProcedureCompletionRecordBody>(
    async (data) => {
      const response = await axiosApi.put(
        `activity-procedure/upload-completion-record/${data.id}`,
        data.formData,
        {
          headers: { 'Content-Type': 'multipart/form-data' },
        },
      );
      return response.data;
    },
    options,
  );

export const getProcedures = (params: string) =>
  axiosApi.get(`/procedure/all${params}`);

export const getArchivedProcedures = (params: string) =>
  axiosApi.get(`/procedure/archive${params}`);

export const useRemoveCompletionRecord = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, RemoveCompletionRecordBody>(
    async (data) => {
      const response = await axiosApi.put(
        `activity-procedure/remove-completion-record/${data.id}`,
        {
          key: data.key,
        },
      );
      return response.data;
    },
    options,
  );
