import React, { FC } from 'react';

import SelectBox from 'devextreme-react/select-box';

import { UserRoleEnum } from '../../types/user.types';

interface Props {
  handleFilter: (value: UserRoleEnum) => void;
}

const userRoles = [
  { ID: 1, type: 'user', displayName: 'User' },
  {
    ID: 2,
    type: 'admin',
    displayName: 'Admin',
  },
];

const Filters: FC<Props> = ({ handleFilter }) => (
  <SelectBox
    dataSource={userRoles}
    valueExpr={'type'}
    displayExpr={'displayName'}
    searchEnabled
    onValueChanged={(e) => {
      handleFilter(e.value as UserRoleEnum);
    }}
    placeholder={'Filter By User Role'}
    showClearButton
  />
);

export default Filters;
