import { FC, useEffect, useState } from 'react';

import { useGetMyProcedures } from '../../../api/home';
import useProcedureSocketTrigger from '../../../customHooks/useProcedureSocketTrigger';
import { ProcedureStatus } from '../../../types/procedure.types';
import { UserRoles } from '../../../types/user.types';
import { SocketEnum } from '../../../types/webSocketEvent.types';
import { formatToISO } from '../../../utils/dateTime';
import { filterProcedureList } from '../../../utils/filterProcedureLIst';
import LoadingIndicator from '../../loadingIndicator/LoadingIndicator';
import { NoData } from '../../no-data/NoData';
import ProcedureCard from '../procedure-card/ProcedureCard';
import R2R3Wrapper from '../r2-r3-wrapper/R2R3Wrapper';
import TimeFilter, { today, tonight } from '../time-filter/TimeFilter';
import styles from './r2-r3.module.scss';

const socketEventList = [
  SocketEnum.NewProcedure,
  SocketEnum.HelpRequest,
  SocketEnum.Complete,
  SocketEnum.LockProcedure,
  SocketEnum.DeleteActivityProcedure,
  SocketEnum.Reassigned,
];

const R2_R3: FC<{ selectedTabIndex: number }> = ({ selectedTabIndex }) => {
  const [startDate, setStartDate] = useState<Date>(today);
  const [endDate, setEndDate] = useState<Date>(tonight);

  const {
    data: list = [],
    refetch,
    isLoading,
  } = useGetMyProcedures({
    teamRole: 'responsible2',
    startDate: formatToISO(startDate),
    endDate: formatToISO(endDate),
  });

  useEffect(() => {
    refetch();
  }, [startDate, endDate]);

  useEffect(() => {
    if (selectedTabIndex === 1) {
      refetch();
    }
  }, [selectedTabIndex]);

  useProcedureSocketTrigger(
    refetch,
    [UserRoles.Responsible2, UserRoles.Responsible3],
    socketEventList,
  );

  const approvalsNeededList = filterProcedureList(list, ProcedureStatus.Active);

  const approvalsNeeded = approvalsNeededList.map((data: any) => (
    <ProcedureCard
      key={data._id}
      data={data}
      type={ProcedureStatus.NextUp}
      refetch={refetch}
      minSize
    />
  ));

  return (
    <section className={styles.mainContainer}>
      <TimeFilter
        type={''}
        onFilterChange={(startDate: Date, endDate: Date) => {
          setStartDate(startDate);
          setEndDate(endDate);
        }}
      />
      <div className={styles.R2R3Container}>
        {isLoading ? (
          <LoadingIndicator />
        ) : (
          <>
            {' '}
            <R2R3Wrapper title={''}>
              {approvalsNeeded.length > 0 ? approvalsNeeded : <NoData />}
            </R2R3Wrapper>
          </>
        )}
      </div>
    </section>
  );
};

export default R2_R3;
