import { useMutation, useQuery } from '@tanstack/react-query';

import { ProcedureActionType, ProcedureStatus } from '../types/procedure.types';
import { ApiServiceErr, MutOptions, axiosApi } from './apiService';

interface TeamResponse {
  _id: string;
  image: string;
  createdAt: string;
  updatedAt: string;
  email: string;
  role: string;
  lastName: string;
  firstName: string;
}

type TeamMemberResponse = Pick<
  TeamResponse,
  '_id' | 'firstName' | 'lastName' | 'role' | 'image' | 'email'
>;

interface Team {
  responsible: TeamMemberResponse;
  responsible2: TeamMemberResponse;
  responsible3: TeamMemberResponse;
  accounter: TeamMemberResponse;
  consultant: TeamMemberResponse;
  informer: TeamMemberResponse;
}

export interface ProcedureResponse {
  _id: string;
  procedureName: string;
  status: ProcedureStatus;
  team: Team;
  occurDate: string;
  applicableSubject: {
    subjectId: { title: string };
    value: { name: string; valueId: string };
  };
}

interface GetProceduresAsMemberParams {
  teamRole: string;
  startDate: string;
  endDate: string;
  completeStatus?: boolean;
}

type HandleProcedure = Pick<ProcedureResponse, '_id'>;

export interface UpdateStatusBody {
  type: ProcedureActionType;
  id: string;
  occurDate: string;
}

export interface ChangeResponsibleBody {
  id: string;
  occurDate: string;
  responsible: string;
}

export interface DifferProcedureBody {
  id: string;
  comment: string;
  occurDate: string;
}

type RequestHelpBody = Pick<DifferProcedureBody, 'id' | 'occurDate'>;

export const useGetMyProcedures = (params: GetProceduresAsMemberParams) =>
  useQuery<ProcedureResponse[], ApiServiceErr>(
    [
      '/activity-procedure/get-procedures-as-member/all?teamRole=${teamRole}&startDate=${startDate}&endDate=${endDate}&completeStatus=${completeStatus}',
      params,
    ],
    async () => {
      const response = await axiosApi.get(
        `/activity-procedure/get-procedures-as-member/all`,
        {
          params: params,
        },
      );
      return response.data;
    },
  );

export const useProcedureActionButton = (
  options?: MutOptions<HandleProcedure>,
) =>
  useMutation<HandleProcedure, ApiServiceErr, UpdateStatusBody>(
    async (data) => {
      const response = await axiosApi.put(
        `/procedure/${data.type}/${data.id}`,
        {
          occurDate: data.occurDate,
        },
      );
      return response.data;
    },
    options,
  );

export const useDifferProcedure = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, DifferProcedureBody>(async (data) => {
    const response = await axiosApi.put(
      `/activity-procedure/cancel?id=${data.id}&occurDate=${data.occurDate}&comment=${data.comment}`,
    );
    return response.data;
  }, options);

export const useProcedureReAssign = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, ChangeResponsibleBody>(async (data) => {
    const response = await axiosApi.put(`/procedure/re-assign/${data.id}`, {
      responsible: data.responsible,
      occurDate: data.occurDate,
    });
    return response.data;
  }, options);

export const useProcedureHelp = (options?: MutOptions<string>) =>
  useMutation<string, ApiServiceErr, RequestHelpBody>(async (data) => {
    const response = await axiosApi.put(`/procedure/request/help/${data.id}`, {
      occurDate: data.occurDate,
    });
    return response.data;
  }, options);
