import React, { FC, PropsWithChildren } from 'react';

import styles from './procedureColumn.module.scss';

interface Props extends PropsWithChildren {
  title: string;
}

const ProcedureColumn: FC<Props> = ({ children, title }) => (
  <div className={styles.procedureColumn}>
    <div className={styles.titleBlock}>{title}</div>
    <div className={styles.contentBlock}>{children}</div>
  </div>
);

export default ProcedureColumn;
