import { FC } from 'react';

import { PageTitle } from '../../components/text/Text';
import SubjectsTabs from './SubjectTabs';

export const Subjects: FC = () => {
  document.title = 'Subjects';

  return (
    <section>
      <PageTitle>Subjects</PageTitle>
      <SubjectsTabs />
    </section>
  );
};
