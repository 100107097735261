import { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import Button from 'devextreme-react/button';
import { CheckBox } from 'devextreme-react/check-box';
import { Form, GroupItem, SimpleItem } from 'devextreme-react/form';
import Switch from 'devextreme-react/switch';
import { Item } from 'devextreme-react/tab-panel';

import { SubmitModal } from '../../components/submit-modal/SubmitModal';
import { confirmationMsgs } from '../../configs';
import { MandateFormValues } from '../../types/procedure.types';
import MandatesCheckList from './MandatesCheckList';
import styles from './procedures.module.scss';

const { UPDATE_PROCEDURE, CREATE_NEW_PROCEDURE } = confirmationMsgs;

const initialFormData: MandateFormValues = {
  checkList: [],
  uploadDocument: '',
  approval: false,
  isCompleteRecordNeeded: false,
};

type ScoreMandatesFormProps = {
  onMandates: (data: MandateFormValues) => void;
  onSubmit: (event: any) => void;
  clearSubFormData: boolean;
  data: MandateFormValues | undefined;
  onBackButton: (index: number) => void;
};

type CheckListValue = {
  text: string;
};

const ScoreMandatesForm: FC<ScoreMandatesFormProps> = ({
  onMandates,
  clearSubFormData,
  onSubmit,
  data,
  onBackButton,
}) => {
  const [formData, setFormData] = useState<MandateFormValues>({
    ...initialFormData,
    ...data,
  });
  const [eventValue, setEventValue] = useState<FormEvent>();
  const [switchValue, setSwitchValue] = useState(false);
  const [submitModalState, SetSubmitModalState] = useState(false);
  const [values, setValues] = useState<CheckListValue[]>([{ text: '' }]);
  const [checkList, setCheckList] = useState(false);
  const [uploadCompletion, setUploadCompletion] = useState(false);

  useEffect(() => {
    if (clearSubFormData && !data) {
      setFormData(initialFormData);
      setCheckList(false);
      setUploadCompletion(false);
      setSwitchValue(false);
    }
  }, [clearSubFormData]);

  useEffect(() => {
    if (data) {
      const { checkList, isCompleteRecordNeeded, approval } = data;
      setUploadCompletion(isCompleteRecordNeeded);

      if (checkList.length) {
        setCheckList(true);
        const list: CheckListValue[] = checkList.map((check) => ({
          text: check,
        }));
        setValues(list);
      }
      setSwitchValue(approval);
    }
  }, [data]);

  const switchOnValueChange = (value: boolean) => {
    setSwitchValue(value);
    setFormData({ ...formData, approval: value });
  };

  const uploadCompletionOnChange = (value: boolean) => {
    let state = {
      ...formData,
      isCompleteRecordNeeded: value,
    };
    if (!value) {
      state = { ...state, uploadDocument: '' };
    }
    setUploadCompletion(value);
    setFormData(state);
  };

  const handleSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      SetSubmitModalState(true);
      setEventValue(event);
      // TODO: Set Mandates only if your Accept the confirmation Modal
      onMandates(formData);
    },
    [formData],
  );

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <Form
          labelMode={'floating'}
          className={styles.formContainer}
          formData={formData}
          scrollingEnabled
        >
          <GroupItem caption={'MANDATES'} cssClass={styles.group2}>
            <GroupItem>
              <Item>
                <p className={styles.subTitle}>COMPLETION RECORD</p>
              </Item>
              <Item>
                <CheckBox
                  text={'Checklist'}
                  iconSize={25}
                  value={checkList}
                  onValueChanged={(e) => setCheckList(e.value)}
                  className={styles.subAlign}
                />
              </Item>
              <GroupItem>
                <MandatesCheckList
                  isChecked={checkList}
                  onChecklistValues={(data) =>
                    setFormData((prev) => ({ ...prev, checkList: data }))
                  }
                  setValues={(values) => setValues(values)}
                  values={values}
                />
              </GroupItem>
              <Item>
                <CheckBox
                  text={'Upload completion record'}
                  iconSize={25}
                  value={uploadCompletion}
                  onValueChanged={({ value }) =>
                    uploadCompletionOnChange(value)
                  }
                  className={styles.subAlign}
                />
              </Item>
              {uploadCompletion && (
                <GroupItem>
                  <SimpleItem
                    dataField="uploadDocument"
                    label={{ text: 'Comment' }}
                  />
                </GroupItem>
              )}

              <Item>
                <p className={styles.subTitle}>APPROVAL</p>
              </Item>
              <Item>
                <div className={styles.scheduleBtn}>
                  <Switch
                    onValueChange={(value) => switchOnValueChange(value)}
                    value={switchValue}
                  />
                  <div className={styles.requiredText}>Required</div>
                </div>
              </Item>
            </GroupItem>
            <GroupItem />
          </GroupItem>
        </Form>
        <div className={styles.nextBtn}>
          <Button text="Back" width={'20%'} onClick={() => onBackButton(1)} />
          <Button
            text="Submit"
            width={'20%'}
            type={'default'}
            useSubmitBehavior={true}
          />
        </div>
      </form>
      <SubmitModal
        visible={submitModalState}
        onHiding={() => SetSubmitModalState(!submitModalState)}
        handleSubmit={() => {
          onSubmit(eventValue);
          SetSubmitModalState(!submitModalState);
        }}
        massage={clearSubFormData ? CREATE_NEW_PROCEDURE : UPDATE_PROCEDURE}
      />
    </div>
  );
};

export default ScoreMandatesForm;
