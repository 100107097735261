import React from 'react';

import DateBox from 'devextreme-react/date-box';
import { RequiredRule } from 'devextreme-react/form';
import { RangeRule, Validator } from 'devextreme-react/validator';
import { DateType } from 'devextreme/ui/date_box';

interface DateAndTimeProps {
  onDateSelected: (date: string) => void;
  type: DateType;
  displayFormat: string;
  placeholder: string;
  title: string;
}

const DateAndTime: React.FC<DateAndTimeProps> = ({
  onDateSelected,
  type,
  displayFormat,
  placeholder,
  title,
}) => {
  const onValueChanged = (date: Date) => {
    const formattedDate = formatDate(date);
    if (date) {
      onDateSelected(formattedDate || '');
    }
  };

  const formatDate = (date: Date) => {
    if (!date) {
      return '';
    }
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const day = date.getDate().toString().padStart(2, '0');
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');
    if (type === 'date') {
      return `${year}-${month}-${day}`;
    } else if (type === 'time') {
      return `${hours}:${minutes}`;
    }
  };

  const minDate = () => {
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
  };

  const OPTIONAL_DATES = ['End Date'];

  return (
    <div>
      <DateBox
        type={type}
        displayFormat={displayFormat}
        onValueChanged={(e) => onValueChanged(e.value)}
        placeholder={placeholder}
        acceptCustomValue={false}
      >
        <Validator>
          {!OPTIONAL_DATES.includes(title) && (
            <RequiredRule message={`${title.slice(0, -1)} is required`} />
          )}

          {type === 'date' && (
            <RangeRule
              message={`Please enter a date that is today or in the future, up to ${minDate().toLocaleDateString()}.`}
              min={minDate()}
            />
          )}
        </Validator>
      </DateBox>
    </div>
  );
};

export default DateAndTime;
