import LoadIndicator from 'devextreme-react/load-indicator';

import styles from '../loadingIndicator/loadingIndicator.module.scss';

const LoadingIndicator = () => (
  <div className={styles.loadingIndicator}>
    <LoadIndicator height={30} width={30} />
  </div>
);
export default LoadingIndicator;
