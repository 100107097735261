import { FC, useState } from 'react';

import { DataGrid } from 'devextreme-react';
import { Column, Pager, Paging } from 'devextreme-react/data-grid';
import CustomStore from 'devextreme/data/custom_store';
import notify from 'devextreme/ui/notify';

import { getUsers, useDeleteUser } from '../../api/users';
import {
  ActionDropDownMenu,
  DeleteModal,
  TableSearchHeader,
  TableWrapper,
} from '../../components';
import UserModal from '../../components/add-user-form/UserModal';
import ResetPasswordModal from '../../components/reset-password-modal/ResetPasswordModal';
import {
  DEFAULT_PAGE_SIZE,
  confirmationMsgs,
  errorMsgs,
  successMsgs,
} from '../../configs';
import { GridRow } from '../../types';
import { GetUserResponse, UserRoleEnum } from '../../types/user.types';
import { displayDate } from '../../utils/dateTime';
import { getStaticFileUrl } from '../../utils/getStaticFileUrl';
import { createParams, getSortString } from '../../utils/helpers';
import { useIsUserRole } from '../../utils/useIsUserRole';
import Filters from './Filters';
import styles from './users.module.scss';

type RowType = GridRow<GetUserResponse>;

const UserTable: FC = () => {
  const [userSearchTxt, setUserSearchTxt] = useState('');
  const [role, setRole] = useState<UserRoleEnum>();

  const { mutate: mutateDeleteUser } = useDeleteUser({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.USER_DELETE, 'success');
      setShowDeleteConfirmation(false);
    },
  });

  const [showAddUser, setShowAddUser] = useState(false);
  const [showEditUser, setShowEditUser] = useState(false);
  const [activeUser, setActiveUser] = useState<GetUserResponse>();
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showResetPassword, setShowResetPassword] = useState(false);

  const renderImageCell = (data: RowType) => (
    <img
      className={styles.imageBlock}
      src={getStaticFileUrl(data.data.image as string)}
      alt={'user-img'}
    />
  );

  const isUser = useIsUserRole(UserRoleEnum.User);

  const renderActionButton = (data: RowType) => (
    <ActionDropDownMenu
      onClickEdit={() => {
        setShowEditUser(true);
        setActiveUser(data?.data);
      }}
      onClickDelete={() => {
        setShowDeleteConfirmation(true);
        setActiveUser(data?.data);
      }}
      onClickResetPassword={() => {
        setShowResetPassword(true);
        setActiveUser(data?.data);
      }}
      disabled={isUser}
      enableResetPassword={true}
    />
  );

  const customDataSource = new CustomStore({
    key: '_id',
    load: (loadOptions: any) => {
      const { sort } = loadOptions;
      let params = createParams(['skip', 'take'], loadOptions);

      if (userSearchTxt) {
        params += `search=${userSearchTxt}&`;
      }

      if (role) {
        params += `role=${role}&`;
      }

      if (sort) {
        params += getSortString(sort[0]);
      }

      params = params.slice(0, -1);

      return getUsers(params)
        .then((response) => ({
          data: response.data.data,
          totalCount: response.data.pagination.total,
        }))
        .catch(() => {
          throw errorMsgs.SOMETHING_WRONG;
        });
    },
  });

  return (
    <TableWrapper>
      <TableSearchHeader
        newButtonText={'New User'}
        newButtonOnClick={() => {
          setShowAddUser(true);
        }}
        searchText={setUserSearchTxt}
        buttonDisabled={isUser}
        isRequiredFilterBtn={false}
        singleFilter={<Filters handleFilter={setRole} />}
      />

      <DataGrid dataSource={customDataSource} remoteOperations={true}>
        <Column dataField="email" caption="User Email" />
        <Column dataField="firstName" />
        <Column dataField="lastName" />
        <Column
          dataField="role"
          cellRender={(data: RowType) =>
            // @ts-ignore
            data.data.role?.charAt(0).toUpperCase() + data.data.role.slice(1)
          }
          width={120}
        />
        <Column
          dataField="updatedAt"
          caption="Updated Date"
          cellRender={(data: RowType) => displayDate(data.data.updatedAt)}
          width={120}
        />
        <Column
          dataField="image"
          cellRender={renderImageCell}
          caption="Profile Image"
          width={120}
        />
        <Column
          caption={'Actions'}
          cellRender={renderActionButton}
          width={80}
        />

        <Paging defaultPageIndex={0} defaultPageSize={DEFAULT_PAGE_SIZE} />
        <Pager
          visible
          allowedPageSizes={DEFAULT_PAGE_SIZE}
          showNavigationButtons
        />
      </DataGrid>

      <UserModal
        visible={showAddUser}
        onHiding={() => {
          setShowAddUser(false);
        }}
      />

      <UserModal
        visible={showEditUser}
        onHiding={() => {
          setShowEditUser(false);
        }}
        selectedUser={activeUser}
      />

      <DeleteModal
        visible={showDeleteConfirmation}
        onHiding={() => {
          setShowDeleteConfirmation(false);
        }}
        handleDelete={() => {
          mutateDeleteUser({ id: activeUser?._id });
          setShowDeleteConfirmation(false);
        }}
        massage={confirmationMsgs.User_DELETE}
      />

      <ResetPasswordModal
        visible={showResetPassword}
        onHiding={() => {
          setShowResetPassword(false);
        }}
        id={activeUser?._id}
      />
    </TableWrapper>
  );
};

export default UserTable;
