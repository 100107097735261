import React, { FC } from 'react';

import { PageTitle } from '../../components/text/Text';
import ClientTable from './ClientTable';

export const Clients: FC = () => (
  <section>
    <PageTitle>Clients</PageTitle>
    <ClientTable />
  </section>
);
