import { FC, FormEvent, useCallback, useEffect, useState } from 'react';

import Form, {
  ButtonItem,
  ButtonOptions,
  SimpleItem,
} from 'devextreme-react/form';
import notify from 'devextreme/ui/notify';

import { useCreateSubject } from '../../api/subject';
import { errorMsgs, successMsgs } from '../../configs';

interface AddSubjectFormProps {
  onFinished: () => void;
}

interface AddSubjectProps {
  subjectPrefix: string;
  title: string;
}

const prefixEditorOptions = {
  stylingMode: 'filled',
  placeholder: 'Subject-Prefix',
  mode: 'text',
};
const subjectEditorOptions = {
  ...prefixEditorOptions,
  placeholder: 'Subject',
};

export const AddSubjectForm: FC<AddSubjectFormProps> = ({ onFinished }) => {
  const initialFormData = { subjectPrefix: '', title: '' };

  const [formData, setFormData] = useState<AddSubjectProps>(initialFormData);
  const { mutate, isLoading } = useCreateSubject({
    onError: (error) => {
      notify(error?.msg || errorMsgs.SOMETHING_WRONG, 'error');
    },
    onSuccess: () => {
      notify(successMsgs.SUBJECT_CREATED, 'success');
      setFormData(initialFormData);
      onFinished();
    },
  });

  useEffect(() => {
    setFormData(initialFormData);
  }, [onFinished]);

  const onSubmit = useCallback(
    async (event: FormEvent<HTMLFormElement>) => {
      event.preventDefault();
      mutate(formData);
    },
    [mutate, formData],
  );

  return (
    <form onSubmit={onSubmit}>
      <Form labelMode={'floating'} formData={formData} disabled={isLoading}>
        <SimpleItem
          dataField={'title'}
          label={{ text: 'Subject' }}
          isRequired
          editorOptions={subjectEditorOptions}
        />
        <SimpleItem
          dataField={'subjectPrefix'}
          label={{ text: 'Prefix' }}
          isRequired
          editorOptions={prefixEditorOptions}
        />
        <ButtonItem>
          <ButtonOptions
            text="Submit"
            width={'100%'}
            type={'default'}
            useSubmitBehavior={true}
          />
        </ButtonItem>
      </Form>
    </form>
  );
};
