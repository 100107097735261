import { Item } from 'devextreme-react/tab-panel';

import styles from '../pages/procedures/procedures.module.scss';

export const fieldText = (title: string, value: string) => (
  <Item>
    <div className={styles.dataItem}>
      <div className={styles.itemTitle}>{title} :</div>
      <div className={styles.itemValue}>{value}</div>
    </div>
  </Item>
);
